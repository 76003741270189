import { MutationTree } from 'vuex';
import {
    ReconciliationActivityState,
    WidgetResultsState,
} from '@/store/modules/insightsDashboard/reconciliationActivity/types';

export enum ReconciliationActivityMutationsEnum {
    SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING = 'SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING',
    SET_RECONCILIATION_ACTIVITY_WIDGET_RESULTS = 'SET_RECONCILIATION_ACTIVITY_WIDGET_RESULTS',
    SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING = 'SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING',
    SET_RECONCILIATION_ACTIVITY_DETAILS_RESULTS = 'SET_RECONCILIATION_ACTIVITY_DETAILS_RESULTS',
}

export type ReconciliationActivityMutations<State = ReconciliationActivityState> = {
    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING](
        state: State,
        isLoading: boolean
    ): void;
    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_RESULTS](
        state: State,
        results: WidgetResultsState
    ): void;

    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING](
        state: State,
        isLoading: boolean
    ): void;

    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_RESULTS](
        state: State,
        results: {
            client_name: string;
            last_reconciled_date: string;
            bank_account: string;
        }[]
    ): void;
};

export const mutations: MutationTree<ReconciliationActivityState> & ReconciliationActivityMutations = {
    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING](state, isLoading) {
        state.reconciliationActivity.widget.isLoading = isLoading;
    },

    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_RESULTS](state, results) {
        state.reconciliationActivity.widget.results = {
            ...{
                no_reconciled_in_last_7_days: 0,
                no_reconciled_in_over_90_days: 0,
                no_reconciled_in_last_30_days: 0,
                no_reconciled_in_60_days: 0,
            },
            ...results,
        };
    },

    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING](state, isLoading) {
        state.reconciliationActivity.details.isLoading = isLoading;
    },

    [ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_RESULTS](state, results) {
        state.reconciliationActivity.details.results = results;
    },
};
