<template>
    <Fragment>
        <ModalHeader>
            <template v-if="limitApproaching">Trial Limit Approaching</template>
            <template v-else>Trial Limit Reached</template>
        </ModalHeader>
        <ModalContent>
            <OnTrialLimitApproaching :client-limit="clientLimit" :limit-approaching="limitApproaching" />
        </ModalContent>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

import { ModalContent, ModalHeader } from '@/components/Modal';
import OnTrialLimitApproaching from '@/layout/PageLayout/OnTrialLimitApproachingOrReached.vue';

export default defineComponent({
    components: { OnTrialLimitApproaching, Fragment, ModalContent, ModalHeader },
    props: {
        clientLimit: PropTypes.number().isRequired,
        limitApproaching: PropTypes.bool().isRequired,
    },
});
</script>
