/**
 * Check if the current user has the given permission enabled.
 *
 * @param {String} name Permission name
 * @returns Boolean
 */
function hasPermission(name) {
    return Boolean(window.Xavier.permissions[name]);
}

export { hasPermission };
