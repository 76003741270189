<template>
    <div class="d-form-input">
        <label v-if="showLabel" class="d-form-label" :for="inputId">
            <slot name="label">{{ label }}</slot>
        </label>

        <div v-if="showHint" class="d-form-hint-message">
            <slot name="hint">
                {{ hint }}
            </slot>
        </div>

        <slot />

        <div v-if="showErrorMessages" :class="`d-form-${status}-message`">
            <slot name="d-form-messages">
                <ul v-if="Array.isArray(errorMessages)" class="error-messages">
                    <li v-for="(item, index) in errorMessages" :key="index">
                        {{ item }}
                    </li>
                </ul>

                <template v-else>{{ errorMessages }}</template>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

import type { InputStatus } from '../DForm.types';

type Props = {
    hint?: string;
    inputId?: string;
    label?: string;
    errorMessages?: string | string[];
    messages?: string | string[];
    status?: InputStatus;
};

const props = defineProps<Props>();
const slots = useSlots();

const showHint = computed(() => Boolean(slots.hint?.()) || Boolean(props.hint));
const showLabel = computed(() => Boolean(slots.label?.()) || Boolean(props.label));
const showErrorMessages = computed(() => Boolean(slots['error-messages']?.()) || Boolean(props.errorMessages?.length));
</script>

<style scoped>
.d-form-label,
.d-form-messages {
    margin-block-end: 0;
}

.d-form-label {
    cursor: pointer;
    user-select: none;
}

.d-form-input:scope:has(.d-form-hint-message) label {
    margin: 0;
}

.d-form-hint-message {
    font-size: var(--d-font-size-small);
    margin-block: 0 var(--d-spacing-0);
}
</style>
