import { Module } from 'vuex';
import { LockDateActivityState } from '@/store/modules/insightsDashboard/lockDateActivity/types';

import { getters } from '@/store/modules/insightsDashboard/lockDateActivity/getters';
import { mutations } from '@/store/modules/insightsDashboard/lockDateActivity/mutations';
import { actions } from '@/store/modules/insightsDashboard/lockDateActivity/actions';
import { RootState } from '@/store/types';

const state: LockDateActivityState = {
    lockDateActivity: {
        widget: {
            isLoading: false,
            results: {
                no_lock_date_set: 0,
                lock_date_over_12_months: 0,
                lock_date_under_12_months: 0,
                lock_date_under_6_months: 0,
                lock_date_under_3_months: 0,
            },
        }
    },
};

const LockDateActivityModuleTs: Module<LockDateActivityState, RootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default LockDateActivityModuleTs;
