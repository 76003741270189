import { reactive, watch } from 'vue';

import injectStrict from '@/hooks/injectStrict';
import useDelay from '@/hooks/useDelay';
import { getFocusableElements, measureElement } from '@/utils/dom';

import { HorizontalAlign, VerticalAlign, VerticalPosition } from '../states/PositionStates';
import menuStates from '../states/MenuStates';
import { MenuContextApi } from '../types';

interface Params {
    alignment: {
        horizontal: HorizontalAlign;
        vertical: VerticalAlign;
    };
    detach: boolean;
    inline: boolean;
    offset: number;
    verticalPosition: VerticalPosition;
}

interface Dimensions {
    activator: ReturnType<typeof measureElement>;
    content: ReturnType<typeof measureElement>;
}

function isOutOfBounds(dimensions: Dimensions) {
    return {
        bottom: window.innerHeight < dimensions.activator.top + dimensions.activator.height + dimensions.content.height,
        left: 0 > dimensions.content.left,
        right: window.innerWidth < dimensions.activator.left + dimensions.activator.width + dimensions.content.width,
        top: 0 > dimensions.content.top,
    };
}

/**
 * Get the alignment values from the component props.
 */
function getAlignment(params: Params, dimensions: Dimensions) {
    const overflows = isOutOfBounds(dimensions);

    const isInline = params.inline;
    const isAlignLeft = (params.alignment.horizontal === HorizontalAlign.LEFT && !overflows.right) || overflows.left;
    const isAlignRight = (params.alignment.horizontal === HorizontalAlign.RIGHT && !overflows.left) || overflows.right;
    const isAlignTop = (params.alignment.vertical === VerticalAlign.TOP && !overflows.top) || overflows.bottom;
    const isAlignBottom = (params.alignment.vertical === VerticalAlign.BOTTOM && !overflows.bottom) || overflows.top;
    const isVerticalAbove = (params.verticalPosition === VerticalPosition.ABOVE && !overflows.top) || overflows.bottom;

    return {
        isAlignLeft,
        isAlignRight,
        isAlignTop,
        isAlignBottom,
        isVerticalAbove,
        isInline,
    };
}

function getLeft(params: Params, dimensions: Dimensions) {
    const alignment = getAlignment(params, dimensions);
    const activatorOffsetLeft = dimensions.activator?.left ?? 0;
    const activatorWidth = dimensions.activator?.width ?? 0;
    const contentWidth = dimensions.content.width;

    switch (true) {
        case alignment.isInline && alignment.isAlignLeft:
            return `${activatorOffsetLeft + activatorWidth + params.offset}px`;
        case alignment.isInline && alignment.isAlignRight:
            return `${activatorOffsetLeft - contentWidth - params.offset}px`;
        case alignment.isAlignRight:
            return `${activatorOffsetLeft + activatorWidth - contentWidth}px`;
        default:
            return `${activatorOffsetLeft}px`;
    }
}

function getTop(params: Params, dimensions: Dimensions) {
    const scrollTop = window.scrollY;
    const alignment = getAlignment(params, dimensions);
    const activatorOffsetTop = dimensions.activator?.top ?? 0;
    const activatorHeight = dimensions.activator?.height ?? 0;
    const contentHeight = dimensions.content.height;

    switch (true) {
        case alignment.isInline && alignment.isAlignTop:
            return `${activatorOffsetTop + scrollTop}px`;
        case alignment.isInline && alignment.isAlignBottom:
            return `${activatorOffsetTop + scrollTop - contentHeight + activatorHeight}px`;
        case alignment.isVerticalAbove:
            return `${activatorOffsetTop + scrollTop - contentHeight - params.offset}px`;
        default:
            return `${activatorOffsetTop + activatorHeight + scrollTop + params.offset}px`;
    }
}

async function getContentStyle(params: Params, menuContext: MenuContextApi) {
    const activatorEl = getFocusableElements(menuContext?.activatorRef.value)?.[0];
    const delay = useDelay();

    await delay();

    const dimensions = {
        activator: measureElement(activatorEl),
        content: measureElement(menuContext.itemsRef.value as HTMLElement),
    };

    return {
        left: getLeft(params, dimensions),
        top: getTop(params, dimensions),
    };
}

/**
 * Calculate the position of the dropdown menu. The main bulk of code here will
 * only run if the dropdown is in `detach` mode.
 *
 * @returns  Calaculated styles or empty object
 */
export default function useMenuItemsStyle(params: Params) {
    const menuContext = injectStrict<MenuContextApi>('MenuContext');
    const contentStyle = reactive<{ left: 0 | string; top: 0 | string }>({ left: 0, top: 0 });

    /**
     * Watch for changes to the menuState visibility and generate the styles.
     * We have to have a hacky double `nextTick` here as Vue seems to be slow
     * at _something_. We could achieve a similar result using
     * `setTimeout(getContentStyle, 250)` but that feels way worse.
     */
    watch(
        menuContext.menuState,
        async (value) => {
            const isDetach = params.detach;
            const isVisible = value === menuStates.OPEN;

            if (isDetach && isVisible) {
                const { left, top } = await getContentStyle(params, menuContext);

                contentStyle.left = left;
                contentStyle.top = top;
            }
        },
        { immediate: true }
    );

    return params.detach ? contentStyle : {};
}
