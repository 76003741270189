/**
 * Create a delay in JS thread
 *
 * @param timeout Time in ms to delay
 * @returns Promise
 */
export default function useDelay(): (timeout?: number) => Promise<never> {
    return (timeout) => {
        return new Promise((resolve) => {
            return setTimeout(resolve, timeout);
        });
    };
}
