import axios from 'axios';

import useXavierGlobals from '@/hooks/useXavierGlobals';

import * as types from './types';

export default {
    namespaced: true,
    state() {
        return {
            clientData: [],
        };
    },
    getters: {
        clientData: (state) => state.clientData,
    },
    actions: {
        async setClientData({ commit }, clientData) {
            commit(types.SET_CLIENT_DATA_FROM_IMPORT, clientData);
        },
        async removeClientDataRecordFromClientData({ commit, state }, recordToRemove) {
            //deep copy
            const clientDataArray = JSON.parse(JSON.stringify(state.clientData));

            const recordIndex = state.clientData.findIndex((x) => x.rowIndex === recordToRemove.rowIndex);

            clientDataArray.splice(recordIndex, 1);

            commit(types.SET_CLIENT_DATA_FROM_IMPORT, clientDataArray);
        },
        /**
         * @param commit
         * @param payload
         * {
         *    name: string,
         *    practice_code: ?string,
         *    year_end_day: Number,
         *    year_end_month: Number,
         *    company_number: ?string,
         *    vat_number: ?string,
         *}
         * @returns {Promise<T | {isSuccessful: boolean, data}>}
         */
        async createUnintegratedClient({ commit }, payload) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            return axios
                .post(`/api/teams/${practiceCrn}/unintegrated-client/create`, payload)
                .then((response) => {
                    return Promise.resolve({ isSuccessful: true, data: response.data });
                })
                .catch(({ response }) => {
                    return Promise.resolve({ isSuccessful: false, data: response.data });
                });
        },
    },
    mutations: {
        [types.SET_CLIENT_DATA_FROM_IMPORT](state, clientData) {
            state.clientData = clientData;
        },
    },
};
