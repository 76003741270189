import moment from 'moment';

import { isFeatureEnabled, isQuickbooksEnabled, isTeamLocationInBeta } from '@/utils/features';
import { hasPermission } from '@/utils/permissions';

export const xavier = {
    ...window.Xavier,
    featureEnabled: isFeatureEnabled,
    hasPermission,
    isQuickbooksEnabled,
    isTeamLocationInBeta,
};

export default {
    install(vm) {
        vm.prototype.$xavier = xavier;
        vm.prototype.$moment = moment;
    },
};
