<template>
    <div class="sync-button-container">
        <slot
            :attrs="{ disabled: !canSync, name: translator.t(t.syncClient) }"
            :can-sync="canSync"
            :is-syncing="isSyncing"
            name="activator"
            :on="{ click: attemptSync }"
            :sync-required="syncRequired"
        >
            <VButton
                v-if="!clientLinked(clientId) && mode === 'all' && currentClient && currentClient.isActive"
                :class="className"
                :name="translator.t(t.reconnect, { provider: currentClient.provider })"
                transparent
                @click="triggerLink"
            >
                <template #icon-left>
                    <VIcon class="sync-icon" name="sync" />
                </template>

                {{
                    translator.t(t.reconnect, {
                        provider: currentClient.provider.charAt(0).toUpperCase() + currentClient.provider.slice(1),
                    })
                }}
            </VButton>

            <VButton
                v-else
                :class="className"
                :disabled="!canSync"
                :name="translator.t(t.syncClient)"
                transparent
                @click="attemptSync"
            >
                <template #icon-left>
                    <VIcon class="sync-icon" name="sync" :spin="isSyncing" />
                </template>

                <slot :can-sync="canSync" :is-syncing="isSyncing" :sync-required="syncRequired">
                    {{ isSyncing ? translator.t(t.recalculating) : translator.t(t.recalculate) }}
                </slot>
            </VButton>

            <div v-if="mode === 'all' && currentClient && currentClient.isActive" class="last-sync">
                {{ translator.t(t.lastSync) }}: {{ lastSync }}
            </div>
        </slot>
    </div>
</template>

<script>
import moment from 'moment/moment.js';
import { createNamespacedHelpers, mapGetters } from 'vuex';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { useVueI18n } from '@/composables/useVueI18n';
import ClientProvider from '@/enums/ClientProvider';
import PropValidationError from '@/errors/PropValidationError';
import useDelay from '@/hooks/useDelay';
import useXavierGlobals from '@/hooks/useXavierGlobals';

import { translations as t } from './SyncButton.translations';

const { mapActions } = createNamespacedHelpers('legacyClients');
const { mapActions: mapImportsActions } = createNamespacedHelpers('imports');

export default {
    name: 'SyncButton',
    props: {
        clientId: {
            default: '',
            required: true,
            type: String,
        },

        flowId: {
            default: null,
            required: false,
            type: Number,
        },

        stage: {
            default: 'all',
            required: false,
            type: String,
        },

        params: {
            default: () => ({}),
            required: false,
            type: Object,
        },

        paramsValid: {
            default: true,
            required: false,
            type: Boolean,
        },

        promptForSync: {
            default: false,
            required: false,
            type: Boolean,
        },

        syncAllowed: {
            default: true,
            required: false,
            type: Boolean,
        },

        mode: {
            default: 'insight',
            type: String,
            validator: (value) => {
                const validModes = ['insight', 'dashboard', 'vat', 'health', 'flow', 'all', 'reporting'];
                const isValid = validModes.includes(value);

                if (!isValid) {
                    throw new PropValidationError(`invalid value of "${value}" provided for prop "mode".`);
                }

                return isValid;
            },
        },
    },

    setup() {
        const translator = useVueI18n();

        return { t, translator };
    },

    data() {
        return {
            lastRunParams: _.clone(this.$props.params),
        };
    },

    watch: {
        isSyncing(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                this.$emit('complete', { flowId: this.flowId, mode: this.mode });
            }
        },
        params(newValue) {
            if (!this.lastRunParams) {
                this.lastRunParams = newValue;
            }

            if (this.paramsHaveChanged) {
                window.Bus.$emit('paramsHaveChanged', { clientId: this.clientId, insight: this.stage });
            }
        },
    },

    computed: {
        ...mapGetters({
            clientCanSync: 'legacyClients/clientCanSync',
            clientLinked: 'legacyClients/clientLinked',
            currentClient: 'legacyClients/currentClient',
            isClientImporting: 'imports/isClientImporting',
        }),

        lastSync() {
            if (this.currentClient.lastSuccessfulImport) {
                return moment(this.currentClient.lastSuccessfulImport).fromNow();
            }

            return 'Never';
        },

        className() {
            return {
                'sync-button': true,
                'sync-button--alert': this.syncRequired,
            };
        },

        importParams() {
            return {
                flowId: this.flowId,
                id: this.clientId,
                mode: this.mode,
                params: this.params,
                stage: this.stage,
            };
        },

        syncRequired() {
            return this.promptForSync || this.paramsHaveChanged;
        },

        paramsHaveChanged() {
            return this.params && !_.isEqual(this.params, this.lastRunParams);
        },

        isSyncing() {
            return this.isClientImporting(this.clientId, this.mode, this.stage);
        },

        canSync() {
            return this.syncAllowed && this.clientCanSync(this.clientId, this.mode, this.stage);
        },
    },

    methods: {
        ...mapActions(['syncClient']),
        ...mapImportsActions(['CREATE_IMPORT_PLACEHOLDER', 'UPSERT_IMPORT']),

        triggerLink() {
            const { currentTeam } = useXavierGlobals();

            dispatchIframeEvent({
                action: 'INITIATE_INTEGRATION',
                payload: {
                    clientCrn: this.currentClient.rbExternalId.toString(),
                    practiceCrn: currentTeam.rbExternalId.toString(),
                    product: this.currentClient.provider,
                },
            });
        },

        attemptSync() {
            if (this.currentClient.provider === ClientProvider.PLAYGROUND) {
                this.CREATE_IMPORT_PLACEHOLDER({ clientId: this.clientId, mode: this.mode, stage: this.stage });

                const delay = useDelay();

                delay(3000).then(() => {
                    this.UPSERT_IMPORT({ clientId: this.clientId, completedAt: new Date().toString() });
                    this.$toaster.success(translator.t(t.syncCompleted, { clientName: this.currentClient.name }));
                });

                return;
            }

            if (!this.paramsValid) {
                this.$toaster.warning(translator.t(t.invalidParams));
            } else if (this.canSync) {
                this.syncClient(this.importParams);
                this.CREATE_IMPORT_PLACEHOLDER({ clientId: this.clientId, mode: this.mode, stage: this.stage });

                this.lastRunParams = this.params;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.sync-button-container {
    display: inline-flex;
}

.sync-button--alert {
    color: get-color(orange) !important;
}

.last-sync {
    padding-top: var(--d-spacing-1);
    line-height: 18px;
    color: var(--d-color-gray-medium);
    font-size: var(--d-font-size-medium);
    white-space: nowrap;
    margin: 0 var(--d-spacing-1);
}
</style>
