<template>
    <component :is="as" :class="classNames">
        <slot />
    </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Props = {
    as: 'h1' | 'h2' | 'h3' | 'h4';
};

const props = defineProps<Props>();
const classNames = computed(() => {
    return ['d-heading', `d-${props.as}`];
});
</script>

<script lang="ts">
export default { name: 'DHeading' };
</script>
