<template>
    <Fragment>
        <ModalHeader>
            <template>Client Limit Reached</template>
        </ModalHeader>

        <ModalContent>
            Add more clients to your plan to connect accounting software for this unintegrated client.
        </ModalContent>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import { ModalContent, ModalHeader } from '@/components/Modal';

export default defineComponent({
    components: { Fragment, ModalContent, ModalHeader },
});
</script>

<style scoped></style>
