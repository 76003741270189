<template>
    <div :id="id" :class="className">
        <div class="menu__activator" ref="activatorRef">
            <slot v-bind="activatorSlotProps" name="activator" />
        </div>

        <slot />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, provide, ref, watch } from 'vue';
import * as PropTypes from 'vue-types';
import debounce from 'lodash-es/debounce';

import { useUUID } from '@/mixins/uuid';
import { focusElement, getFocusableElements } from '@/utils/dom';

import MenuStates from './states/MenuStates';
import { MenuContextApi, MenuItem, MenuItemData, MenuItemId } from './types';

export default defineComponent({
    name: 'DMenu',

    emits: ['close', 'open', 'visibility-change'],

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    props: {
        /**
         * Should the `Menu` close on outside click.
         */
        closeOnClick: PropTypes.bool().def(true),

        /**
         * Should the `Menu` close on content click.
         */
        closeOnContentClick: PropTypes.bool().def(false),

        /**
         * Can the `Menu` be activated?
         */
        disabled: PropTypes.bool().def(false),

        /**
         * Used for v-model binding, or for manual visibility control.
         */
        visible: PropTypes.bool().def(false),
    },

    setup(props, context) {
        const uuid = useUUID();
        const buttonUuid = useUUID();
        const menuState = ref(props.visible ? MenuStates.OPEN : MenuStates.CLOSED);
        const activatorRef = ref<HTMLElement>();
        const activeItemIndex = ref(-1);
        const items = ref<MenuItem[]>([]);
        const itemsRef = ref<HTMLElement>();
        const id = `menu_${uuid}`;
        const buttonId = `menu_activator_${buttonUuid}`;

        const onWindowResize = debounce(() => closeMenu(), 100, { leading: true });

        const openMenu = async () => {
            if (props.disabled) return;
            menuState.value = MenuStates.OPEN;
            context.emit('open');
            window.addEventListener('resize', onWindowResize);
        };

        const closeMenu = () => {
            const activatorFocusableElements = getFocusableElements(activatorRef.value);

            activeItemIndex.value = -1;
            menuState.value = MenuStates.CLOSED;
            focusElement(activatorFocusableElements?.[0]);
            context.emit('close');
            window.removeEventListener('resize', onWindowResize);
        };

        const registerItem = (id: MenuItemId, data: MenuItemData) => items.value.push({ id, data });

        const unregisterItem = (id: MenuItemId) => {
            const nextItems = items.value.filter((item) => item.id !== id);
            const nextIndex = nextItems.findIndex((item) => {
                return item.data.isInteractive && !item.data.isDisabled;
            });

            if (activeItemIndex.value > -1) {
                nextIndex > -1 && setItemActive(nextIndex);
            }

            items.value = nextItems;
        };

        const setItemActive = (index: number) => {
            if (!items.value[index].data.isDisabled) {
                activeItemIndex.value = index;
            }
        };

        const focusFirst = () => {
            const nextIndex = items.value.findIndex((item) => {
                return item.data.isInteractive && !item.data.isDisabled;
            });

            if (nextIndex === -1) return;
            setItemActive(nextIndex);
        };

        const focusLast = () => {
            const nextIndex = [...items.value].reverse().findIndex((item) => {
                return item.data.isInteractive && !item.data.isDisabled;
            });

            if (nextIndex === -1) return;
            setItemActive(items.value.length - 1 - nextIndex);
        };

        const focusNext = () => {
            const nextIndex = items.value.findIndex((item, index) => {
                if (index <= activeItemIndex.value) return false;

                return item.data.isInteractive && !item.data.isDisabled;
            });

            if (nextIndex === -1) return;
            setItemActive(nextIndex);
        };

        const focusPrevious = () => {
            const nextIndex = [...items.value].reverse().findIndex((item, index) => {
                const reversedIndex = items.value.length - 1 - index;

                if (reversedIndex >= activeItemIndex.value) return false;

                return item.data.isInteractive && !item.data.isDisabled;
            });

            if (nextIndex === -1) return;
            setItemActive(items.value.length - 1 - nextIndex);
        };

        const api: MenuContextApi = {
            activeItemIndex,
            activatorRef,
            closeMenu,
            closeOnClick: props.closeOnClick,
            closeOnContentClick: props.closeOnContentClick,
            focusFirst,
            focusLast,
            focusNext,
            focusPrevious,
            items,
            itemsRef,
            menuState,
            openMenu,
            registerItem,
            setItemActive,
            unregisterItem,
        };

        const activatorSlotProps = computed(() => {
            return {
                attrs: {
                    'aria-controls': itemsRef.value?.getAttribute('id'),
                    'aria-haspopup': 'menu',
                    'aria-expanded': menuState.value === MenuStates.OPEN,
                    disabled: props.disabled,
                    id: buttonId,
                },
                isVisible: menuState.value === MenuStates.OPEN,
                on: {
                    click: () => {
                        menuState.value === MenuStates.OPEN ? closeMenu() : openMenu();
                    },
                },
            };
        });

        provide<MenuContextApi>('MenuContext', api);

        watch(
            () => props.visible,
            (value) => (value ? openMenu() : closeMenu())
        );

        watch(menuState, (value) => context.emit('visibility-change', value === MenuStates.OPEN));

        onUnmounted(() => {
            window.removeEventListener('resize', onWindowResize);
        });

        return {
            activatorRef,
            activatorSlotProps,
            className: {
                menu: true,
                'menu--is-visible': computed(() => menuState.value === MenuStates.OPEN),
            },
            closeMenu,
            id,
            items,
            menuState,
            openMenu,
        };
    },
});
</script>

<style lang="scss" scoped>
.menu {
    display: inline-flex;
    position: relative;
}
</style>
