<template>
    <div :class="className">
        <div class="menu-section__title" v-if="hasTitleSlot">
            <slot name="title" />
        </div>

        <div class="menu-section__items">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import PropTypes from 'vue-types';
import { defineComponent } from 'vue';

import useHasSlot from '@/hooks/useHasSlot';

export default defineComponent({
    name: 'DMenuSection',

    props: {
        horizontal: PropTypes.bool.def(false),
    },

    setup(props) {
        return {
            className: {
                'menu-section': true,
                'menu-section--horizontal': props.horizontal,
            },
            hasTitleSlot: useHasSlot('title'),
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.menu-section {
    & + & {
        margin-top: 12px;
    }

    .menu-content--medium & + & {
        margin-top: 25px;
    }
}

.menu-section--horizontal {
    padding-left: 16px;
    padding-right: 16px;
}

.menu-section__items {
    display: flex;
    flex-wrap: wrap;
}
</style>
