<template>
    <Modal v-model="isVisible" name="workflow.details.handback" @confirm="handBack">
        <ModalHeader>{{ translator.t(t.title) }}</ModalHeader>
        <ModalContent>
            <p>
                {{ translator.t(t.leaveMessage, { userName: flow.userName }) }}
            </p>

            <TextAreaField
                v-model="message"
                autogrow
                :autogrow-max-lines="10"
                :hint="translator.t(t.messageHint)"
                :label="translator.t(t.messageLabel)"
                name="handback-message"
                qa-name="workflow.details.handback-modal.message"
            />
        </ModalContent>

        <ModalFooter>
            <template #right>
                <PButton qa-name="workflow.details.handback-modal.close" @click="isVisible = false">
                    {{ translator.t(t.close) }}
                </PButton>

                <PButton
                    :loading="busy"
                    qa-name="workflow.details.handback-modal.hand-back"
                    variant="primary"
                    @click="handBack"
                >
                    {{ translator.t(t.handBack) }}
                </PButton>
            </template>
        </ModalFooter>
    </Modal>
</template>

<script>
import { useVModel } from '@vueuse/core';
import { defineComponent, ref } from 'vue';
import { mapActions } from 'vuex';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { TextAreaField } from '@/components/Form';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '@/components/Modal';
import { PButton } from '@/components/PButton';
import { useVueI18n } from '@/composables/useVueI18n';
import { IS_EMBEDDED } from '@/config/env';
import logger from '@/utils/logger';

import { translations as t } from './HandBack.translations';

export default defineComponent({
    name: 'HandBack',

    components: {
        Modal,
        ModalContent,
        ModalFooter,
        ModalHeader,
        PButton,
        TextAreaField,
    },

    props: {
        flow: { required: true, type: Object },
        value: { required: false, type: Boolean },
    },

    setup(props) {
        const busy = ref(false);
        const message = ref('');
        const isVisible = useVModel(props);
        const translator = useVueI18n();

        return {
            busy,
            isVisible,
            message,
            t,
            translator,
        };
    },

    methods: {
        ...mapActions({
            cancelFlowReview: 'flows/cancelFlowReview',
        }),

        async handBack() {
            this.busy = true;

            try {
                const data = {
                    id: this.flow.id,
                    message: this.message,
                };

                await this.cancelFlowReview(data);

                this.isVisible = false;
                this.message = '';
                this.busy = false;

                await this.$nextTick();

                if (IS_EMBEDDED) {
                    return dispatchIframeEvent({
                        action: 'NAVIGATE',
                        payload: {
                            clientCrn: this.flow.clientCrn.toString(),
                            pathName: 'CLIENT_WORKFLOWS',
                            practiceCrn: this.flow.teamCrn.toString(),
                        },
                    });
                }

                this.$router.push({ name: 'team.focus' });
            } catch (ex) {
                logger.error(ex);
            }
        },
    },
});
</script>
