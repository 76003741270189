import { MutationTree } from 'vuex';
import { LockDateActivityState, WidgetResultsState } from '@/store/modules/insightsDashboard/lockDateActivity/types';

export enum LockDateActivityMutationsEnum {
    SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING = 'SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING',
    SET_LOCK_DATE_ACTIVITY_WIDGET_RESULTS = 'SET_LOCK_DATE_ACTIVITY_WIDGET_RESULTS',
}

export type LockDateActivityMutations<State = LockDateActivityState> = {
    [LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING](state: State, isLoading: boolean): void;
    [LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_RESULTS](
        state: State,
        results: WidgetResultsState
    ): void;
};

export const mutations: MutationTree<LockDateActivityState> & LockDateActivityMutations = {
    [LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING](state, isLoading) {
        state.lockDateActivity.widget.isLoading = isLoading;
    },

    [LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_RESULTS](state, results) {
        state.lockDateActivity.widget.results = {
            ...{
                no_lock_date_set: 0,
                lock_date_over_12_months: 0,
                lock_date_under_12_months: 0,
                lock_date_under_6_months: 0,
                lock_date_under_3_months: 0,
            },
            ...results,
        };
    }
};
