import directorsLoanAccountsModule from './directorsLoanAccounts';
import averageHealthScoreModule from './averageHealthScore';
import flowsDueModule from './flowsDue';
import reconciliationActivityModule from './reconciliationActivity';
import lockDateActivityModule from './lockDateActivity';
import totalTransactionsModule from './totalTransactions';
import InsightsDashboardService from '@/services/Api/InsightsDashboardService';
import * as types from './types';

export default {
    namespaced: true,
    modules: {
        directorsLoanAccountsModule,
        averageHealthScoreModule,
        flowsDueModule,
        reconciliationActivityModule,
        lockDateActivityModule,
        totalTransactionsModule,
    },
    state: {
        hiddenClients: 0,
    },
    getters: {
        hiddenClients: (state) => state.hiddenClients,
    },
    actions: {
        async fetchHiddenClientsCountForUser({ commit }, { integratedClientsOnly }) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            if (integratedClientsOnly) {
                await InsightsDashboardService.getHiddenIntegratedClientsCount(practiceCrn).then((response) => {
                    commit(types.SET_HIDDEN_CLIENT_COUNT, response.result);
                });
            } else {
                await InsightsDashboardService.getHiddenClientsCount(practiceCrn).then((response) => {
                    commit(types.SET_HIDDEN_CLIENT_COUNT, response.result);
                });
            }
        },
    },
    mutations: {
        [types.SET_HIDDEN_CLIENT_COUNT](state, invisibleClientCount) {
            state.hiddenClients = invisibleClientCount;
        },
    },
};
