import { getBrowserLocale } from './browser';

export function formatCurrency(input: number, currency = 'GBP', decimals = 2) {
    if ([null, undefined, Infinity].indexOf(input) !== -1) {
        return '';
    }

    const locale = getBrowserLocale();

    return Number(input).toLocaleString(locale, {
        currency,
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
        style: 'currency',
    });
}
