<template>
    <div v-if="!basic" v-show="isVisible" :class="className">
        <img :alt="altText" :aria-hidden="decorative" class="spinner-image" :src="variant" />
    </div>

    <div v-else v-show="isVisible" :class="className" v-html="computedSpinner" />
</template>

<script>
import { stringToElement } from '@/utils/dom';

import loaderBasic from './loader-basic';

import loader from '/svg/spinner/loader.svg?url';
import loaderSimple from '/svg/spinner/loader-simple.svg?url';

export default {
    props: {
        basic: { type: Boolean, default: false },
        centered: { type: Boolean, default: true },
        decorative: { type: Boolean, default: false },
        showDelay: { type: Number, default: 300 },
        simple: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
    },

    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        altText() {
            if (this.decorative) {
                return;
            }

            if (this.basic) return 'Basic loading spinner';
            if (this.simple) return 'Orange-coloured loading placeholder';

            return 'Orange-coloured loading placeholder with Dext logo';
        },

        className() {
            return {
                spinner: true,
                'spinner--centered': this.centered,
                'spinner--mini': this.small,
                'spinner--basic': this.basic,
            };
        },

        variant() {
            if (this.simple) return loaderSimple;
            if (this.basic) return loaderBasic;

            return loader;
        },

        computedSpinner() {
            if (!this.basic) return null;

            const spinnerEl = stringToElement(loaderBasic);

            spinnerEl.classList.add('spinner-basic-svg');

            return spinnerEl.outerHTML;
        },
    },

    created() {
        setTimeout(() => {
            this.isVisible = true;
        }, this.showDelay);
    },
};
</script>

<style lang="scss">
@use 'sass:math';
@import 'style/dext/includes';

$size: 50px;
$size-small: 35px;
$size-basic: 16px;

.spinner {
    align-items: flex-end;
    display: flex;
    height: $size;
    margin: 0 auto;
    width: $size;

    &--centered {
        left: calc(50% - #{math.div($size, 2)});
        position: absolute;
        top: calc(50% - #{math.div($size, 2)});
    }

    &--mini {
        height: $size-small;
        margin-bottom: 20px;
        margin-top: 20px;
        width: $size-small;
    }

    &--basic {
        height: $size-basic;
        width: $size-basic;
    }
}

.spinner-image {
    display: block;
    height: 100%;
    width: 100%;
}

.spinner-basic-svg {
    vertical-align: top;
}
</style>
