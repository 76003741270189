import { inject } from 'vue';
import type { ContentContainerApi } from '../LayoutContentTypes';

export const ContentContainerContext = Symbol('ContentContainerContext');

/**
 * Inject the Content Container Context.
 *
 * @param {String} componentName
 * @returns {State}
 */
export default function useContentContainerContext(componentName: string) {
    const menuContext = inject<ContentContainerApi | null>(ContentContainerContext, null);

    if (menuContext === null) {
        const err = new Error(`<${componentName} /> is missing a parent <ContentContainer /> component.`);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(err, useContentContainerContext);
        }

        throw err;
    }

    return menuContext;
}
