import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientService from '@/services/Api/ClientService';
import createMeta from '@/store/utils/createMeta';
import isClient from '@/utils/clients';

import type { ClientActions, ClientState, ClientGetters, ClientMutations } from './types/Store';
import type { ActionTree, Module, GetterTree, MutationTree } from 'vuex';
import type { RootState } from '@/store/types';

import { ClientActionTypes, ClientMutationTypes } from './types/Store';

import { NAMESPACE as clientsNamespace, ClientsMutationTypes } from '../clients/clients.store';
import * as legacyClientsTypes from '../clients_LEGACY/types';

const meta = createMeta();

export const clientInitialState = {
    ...meta.getInitialState(),
};

/* Actions
========================================================================== */
const actions: ActionTree<ClientState, RootState> & ClientActions = {
    async [ClientActionTypes.LOAD_CLIENT]({ commit, state }) {
        if (meta.isInProgress(state)) {
            return Promise.reject({
                code: '202',
                success: false,
                message: 'Already in flight',
            });
        }

        commit(ClientMutationTypes.LOAD_CLIENT_START);

        try {
            const client = await ClientService.getClientById(
                useXavierGlobals().currentTeam.rbExternalId,
                state.data.id,
                {
                    include: ['trackingCategories'].join(','),
                }
            );

            commit(ClientMutationTypes.LOAD_CLIENT_SUCCESS, { client: client.data });

            return Promise.resolve({ success: true });
        } catch (error) {
            commit(ClientMutationTypes.LOAD_CLIENT_FAILURE, { error });

            return Promise.reject({ success: false });
        }
    },

    async [ClientActionTypes.DELETE_CLIENT]({ commit, state }) {
        if (meta.isInProgress(state)) {
            return Promise.reject({
                code: '202',
                success: false,
                message: 'Already in flight',
            });
        }

        if (!state.data) {
            return Promise.reject({
                code: '404',
                success: false,
                message: 'Client not found',
            });
        }

        commit(ClientMutationTypes.LOAD_CLIENT_START);

        try {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await ClientService.deleteClient(practiceCrn, state.data.id);
            commit(ClientMutationTypes.DELETE_CLIENT_SUCCESS);
            commit(
                `${clientsNamespace}/${ClientsMutationTypes.CLIENT_DELETED}`,
                { clientSlug: state.data.slug },
                { root: true }
            );
            commit(`legacyClients/${legacyClientsTypes.REMOVE_CLIENT}`, state.data.id, { root: true });

            return Promise.resolve({ success: true });
        } catch (error) {
            commit(ClientMutationTypes.LOAD_CLIENT_FAILURE, { error });

            return Promise.reject({ success: false });
        }
    },

    async [ClientActionTypes.FLAG_CLIENT]({ commit, state }, { hasFlagged }) {
        if (!state.data) {
            return Promise.reject({
                code: '404',
                success: false,
                message: 'Client not found',
            });
        }

        try {
            commit(ClientMutationTypes.FLAG_CLIENT_SUCCESS);

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await ClientService.postFlagClient(practiceCrn, state.data.id);

            return Promise.resolve({ success: true });
        } catch (error) {
            commit(ClientMutationTypes.LOAD_CLIENT_FAILURE, {
                data: { hasFlagged: !hasFlagged },
                error,
            });

            return Promise.reject({ success: false });
        }
    },
};

/* Mutations
========================================================================== */
const mutations: MutationTree<ClientState> & ClientMutations = {
    [ClientMutationTypes.LOAD_CLIENT_START](state) {
        meta.startProgress(state);
    },

    [ClientMutationTypes.LOAD_CLIENT_SUCCESS](state, { client }) {
        meta.stopProgress(state);
        meta.setSuccess(state, true);

        Vue.set(state, 'data', client);
    },

    [ClientMutationTypes.DELETE_CLIENT_SUCCESS](state) {
        meta.stopProgress(state);
        meta.setSuccess(state, true);
    },

    [ClientMutationTypes.FLAG_CLIENT_SUCCESS](state) {
        meta.stopProgress(state);
        meta.setSuccess(state, true);

        if (isClient(state.data)) {
            state.data.hasFlagged = !state.data.hasFlagged;
        }
    },

    [ClientMutationTypes.LOAD_CLIENT_FAILURE](state, { data, error }) {
        meta.stopProgress(state);
        meta.setError(state, error);

        if (data && state.data) {
            Vue.set(state, 'data', {
                ...state.data,
                ...data,
            });
        }
    },
};

/* Getters
========================================================================== */
const getters: GetterTree<ClientState, RootState> & ClientGetters = {
    //
};

const clientStore: Module<ClientState, RootState> = {
    namespaced: true,
    actions,
    getters,
    mutations,
};

export default clientStore;
