import Vue from 'vue';
import axios from 'axios';
import * as types from './types';

export default {
    namespaced: true,
    state: {
        adminReports: [],
    },
    actions: {
        async loadReports({ commit }) {
            const { data } = await axios.get('/api/status/admin-reports');

            commit(types.REPORTS_LOADED, data.data);
        },
        async saveReport({ dispatch }, details) {
            const { data } = await axios.post('/api/status/admin-reports', details);

            dispatch('loadReports');

            return data.id;
        },
        async updateReport({ commit }, details) {
            await axios.post(`/api/status/admin-reports/${details.id}`, details);

            commit(types.REPORT_CHANGED, details);
        },
        async deleteReport({ commit }, id) {
            await axios.delete(`/api/status/admin-reports/${id}`);

            commit(types.REMOVE_REPORT, id);
        },
    },
    mutations: {
        [types.REPORTS_LOADED](state, reports) {
            state.adminReports = reports;
        },
        [types.REMOVE_REPORT](state, id) {
            const reportIndex = state.adminReports.findIndex((r) => r.id === id);

            state.adminReports.slice(reportIndex, 1);
        },
        [types.REPORT_CHANGED](state, details) {
            const reportIndex = state.adminReports.findIndex((r) => r.id === details.id);

            Vue.set(state.adminReports, reportIndex, {
                ...state.adminReports[reportIndex],
                ...details,
            });
        },
    },
};
