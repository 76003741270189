import { z } from 'zod';

import { ClientNavigationPathNames, TeamNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import { WorkflowCreatorStage } from '@/enums/WorkflowCreatorStage';

export const SendNavigateTeamPayloadSchema = z.union([
    z.object({
        pathName: z.enum([
            TeamNavigationPathNames.PRACTICE_DASHBOARD,
            TeamNavigationPathNames.REPEATING_WORKFLOWS,
            TeamNavigationPathNames.WORKFLOWS,
            TeamNavigationPathNames.WORKFLOW_DRAFTS,
            TeamNavigationPathNames.WORKFLOW_DASHBOARD,
        ]),
        practiceCrn: z.string(),
    }),

    z.object({
        pathName: z.literal(TeamNavigationPathNames.NEW_WORKFLOW),
        practiceCrn: z.string(),
        type: z.enum(['repeating', 'one_off']),
    }),

    z.object({
        pathName: z.literal(TeamNavigationPathNames.EDIT_WORKFLOW),
        practiceCrn: z.string(),
        step: z.nativeEnum(WorkflowCreatorStage),
        type: z.enum(['repeating', 'one_off']),
        workflowSlug: z.string(),
    }),

    z.object({
        dashboardId: z.string(),
        pathName: z.literal(TeamNavigationPathNames.CUSTOM_DASHBOARD_DETAILS),
        practiceCrn: z.string(),
        type: z.string(),
    }),
]);

export const SendNavigateClientPayloadSchema = z.union([
    z.object({
        clientCrn: z.string(),
        pathName: z.enum([
            ClientNavigationPathNames.CLIENT_ACTIVITY_STATS,
            ClientNavigationPathNames.CLIENT_CONTROL_ACCOUNTS,
            ClientNavigationPathNames.CLIENT_CONNECTION_SETTINGS,
            ClientNavigationPathNames.CLIENT_OVERVIEW,
            ClientNavigationPathNames.CLIENT_REPORT_CREATOR,
            ClientNavigationPathNames.CLIENT_WORKFLOWS,
        ]),
        practiceCrn: z.string(),
    }),
    z.object({
        clientCrn: z.string(),
        pathName: z.literal(ClientNavigationPathNames.CLIENT_WORKFLOW_DETAILS),
        practiceCrn: z.string(),
        workflowSlug: z.string(),
    }),
    z.object({
        clientCrn: z.string(),
        dashboardId: z.string(),
        pathName: z.literal(ClientNavigationPathNames.CLIENT_CUSTOM_REPORT_DETAILS),
        practiceCrn: z.string(),
    }),
]);

export const SendNavigatePayloadSchema = z.union([SendNavigateClientPayloadSchema, SendNavigateTeamPayloadSchema]);
