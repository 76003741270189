import { ClientsMutations } from '@/store/modules/clients/clients.store';
import { AugmentedActionContext } from '@/store/types';
import { MetaStore } from '@/store/utils/createMeta';

import { BasicClient, Client, ClientId } from './Client';

import { REMOVE_CLIENT } from '../../clients_LEGACY/types';

type LegacyClientsRemoveClient = `legacyClients/${typeof REMOVE_CLIENT}`;

/* Actions
========================================================================== */
export type ActionContext = AugmentedActionContext<
    ClientState,
    ClientGetters,
    ClientMutations &
        ClientsMutations & {
            [key in LegacyClientsRemoveClient]: (state: unknown, id: ClientId) => void;
        }
>;

export enum ClientActionTypes {
    DELETE_CLIENT = 'DELETE_CLIENT',
    FLAG_CLIENT = 'FLAG_CLIENT',
    LOAD_CLIENT = 'LOAD_CLIENT',
}

type ActionResponse = Promise<{ message: string } | { success: boolean }>;

export interface ClientActions {
    [ClientActionTypes.LOAD_CLIENT](context: ActionContext): ActionResponse;
    [ClientActionTypes.DELETE_CLIENT](context: ActionContext): ActionResponse;
    [ClientActionTypes.FLAG_CLIENT](context: ActionContext, payload: { hasFlagged: boolean }): ActionResponse;
}

/* Mutations
========================================================================== */
export enum ClientMutationTypes {
    LOAD_CLIENT_START = 'LOAD_CLIENT_START',
    LOAD_CLIENT_FAILURE = 'LOAD_CLIENT_FAILURE',
    LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS',

    DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
    FLAG_CLIENT_SUCCESS = 'FLAG_CLIENT_SUCCESS',
}

export type ClientMutations<State = ClientState> = {
    [ClientMutationTypes.LOAD_CLIENT_START](state: State): void;
    [ClientMutationTypes.LOAD_CLIENT_FAILURE](
        state: State,
        payload: {
            data?: Partial<Client>;
            error: unknown;
        }
    ): void;
    [ClientMutationTypes.LOAD_CLIENT_SUCCESS](state: State, payload: { client: Client }): void;
    [ClientMutationTypes.DELETE_CLIENT_SUCCESS](state: State): void;
    [ClientMutationTypes.FLAG_CLIENT_SUCCESS](state: State): void;
};

/* Getters
========================================================================== */
export type ClientGetters = Record<string, never>;

/* State
========================================================================== */
export interface ClientState extends MetaStore {
    data: BasicClient | Client;
}
