import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import TeamService from '@/services/Api/TeamService';

import * as types from './types';
// @ts-ignore
// @ts-ignore
export default {
    namespaced: true,
    state() {
        return {
            dextUsers: {},
            users: {},
            loading: false,
        };
    },
    getters: {
        loading: (state) => state.loading,
        userList: (state) => _.sortBy(Object.values(state.users), 'name'),
        dextUserList: (state) => _.sortBy(Object.values(state.dextUsers), (data) => data.user.name),
        currentDextUser: (state) => {
            return Object.values(state.dextUsers).find((dextUser) => {
                return dextUser.user.id === window.Xavier.userRbInternalId;
            });
        },
        currentUser: (state) => {
            return Object.values(state.users).find((user) => {
                return user.id === window.Xavier.userId;
            });
        },
    },
    actions: {
        async loadUserList({ commit }) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            TeamService.getTeamMembers(practiceCrn).then((response) => {
                commit(types.USER_LIST_LOADED, response.data);
            });
        },
        async loadDextUserList({ commit }) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            TeamService.getTeamDextMembers(practiceCrn).then((response) => {
                commit(types.DEXT_USER_LIST_LOADED, response.data);
            });
        },
        async addDextMemberToCurrentTeam({ commit, state }, member) {
            commit(types.LOADING_STARTED);

            try {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.post(`/api/teams/${practiceCrn}/team/members/add-dext-member`, member);

                commit(types.DEXT_USER_ADDED_TO_TEAM, data.data);

                return true;
            } catch (error) {
                state.loading = false;

                throw error;
            }
        },
        async updateDailyDigest({ commit }, switchValue) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios
                .put(`/api/teams/${practiceCrn}/user/updateDailyDigest`, { daily_digest: switchValue })
                .then(() => commit(types.DEXT_USER_CHANGE_DAILY_DIGEST, switchValue))
                .catch(() =>
                    alert(
                        'The daily digest setting has not updated correctly. Please contact support if the error persists.'
                    )
                );
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.USER_LIST_LOADED](state, userList) {
            for (const user of userList) {
                Vue.set(state.users, user.id, {
                    ...state.users[user.id],
                    ...user,
                });
            }

            for (const id of Object.keys(state.users)) {
                if (userList.every((u) => u.id != id)) {
                    Vue.delete(state.users, id);
                }
            }

            state.loading = false;
        },

        [types.DEXT_USER_LIST_LOADED](state, userList) {
            for (const dextUser of userList) {
                Vue.set(state.dextUsers, dextUser.user.id, {
                    ...state.dextUsers[dextUser.user.id],
                    ...dextUser,
                });
            }

            state.loading = false;
        },

        [types.DEXT_USER_ADDED_TO_TEAM](state, user) {
            Vue.set(state.users, user.id, {
                ...state.users[user.id],
                ...user,
            });

            state.loading = false;
        },

        [types.DEXT_USER_CHANGE_DAILY_DIGEST](state, switchValue) {
            const currentUser = Object.values(state.users).find((user) => {
                return user.id === window.Xavier.userId;
            });

            currentUser.dailyDigest = switchValue;
        },
    },
};
