import Vue from 'vue';

import ClientService from '@/services/Api/ClientService';
import * as types from './types';

export default {
    namespaced: true,
    state() {
        return {
            accounts: {},
            loading: false,
        };
    },
    getters: {
        loading: (state) => state.loading,
        accountList: (state) => Object.values(state.accounts),
        expenseAccountList: (state) => {
            return Object.values(state.accounts).filter(
                (account) => account.class === 'EXPENSE' || account.class === 'LIABILITY'
            );
        },
    },
    actions: {
        async loadAccountList({ commit }, clientId) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await ClientService.getClientAccounts(practiceCrn, clientId);

            commit(types.ACCOUNT_LIST_LOADED, data);
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },

        [types.ACCOUNT_LIST_LOADED](state, accountList) {
            for (const account of accountList) {
                Vue.set(state.accounts, account.id, {
                    ...state.accounts[account.id],
                    ...account,
                });
            }

            for (const id of Object.keys(state.accounts)) {
                const shouldDelete = accountList.every((a) => a.id != id);

                if (shouldDelete) {
                    Vue.delete(state.accounts, id);
                }
            }

            state.loading = false;
        },
    },
};
