import { z } from 'zod';

import { ClientNavigationPathNames, TeamNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import { WorkflowCreatorStage } from '@/enums/WorkflowCreatorStage';

const receiveTeamNavigatePathNames = {
    [TeamNavigationPathNames.ACTIVITY_DASHBOARD]: 'team.practice-dashboards.activity-stats',
    [TeamNavigationPathNames.API_TOKENS]: 'team.apiSettings',
    [TeamNavigationPathNames.CUSTOM_DASHBOARDS]: 'team.practice-dashboards.custom-dashboards',
    [TeamNavigationPathNames.DEADLINES_DASHBOARD]: 'team.practice-dashboards.deadlines',
    [TeamNavigationPathNames.HEALTH_SCORE]: 'team.customHealthScores.view',
    [TeamNavigationPathNames.INSIGHTS_DASHBOARD]: 'team.practiceInsightsDashboard',
    [TeamNavigationPathNames.LOCK_DATES]: 'team.lockDates',
    [TeamNavigationPathNames.MTD_DASHBOARD]: 'team.practice-dashboards.mtd',
    [TeamNavigationPathNames.NOTIFICATION_SETTINGS]: 'team.notificationSettings',
    [TeamNavigationPathNames.PRACTICE_DASHBOARD]: 'team.practice-dashboards.practice',
    [TeamNavigationPathNames.PRACTICE_INTEGRATIONS]: 'team.integrations.unified',
    [TeamNavigationPathNames.REPEATING_WORKFLOWS]: 'team.workflows.repeating.list',
    [TeamNavigationPathNames.TAGS]: 'team.tags',
    [TeamNavigationPathNames.WORKFLOW_CHECKS]: 'team.workflows.custom-checks.list',
    [TeamNavigationPathNames.WORKFLOW_DASHBOARD]: 'team.practice-dashboards.workflows',
    [TeamNavigationPathNames.WORKFLOW_DRAFTS]: 'team.workflows.drafts.list',
    [TeamNavigationPathNames.WORKFLOW_TEMPLATES]: 'team.workflows.templates.list',
    [TeamNavigationPathNames.WORKFLOWS]: 'team.workflows.list',
} as const;

const receiveClientNavigatePathNames = {
    [ClientNavigationPathNames.CLIENT_ABN_VALIDATION]: 'client.cleanup.abnValidation.dextified',
    [ClientNavigationPathNames.CLIENT_ACTIVITY_STATS]: 'client.insights.activityStats',
    [ClientNavigationPathNames.CLIENT_AGED_BALANCES]: 'client.insights.agedBalances',
    [ClientNavigationPathNames.CLIENT_BANK_RECONCILIATION]: 'client.cleanup.bankReconciliation.unified',
    [ClientNavigationPathNames.CLIENT_BENCHMARKING]: 'client.insights.performanceBenchmarking',
    [ClientNavigationPathNames.CLIENT_CONTACT_DETAILS]: 'client.cleanup.contactDefaults.dextified',
    [ClientNavigationPathNames.CLIENT_CONTROL_ACCOUNTS]: 'client.insight.controlAccounts.dextified',
    [ClientNavigationPathNames.CLIENT_CORPORATION_TAX]: 'client.insight.corporationTax.unified',
    [ClientNavigationPathNames.CLIENT_COST_ANALYSIS]: 'client.insights.costAnalysis',
    [ClientNavigationPathNames.CLIENT_CUSTOM_REPORTS]: 'client.reports.unified',
    [ClientNavigationPathNames.CLIENT_DORMANT_ACCOUNTS]: 'client.cleanup.dormantAccounts.dextified',
    [ClientNavigationPathNames.CLIENT_DUPLICATE_CONTACTS]: 'client.cleanup.duplicateContacts',
    [ClientNavigationPathNames.CLIENT_DUPLICATE_TRANSACTIONS]: 'client.cleanup.duplicateTransactions.unified',
    [ClientNavigationPathNames.CLIENT_ENTERTAINMENT]: 'client.tax.entertainmentAccounts',
    [ClientNavigationPathNames.CLIENT_FIXED_ASSETS]: 'client.insight.fixedAssets.unified',
    [ClientNavigationPathNames.CLIENT_HISTORICAL_ACTIVITY]: 'client.insight.historicalChanges.dextified',
    [ClientNavigationPathNames.CLIENT_LOCK_DATE_HISTORY]: 'client.insight.lockDates.dextified',
    [ClientNavigationPathNames.CLIENT_METRICS]: 'client.insights.metrics',
    [ClientNavigationPathNames.CLIENT_MULTI_CODED_CONTACTS]: 'client.cleanup.multi-codedContacts.dextified',
    [ClientNavigationPathNames.CLIENT_OVERVIEW]: 'client.overview',
    [ClientNavigationPathNames.CLIENT_REPORT_CREATOR]: 'client.sandbox.unified',
    [ClientNavigationPathNames.CLIENT_SALES_ANALYSIS]: 'client.insights.salesAnalysis',
    [ClientNavigationPathNames.CLIENT_UNRECONCILED_TRANSACTIONS]: 'client.cleanup.unreconciledTransactions.unified',
    [ClientNavigationPathNames.CLIENT_VAT_RETURN_CHECKS]: 'client.check.vatReturn.unified',
    [ClientNavigationPathNames.CLIENT_WORKFLOWS]: 'client.workflows.list',
    [ClientNavigationPathNames.CLIENT_HEALTH_SCORE_DETAILS]: 'client.overview.health-score',
} as const;

export const ReceiveNavigateTeamPayloadSchema = z
    .object({
        pathName: z.enum([
            TeamNavigationPathNames.ACTIVITY_DASHBOARD,
            TeamNavigationPathNames.API_TOKENS,
            TeamNavigationPathNames.CUSTOM_DASHBOARDS,
            TeamNavigationPathNames.DEADLINES_DASHBOARD,
            TeamNavigationPathNames.HEALTH_SCORE,
            TeamNavigationPathNames.INSIGHTS_DASHBOARD,
            TeamNavigationPathNames.LOCK_DATES,
            TeamNavigationPathNames.MTD_DASHBOARD,
            TeamNavigationPathNames.NOTIFICATION_SETTINGS,
            TeamNavigationPathNames.PRACTICE_DASHBOARD,
            TeamNavigationPathNames.PRACTICE_INTEGRATIONS,
            TeamNavigationPathNames.REPEATING_WORKFLOWS,
            TeamNavigationPathNames.TAGS,
            TeamNavigationPathNames.WORKFLOW_CHECKS,
            TeamNavigationPathNames.WORKFLOW_DASHBOARD,
            TeamNavigationPathNames.WORKFLOW_DRAFTS,
            TeamNavigationPathNames.WORKFLOW_TEMPLATES,
            TeamNavigationPathNames.WORKFLOWS,
        ]),
        practiceCrn: z.string(),
    })
    .transform((value) => {
        return {
            name: receiveTeamNavigatePathNames[value.pathName],
            params: { teamRbExternalId: value.practiceCrn },
        };
    });

export const ReceiveNavigateClientPayloadSchema = z
    .object({
        clientCrn: z.string(),
        practiceCrn: z.string(),

        pathName: z.enum([
            ClientNavigationPathNames.CLIENT_ABN_VALIDATION,
            ClientNavigationPathNames.CLIENT_ACTIVITY_STATS,
            ClientNavigationPathNames.CLIENT_AGED_BALANCES,
            ClientNavigationPathNames.CLIENT_BANK_RECONCILIATION,
            ClientNavigationPathNames.CLIENT_BENCHMARKING,
            ClientNavigationPathNames.CLIENT_CONTACT_DETAILS,
            ClientNavigationPathNames.CLIENT_CONTROL_ACCOUNTS,
            ClientNavigationPathNames.CLIENT_CORPORATION_TAX,
            ClientNavigationPathNames.CLIENT_COST_ANALYSIS,
            ClientNavigationPathNames.CLIENT_CUSTOM_REPORTS,
            ClientNavigationPathNames.CLIENT_DORMANT_ACCOUNTS,
            ClientNavigationPathNames.CLIENT_DUPLICATE_CONTACTS,
            ClientNavigationPathNames.CLIENT_DUPLICATE_TRANSACTIONS,
            ClientNavigationPathNames.CLIENT_ENTERTAINMENT,
            ClientNavigationPathNames.CLIENT_FIXED_ASSETS,
            ClientNavigationPathNames.CLIENT_HEALTH_SCORE_DETAILS,
            ClientNavigationPathNames.CLIENT_HISTORICAL_ACTIVITY,
            ClientNavigationPathNames.CLIENT_LOCK_DATE_HISTORY,
            ClientNavigationPathNames.CLIENT_METRICS,
            ClientNavigationPathNames.CLIENT_MULTI_CODED_CONTACTS,
            ClientNavigationPathNames.CLIENT_OVERVIEW,
            ClientNavigationPathNames.CLIENT_REPORT_CREATOR,
            ClientNavigationPathNames.CLIENT_SALES_ANALYSIS,
            ClientNavigationPathNames.CLIENT_UNRECONCILED_TRANSACTIONS,
            ClientNavigationPathNames.CLIENT_VAT_RETURN_CHECKS,
            ClientNavigationPathNames.CLIENT_WORKFLOWS,
        ]),
    })
    .transform((value) => {
        return {
            name: receiveClientNavigatePathNames[value.pathName],
            params: { clientSlug: value.clientCrn, teamRbExternalId: value.practiceCrn },
        };
    });

export const ReceiveClientWorkflowDetailsPayloadSchema = z
    .object({
        clientCrn: z.string(),
        pathName: z.literal(ClientNavigationPathNames.CLIENT_WORKFLOW_DETAILS),
        practiceCrn: z.string(),
        workflowSlug: z.string(),
    })
    .transform((value) => {
        return {
            name: 'client.workflows.details',
            params: {
                clientSlug: value.clientCrn,
                teamRbExternalId: value.practiceCrn,
                workflowSlug: value.workflowSlug,
            },
        };
    });

export const ReceiveNewWorkflowPayloadSchema = z
    .object({
        pathName: z.literal(TeamNavigationPathNames.NEW_WORKFLOW),
        practiceCrn: z.string(),
        type: z.enum(['repeating', 'one_off']),
    })
    .transform((value) => {
        return {
            name: 'team.workflows.create',
            params: { teamRbExternalId: value.practiceCrn },
            query: {
                draft: 'true',
                type: value.type === 'repeating' ? 'repeating' : null,
            },
        };
    });

export const ReceiveEditWorkflowPayloadSchema = z
    .object({
        pathName: z.literal(TeamNavigationPathNames.EDIT_WORKFLOW),
        practiceCrn: z.string(),
        step: z.nativeEnum(WorkflowCreatorStage),
        type: z.enum(['repeating', 'one_off']),
        workflowSlug: z.string(),
    })
    .transform((value) => {
        return {
            name: 'team.workflows.create',
            params: { teamRbExternalId: value.practiceCrn },

            query: {
                slug: value.workflowSlug,
                stage: value.step,
                type: value.type === 'repeating' ? 'repeating' : null,
            },
        };
    });

export const ReceiveClientCustomReportDetailsPayloadSchema = z
    .object({
        clientCrn: z.string(),
        dashboardId: z.string(),
        pathName: z.literal(ClientNavigationPathNames.CLIENT_CUSTOM_REPORT_DETAILS),
        practiceCrn: z.string(),
    })
    .transform((value) => {
        return {
            name: 'client.customReport.details.unified',
            params: {
                clientSlug: value.clientCrn,
                reportId: value.dashboardId,
                teamRbExternalId: value.practiceCrn,
            },
        };
    });

export const ReceiveCustomDashboardDetailsPayloadSchema = z
    .object({
        dashboardId: z.string(),
        pathName: z.literal(TeamNavigationPathNames.CUSTOM_DASHBOARD_DETAILS),
        practiceCrn: z.string(),
        type: z.string(),
    })
    .transform((value) => {
        return {
            name: `team.customReport.${value.type}.details.unified`,
            params: { reportId: value.dashboardId, teamRbExternalId: value.practiceCrn },
        };
    });

export const ReceiveNavigatePayloadSchema = z.union([
    ReceiveNavigateTeamPayloadSchema,
    ReceiveEditWorkflowPayloadSchema,
    ReceiveNewWorkflowPayloadSchema,
    ReceiveNavigateClientPayloadSchema,
    ReceiveClientWorkflowDetailsPayloadSchema,
    ReceiveClientCustomReportDetailsPayloadSchema,
    ReceiveCustomDashboardDetailsPayloadSchema,
]);
