<template>
    <div class="mt-4">
        <div class="mb-3 content-description center">Add an unintegrated client</div>

        <p class="text-muted text-center">
            Have clients that don't use Xero or QuickBooks? Add them to Precision for free to still manage them as part
            of your practice.
        </p>

        <div class="row justify-content-center mb-3">
            <VButton
                secondary
                class="unintegrated-button"
                name="Add unintegrated client manually"
                @click="$emit('create-client')"
            >
                Add an unintegrated client
            </VButton>
            <p class="mt-3 text-muted">Unintegrated clients do not count towards client limit.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnintegratedClients',
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.d-modal-title {
    color: get-color(orange);
    flex: 1;
    font-family: $typeface-roboto;
    font-size: pxtorem(16);
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin: 0;
}

.unintegrated-button {
    height: 48px;
    width: 274px;
}

.content-description {
    color: #647890;
    font-size: pxtoem(14);
    font-weight: $font-weight-bold;
    letter-spacing: 0.02em;
    line-height: 120%;
    width: 100%;
}

.center {
    align-items: center;
    display: flex;
    justify-content: center;
}
</style>
