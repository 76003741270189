import { inject } from 'vue';

import type { ProductTourContextApi } from './types';

export const ProductTourContext = Symbol('ProductTourContext');

/**
 * Give a component access to the ProductTourContext that is provided by the
 * ProductTourProvider component.
 *
 * @param componentName If for some reason there isn't a ProductTourProvider,
 * this will be used in the error message.
 * @returns
 */
export function useProductTour(componentName: string) {
    const context = inject<ProductTourContextApi>(ProductTourContext);

    if (!context) {
        throw new Error(`<${componentName} /> is missing a parent <ProductTourProvider /> component.`);
    }

    return context;
}
