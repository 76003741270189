import sortBy from 'lodash-es/sortBy';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientBulkImportService from '@/services/Api/ClientBulkImportService';

import * as types from './types';

/* Bulk Import Clients Default State
========================================================================== */
const state = () => {
    return {
        clientIds: [],
        clients: {},
        error: null,
        invalidClientIds: [],
        loading: false,
        removedIds: [],
    };
};

/* Bulk Import Clients Actions
========================================================================== */
const actions = {
    async loadClientList({ commit, state }, payload) {
        const { authEventId } = payload;

        if (!state.loading) {
            commit(types.LOAD_CLIENTS_START);

            try {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const response = await ClientBulkImportService.getBulkImportClientList(practiceCrn, authEventId);

                commit(types.LOAD_CLIENTS_SUCCESS, { clientList: response.data });
            } catch (error) {
                commit(types.LOAD_CLIENTS_FAILURE, { error });
            }
        }
    },

    resetError() {},
};

/* Bulk Import Clients Exports
========================================================================== */
const getters = {
    clientList(state) {
        const clientList = state.clientIds.map((id) => state.clients[id]);

        return sortBy(clientList, 'name');
    },

    error(state) {
        return state.error;
    },

    invalidClientsErrors(state) {
        const errors = state.invalidClientIds.reduce((objectAcc, id) => {
            objectAcc[id] = state.clients[id].errorMessage;

            return objectAcc;
        }, {});

        return errors;
    },
};

/* Bulk Import Clients Mutations
========================================================================== */
const mutations = {
    [types.LOAD_CLIENTS_START](state) {
        state.loading = true;
    },

    [types.LOAD_CLIENTS_FAILURE](state, payload) {
        state.error = payload.error;
        state.loading = false;
    },

    [types.LOAD_CLIENTS_SUCCESS](state, payload) {
        state.loading = false;
        state.clientIds = payload.clientList.map((client) => client.xeroTenantId);

        payload.clientList.forEach(function (client) {
            state.clients[client.xeroTenantId] = client;

            if (client.valid === false && client.errorMessage) {
                state.invalidClientIds.push(client.xeroTenantId);
                state.removedIds.push(client.xeroTenantId);
            }
        });
    },

    [types.REMOVE_CLIENT](state, payload) {
        const removed = [...state.removedIds, payload.xeroTenantId];

        state.removedIds = removed.filter(function (id, index, arr) {
            return arr.indexOf(id) === index;
        });
    },

    [types.RESTORE_CLIENT](state, payload) {
        state.removedIds = state.removedIds.filter(function (id) {
            return id !== payload.xeroTenantId;
        });
    },

    [types.SET_AUTH_EVENT_ID](state, payload) {
        state.authEventId = payload.authEventId;
    },
};

/* Bulk Import Clients Exports
========================================================================== */
export const NAMESPACE = 'clientBulkImport/clients';

export const actionTypes = {
    LOAD_CLIENT_LIST: `${NAMESPACE}/loadClientList`,
};

export const getterTypes = {
    CLIENT_ERROR: `${NAMESPACE}/error`,
    CLIENT_LIST: `${NAMESPACE}/clientList`,
    INVALID_CLIENTS_ERRORS: `${NAMESPACE}/invalidClientsErrors`,
};

export const mutationTypes = {
    [types.REMOVE_CLIENT]: `${NAMESPACE}/${types.REMOVE_CLIENT}`,
    [types.RESTORE_CLIENT]: `${NAMESPACE}/${types.RESTORE_CLIENT}`,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
