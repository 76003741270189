export default function createLocaleManager(config) {
    return {
        countryConfig(countryCode) {
            return config[countryCode] ? config[countryCode] : config['DEFAULT'];
        },

        salesTaxName(countryCode) {
            return this.countryConfig(countryCode).salesTaxName;
        },

        corpTaxName(countryCode) {
            return this.countryConfig(countryCode).corpTaxName;
        },

        payeName(countryCode) {
            return this.countryConfig(countryCode).payeName;
        },

        localiseText(content, countryCode) {
            if (!content) {
                return '';
            }

            const country = this.countryConfig(countryCode);

            const salesTax = /salesTax/g;
            const corpTax = /corpTax/g;
            const paye = /PAYE|Paye|paye/g; // will also normalise the text
            const pension = /Pension/g;
            const turnover = /Turnover/g;

            let newContent = content.replace(salesTax, country.salesTaxName);

            newContent = newContent.replace(corpTax, country.corpTaxName);
            newContent = newContent.replace(paye, country.payeName);
            newContent = newContent.replace(pension, country.pensionName);
            newContent = newContent.replace(turnover, country.turnoverName);

            return newContent;
        },
    };
}
