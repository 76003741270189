import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ImportsService from '@/services/Api/ImportsService';
import { ImportMode } from '@/store/modules/imports/types/Import';
import { ImportsActionTypes, ImportsMutationTypes, ParentStages } from '@/store/modules/imports/types/Store';
import createMeta from '@/store/utils/createMeta';

import type { ClientId } from '@/store/modules/client/types/Client';
import type { Import, ImportStage, ImportPlaceholderData } from '@/store/modules/imports/types/Import';
import type {
    ImportsActionContext,
    ImportsActions,
    ImportsGetters,
    ImportsMutations,
    ImportsStageCompletedData,
    ImportsState,
    ImportStageUpdatedData,
} from '@/store/modules/imports/types/Store';
import type { RootState } from '@/store/types';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

const meta = createMeta();
const initialState = (): ImportsState => {
    return {
        imports: [],
        ...meta.getInitialState(),
    };
};

/**
 * Check if we hold an import by client in current state.
 * If not then we fetch it from our api service.
 * @param clientId
 * @param state
 * @param commit
 */
const preloadImportBClientId = async (
    clientId: ClientId,
    { state, commit }: ImportsActionContext
): Promise<Import | null> => {
    let importEntity = state.imports.find((importItem: Import) => importItem.clientId === clientId);

    if (!importEntity) {
        const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
        const importsData: Array<Import> = await ImportsService.getByClient(practiceCrn, clientId);

        importsData.forEach((importItem: Import) => commit(ImportsMutationTypes.UPSERT_IMPORT, importItem));
        importEntity = state.imports.find((importItem: Import) => importItem.clientId === clientId);
    }

    return importEntity ?? null;
};

const actions: ActionTree<ImportsState, RootState> & ImportsActions = {
    /**
     * Loads all in progress imports for a client
     * @param commit
     * @param state
     * @param clientId
     */
    async [ImportsActionTypes.LOAD_CLIENT_IMPORTS]({ commit }: ImportsActionContext, clientId: string) {
        const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
        const importsData: Array<Import> = await ImportsService.getByClient(practiceCrn, clientId);

        importsData.forEach((importItem: Import) => commit(ImportsMutationTypes.UPSERT_IMPORT, importItem));
    },
    /**
     * Updates import stage, usually with record count.
     * In certain cases the import will be already finished when we receive this call, which we can ignore
     * @param context
     * @param data
     */
    async [ImportsActionTypes.UPDATE_IMPORT_STAGE](context: ImportsActionContext, data: ImportStageUpdatedData) {
        const importEntity = await preloadImportBClientId(data.clientId, context);

        if (importEntity) {
            context.commit(ImportsMutationTypes.UPDATE_IMPORT_STAGE_RECORDS, data);
        }
    },
    /**
     * Update the import, usually to mark it as complete.
     * Usually this results in the associated client without any in progress imports
     * @param context
     * @param data
     */
    async [ImportsActionTypes.COMPLETE_IMPORT_STAGE](context: ImportsActionContext, data: ImportsStageCompletedData) {
        const importEntity = await preloadImportBClientId(data.clientId, context);

        if (importEntity) {
            context.commit(ImportsMutationTypes.COMPLETE_IMPORT_STAGE, data);
        }
    },
    /**
     * Upsert import to state
     * @param context
     * @param importData
     */
    [ImportsActionTypes.UPSERT_IMPORT](context: ImportsActionContext, importData: Import) {
        context.commit(ImportsMutationTypes.UPSERT_IMPORT, importData);
    },
    /**
     * Allows to create an import for a client to mark the client as importing.
     * Useful when we initiate an import and are waiting for a WS callback
     * @param context
     * @param data
     */
    [ImportsActionTypes.CREATE_IMPORT_PLACEHOLDER](context: ImportsActionContext, data: ImportPlaceholderData) {
        const importData: Import = {
            id: 0,
            clientId: data.clientId,
            completedAt: null,
            stage: data.stage,
            mode: data.mode,
            flowId: null,
            progress: [
                {
                    code: 'placeholder',
                    name: 'placeholder',
                    finished: false,
                    finishedAt: null,
                    successful: false,
                    type: 'import',
                    recordCount: 0,
                },
            ],
        };

        context.commit(ImportsMutationTypes.UPSERT_IMPORT, importData);
    },
};

const mutations: MutationTree<ImportsState> & ImportsMutations = {
    /**
     * Will set record count on specific stage for an import
     * @param state
     * @param data
     */
    [ImportsMutationTypes.UPDATE_IMPORT_STAGE_RECORDS](state: ImportsState, data: ImportStageUpdatedData): void {
        const importIndex = state.imports.findIndex((importItem: Import) => importItem.clientId === data.clientId);
        const importEntity = state.imports[importIndex];

        if (!importEntity) {
            return;
        }

        importEntity.progress = importEntity.progress.map((stage: ImportStage) => {
            if (stage.code === data.stage) {
                stage.recordCount = data.recordCount;
            }

            return stage;
        });
        Vue.set(state.imports, importIndex, importEntity);
    },
    /**
     * Will set finished to true on specific stage for an import
     * @param state
     * @param data
     */
    [ImportsMutationTypes.COMPLETE_IMPORT_STAGE](state: ImportsState, data: ImportsStageCompletedData): void {
        const importIndex = state.imports.findIndex((importItem: Import) => importItem.clientId === data.clientId);
        const importEntity = state.imports[importIndex];

        if (!importEntity) {
            return;
        }

        importEntity.progress = importEntity.progress.map((stage: ImportStage) => {
            if (stage.code === data.stage) {
                stage.finished = true;
            }

            return stage;
        });
        Vue.set(state.imports, importIndex, importEntity);
    },
    /**
     * Replaces or create an import
     * @param state
     * @param importData
     */
    [ImportsMutationTypes.UPSERT_IMPORT](state: ImportsState, importData: Import): void {
        const importIndex: number = state.imports.findIndex((item: Import) => {
            return item.clientId === importData.clientId;
        });

        if (importIndex !== -1) {
            Vue.set(state.imports, importIndex, importData);
        } else {
            Vue.set(state.imports, state.imports.length, importData);
        }
    },
};

const getters: GetterTree<ImportsState, RootState> & ImportsGetters = {
    /**
     * Filters to imports which are only in progress since completed ones are no longer relevant
     * @param state
     */
    inProgressImports(state: ImportsState): Array<Import> {
        return state.imports.filter((item: Import) => item.completedAt === null);
    },
    /**
     * Checks if client has any in progress imports
     * @param _state
     * @param getters
     */
    isClientImporting(
        _state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, mode?: string | ImportMode, stage?: string) => boolean {
        return (clientId: string, mode?: string | ImportMode, stage?: string) => {
            const inProgressImports = getters.inProgressImports.filter((item: Import) => {
                return item.clientId === clientId;
            });

            if (!mode) {
                return !!inProgressImports.length;
            }

            const modeImport = inProgressImports.find((item: Import) => item.mode === mode);

            if (!modeImport) {
                return false;
            }

            if (!stage) {
                return true;
            }

            const parentStages = Object.values(ParentStages) as Array<string>;

            return (
                parentStages.includes(stage) ||
                modeImport.progress.some((importStage: ImportStage) => {
                    return importStage.code === stage && !importStage.finished;
                })
            );
        };
    },
    /**
     * Retrieves all imports that are in progress by the current client.
     * Almost all cases this should not return more than one.
     * @param _state
     * @param getters
     */
    getImportsByClientId(_state: ImportsState, getters: ImportsGetters): (clientId: string) => Array<Import> {
        return (clientId: string) =>
            getters.inProgressImports.filter((item: Import) => {
                return item.clientId === clientId;
            });
    },
    /**
     * Find an in progress import by client and mode and workflow
     * @param _state
     * @param getters
     */
    findImportByClientIdAndFlowId(
        _state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, flowId: number) => Import | undefined {
        return (clientId: string, flowId: number) =>
            getters.inProgressImports.find((item: Import) => {
                return item.clientId === clientId && item.mode === ImportMode.flow && item.flowId === flowId;
            });
    },
    /**
     * Finds an in progress import client and mode
     * @param _state
     * @param getters
     */
    findImportByClientIdAndMode(
        _state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, mode: string | ImportMode) => Import | undefined {
        return (clientId: string, mode: string | ImportMode) =>
            getters.inProgressImports.find((item: Import) => {
                return item.clientId === clientId && item.mode === mode;
            });
    },
    /**
     * Calculates progress of import by client
     * @param _state
     * @param getters
     */
    getImportProgressByClientIdAndModeInPercentage(
        _state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, mode?: string | ImportMode) => number {
        return (clientId: string, mode?: string | ImportMode): number => {
            const importItem = getters.inProgressImports.find(
                (item: Import) => item.clientId === clientId && (mode ? item.mode === mode : true)
            );

            if (!importItem) {
                return 0;
            }

            const completedStages = importItem.progress.filter((item: ImportStage) => item.finished);

            return (completedStages.length / importItem.progress.length) * 100;
        };
    },
};

const importsStore: Module<ImportsState, RootState> = {
    namespaced: true,
    state: initialState,
    actions,
    getters,
    mutations,
};

export default importsStore;
