<template>
    <Modal v-model="isVisible" name="Welcome modal" size="large">
        <ModalHeader>Welcome to Dext Precision</ModalHeader>
        <ModalContent>
            <div>
                <img alt="Welcome to Dext Precision" class="welcome-to-dext" src="/img/welcome-to-dext-precision.svg" />
            </div>

            <div class="modal-content-onboarding">
                <p>We're thrilled to join you on your journey towards better data quality and automation.</p>
                <p>Let us show you how you can use Precision to make monthly bookkeeping quicker and more efficient.</p>
                <p>
                    Not got time right now? You can access the tutorial anytime from the Get Started page on the menu.
                </p>
            </div>
        </ModalContent>

        <ModalFooter>
            <template #right>
                <DButton name="welcome-modal-close" secondary @click="hide">Not right now</DButton>
                <DButton name="welcome-modal-start-tutorial" primary @click="$emit('startTutorial')">
                    Start Tutorial
                </DButton>
            </template>
        </ModalFooter>
    </Modal>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { DButton } from '@/components/buttons';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@/components/Modal';

type Emits = {
    (event: 'startTutorial'): void;
};

const emit = defineEmits<Emits>();

type Props = {
    value: boolean;
};

const props = defineProps<Props>();

const isVisible = computed({
    get() {
        return props.value;
    },
    set(newValue: boolean) {
        emit('input', newValue);
    },
});

const hide = () => {
    isVisible.value = false;
};
</script>

<style scoped>
.modal-content-onboarding {
    margin-top: 30px;
    line-height: 30px;
}
.welcome-to-dext {
    width: 100%;
}
</style>
