<template>
    <ul class="breadcrumbs-list d-mb-3">
        <template v-for="(item, index) in breadcrumbs">
            <slot name="item" v-bind="{ item, index }">
                <BreadcrumbsListItem :key="item.id || index" :data="item" :index="index" />
            </slot>

            <slot v-if="index < breadcrumbs.length - 1" name="separator" v-bind="{ item, index }">
                <DIcon class="breadcrumbs-separator" decorative name="chevron-right" size="12px" variant="thick" />
            </slot>
        </template>
    </ul>
</template>

<script setup lang="ts">
import { DIcon } from '@/components/DextUi';

import type { BreadcrumbListItem } from './BreadcrumbsList.types';

import BreadcrumbsListItem from './BreadcrumbsListItem.vue';

type Props = {
    breadcrumbs: BreadcrumbListItem[];
};

defineProps<Props>();
</script>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({ name: 'BreadcrumbsList' });
/* eslint-enable */
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.breadcrumbs-list {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadcrumbs-separator {
    margin: 0 pxtorem(8px);
}
</style>
