import { createNamespacedHelpers } from 'vuex-composition-helpers';

import type Feature from '@/enums/Feature';
import store from '@/store';
import type { FeatureStore, FeatureGetters } from '../types/FeaturesStore';

const { useGetters } = createNamespacedHelpers<FeatureStore, FeatureGetters>(store, 'features');

/**
 * Helper method to reduce boilerplate code when accessing feature availability.
 * Useful if you aren't already accessing the store in your component.
 *
 * @param feature
 * @returns Boolean
 */
export default function useIsFeatureEnabled(feature: Feature | Feature[]): boolean {
    const { isFeatureEnabled } = useGetters(['isFeatureEnabled']);

    return isFeatureEnabled.value(feature);
}
