<template>
    <div v-if="$xavier.hasPermission('canAddNewClient') && !currentTeam.isBase" class="create-client-button-container">
        <VButton v-bind="$attrs" class="create" name="Add Client" @click="onClick">
            <slot>Add Client</slot>

            <template v-for="(index, name) in $slots" #[name]>
                <slot :name="name" />
            </template>

            <template v-for="(index, name) in $scopedSlots" #[name]="data">
                <slot v-bind="data" :name="name" />
            </template>
        </VButton>

        <DBadge
            v-tooltip="tooltipMessage"
            class="client-counter-badge"
            name="Client Limit Approaching"
            variant="error"
            @click="clientCountClicked"
        >
            {{ teamClientCount }}/{{ currentTeam.clientLimit }}
        </DBadge>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useTeamContext } from '@/App/composables/useTeamContext';
import { DBadge } from '@/components/Badge';
import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { useCreateClient } from '@/providers/CreateClientContext';
import useCurrentTeam from '@/store/hooks/useCurrentTeam';
import { isFeatureEnabled } from '@/utils/features';

const RATIOS = {
    APPROACHING: 'APPROACHING_LIMIT',
    REACHED: 'REACHED_NOT_EXCEEDED',
    EXCEEDED: 'EXCEEDED',
};

export default defineComponent({
    name: 'CreateClientButton',

    components: {
        DBadge,
    },

    setup() {
        const currentTeam = useCurrentTeam();
        const xavierGlobals = useXavierGlobals();
        const { clientLimitModalVisible, hideConnectClientModal, showConnectClientModal, createIntegratedClient } =
            useCreateClient('CreateClientButton');

        const { linkToPrepare } = useTeamContext('CreateClientButton');

        return {
            clientLimitModalVisible,
            createIntegratedClient,
            currentTeam,
            hideConnectClientModal,
            linkToPrepare,
            showConnectClientModal,
            teamClientCount: xavierGlobals.teamClientCount,
            xavierGlobals,
        };
    },

    computed: {
        clientRatio() {
            return this.currentTeam.clientCount / this.currentTeam.clientLimit;
        },

        clientCountStatus() {
            if (this.clientRatio >= 0.8 && this.clientRatio < 1) {
                return RATIOS.APPROACHING;
            }

            if (this.clientRatio === 1) {
                return RATIOS.REACHED;
            }

            if (this.clientRatio > 1) {
                return RATIOS.EXCEEDED;
            }

            return '';
        },

        remainingClientSlots() {
            return this.currentTeam.clientLimit - this.currentTeam.clientCount;
        },

        showClientCounter() {
            if (this.currentTeam.clientLimit === 0) {
                return false;
            }

            return true;
        },

        ownerOrAdminTooltipAppendedMessage() {
            if (this.currentTeam.userRole === 'owner' || this.currentTeam.userRole === 'admin') {
                if (this.clientCountStatus === RATIOS.APPROACHING) {
                    return ' Visit Manage Team > Subscription to see your subscription.';
                }

                if (this.clientCountStatus === RATIOS.REACHED || this.clientCountStatus === RATIOS.EXCEEDED) {
                    return ' Visit Manage Team > Subscription to upgrade your client bundle';
                }

                return '';
            }

            return '';
        },

        tooltipMessage() {
            if (this.currentTeam.subscriptionStatus === 'TRIAL') {
                if (this.clientCountStatus === RATIOS.APPROACHING) {
                    return "You've almost reached the maximum number of clients in your free trial period. Click here to add a new client.";
                }

                return 'Client numbers are limited in the trial phase until payment details are added.';
            }

            if (this.clientCountStatus === RATIOS.APPROACHING) {
                if (this.remainingClientSlots === 1) {
                    return `${this.remainingClientSlots} client remaining.` + this.ownerOrAdminTooltipAppendedMessage;
                }

                return `${this.remainingClientSlots} clients remaining.` + this.ownerOrAdminTooltipAppendedMessage;
            }

            if (this.clientCountStatus === RATIOS.REACHED) {
                return 'No clients remaining.' + this.ownerOrAdminTooltipAppendedMessage;
            }

            if (this.clientCountStatus === RATIOS.EXCEEDED) {
                if (this.remainingClientSlots === -1) {
                    return (
                        `${this.remainingClientSlots * -1} client over your limit.` +
                        this.ownerOrAdminTooltipAppendedMessage
                    );
                }

                return (
                    `${this.remainingClientSlots * -1} clients over your limit.` +
                    this.ownerOrAdminTooltipAppendedMessage
                );
            }

            return '';
        },
    },

    methods: {
        onClick() {
            this.$emit('click');

            const unintegratedProviderEnabled = isFeatureEnabled(Feature.UNINTEGRATED);

            if (this.xavierGlobals.isQuickbooksEnabled() || unintegratedProviderEnabled) {
                this.showConnectClientModal();
            } else {
                this.hideConnectClientModal();
                this.createIntegratedClient({ provider: ClientProvider.XERO });
            }
        },

        clientCountClicked() {
            if (this.clientCountStatus === RATIOS.EXCEEDED || this.clientCountStatus === RATIOS.REACHED) {
                window.location.href = this.linkToPrepare('subscription');
            } else if (this.clientCountStatus === RATIOS.APPROACHING) {
                this.clientLimitModalVisible = true;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.create-client-button-container {
    align-items: center;
    display: flex;
}

.client-counter-badge {
    margin-left: 4px;
}
</style>
