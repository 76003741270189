<template>
    <div v-if="!isEmbedded && optInStatus.status !== 'show-precision'" class="unification-banner d-flex">
        <div class="unification-banner-content d-flex d-flex-align-center">
            <template v-if="optInStatus.status === 'show-opt-in'">
                Try the more modern, simple and intuitive Dext
                <PButton
                    class="d-ml-2"
                    :href="optInStatus.redirectUrl"
                    :name="'Try the new Dext'"
                    qa-name="unification-banner-lets-go"
                    :variant="'transparent'"
                >
                    Lets Go
                </PButton>
            </template>
            <template v-else>
                Back to the new Dext experience?
                <PButton
                    class="d-ml-2"
                    :href="optInStatus.redirectUrl"
                    :name="'Back to the new dext'"
                    qa-name="unification-banner-go-back"
                    :variant="'transparent'"
                >
                    Lets Go
                </PButton>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useAppContext } from '@/App/composables/useAppContext';
import { PButton } from '@/components/PButton';

const { isEmbedded, optInStatus } = useAppContext();
</script>
<style lang="css" scoped>
.unification-banner {
    background-color: var(--d-color-blue-dark);
    color: var(--d-color-white);
    font-size: var(--d-font-size-medium);
    padding: 10px 0;
    place-content: center center;
    vertical-align: center;
    width: 100%;
}

.unification-banner-content {
    place-content: center space-between;
}

.unification-banner-content .dext {
    color: var(--d-color-orange);
    margin-bottom: -1px;
}

.unification-banner-content .dext::before,
.unification-banner-content .dext::after {
    content: '\00a0';
}

.unification-banner-content a.d-button {
    border: 1px solid var(--d-color-white);
    border-radius: 15px;
    color: var(--d-color-white);
}

.unification-banner-content a.d-button:active,
.unification-banner-content a.d-button:visited {
    color: var(--d-color-white);
}

.unification-banner-content a.d-button:hover {
    color: var(--d-color-blue-dark);
}
</style>
<script lang="ts">
export default {
    name: 'UnificationBanner',
};
</script>
