import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '@/store';

import type { TeamStore, TeamGetters } from '../modules/teams/types/TeamsStore';

export default function useCurrentTeam() {
    const { useGetters } = createNamespacedHelpers<TeamStore, TeamGetters>(store, 'teams');
    const { currentTeam } = useGetters(['currentTeam']);

    return currentTeam;
}
