import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { createLogger } from 'vuex';

import { isDevelopment } from '@/utils/env';
import createLocaleManager from '@/utils/locale';

import accountsModule from './modules/accounts';
import adminReportsModule from './modules/adminReports';
import clientsModule, { ClientsMutationTypes, NAMESPACE as clientsNamespace } from './modules/clients/clients.store';
import legacyClientsModule from './modules/clients_LEGACY';
import clientBulkImportModule from './modules/clientBulkImport';
import featuresModule from './modules/feature/feature.store';
import flowsModule from './modules/flows';
import flowsTemplatesModule from './modules/flowTemplates';
import importsModule from './modules/imports';
import invitationsModule from './modules/invitations';
import quickbooksImportModule from './modules/quickbooksImport';
import reportsModule from './modules/reports';
import sandboxModule from './modules/sandbox';
import sessionModule from './modules/session';
import settingsModule from './modules/settings';
import subscriptionsModule from './modules/subscriptions';
import taxRatesModule from './modules/taxRates';
import teamsModule from './modules/teams';
import usersModule from './modules/users';
import vatModule from './modules/vat';
import apiSettingsModule from './modules/apiSettings';
import insightsDashboardModule from './modules/insightsDashboard';
import unintegratedClientsModule from './modules/unintegratedClients';

import registerDynamicModules, { dynamicClientModule } from './plugins/registerDynamicModules';

Vue.use(Vuex);

const localeManager = createLocaleManager(window.Xavier.countries);

const plugins = [
    createPersistedState({
        key: 'xavier-settings',
        paths: ['settings'],
    }),
    createPersistedState({
        key: 'xavier-settings',
        paths: ['session'],
        storage: window.sessionStorage,
    }),

    registerDynamicModules([
        {
            mutationType: `${clientsNamespace}/${ClientsMutationTypes.LOAD_CLIENTS_SUCCESS}`,
            handler: dynamicClientModule,
        },
    ]),
];

isDevelopment() && plugins.push(createLogger());

export default new Vuex.Store({
    strict: isDevelopment(),
    plugins,
    modules: {
        accounts: accountsModule,
        adminReports: adminReportsModule,
        clientBulkImport: clientBulkImportModule,
        clients: clientsModule,
        legacyClients: legacyClientsModule(localeManager),
        features: featuresModule,
        flows: flowsModule,
        flowTemplates: flowsTemplatesModule,
        imports: importsModule,
        invitations: invitationsModule,
        quickbooksImport: quickbooksImportModule,
        reports: reportsModule,
        sandbox: sandboxModule,
        session: sessionModule,
        settings: settingsModule,
        subscriptions: subscriptionsModule,
        taxRates: taxRatesModule,
        teams: teamsModule,
        users: usersModule,
        vat: vatModule,
        apiSettings: apiSettingsModule,
        insightsDashboard: insightsDashboardModule,
        unintegratedClients: unintegratedClientsModule,
    },
});
