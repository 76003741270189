import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import FlowService from '@/services/Api/FlowService';

import * as types from './types';

export default {
    namespaced: true,
    state() {
        return {
            currentFlowId: undefined,
            currentFlowSlug: undefined,
            currentDraftFlowSlug: undefined,
            flows: {},
            currentDraftFlow: undefined,
            draftFlows: {},
            repeatingFlows: {},
            checks: {},
            loading: false,
            flowListLoaded: false,
            draftFlowListLoaded: false,
            repeatingFlowListLoaded: false,
            flowStatusCounts: { NOT_STARTED: 0, IN_PROGRESS: 0, UNDER_REVIEW: 0, COMPLETED: 0 },
            completedFlowListLoaded: false,
        };
    },
    getters: {
        getFlowById(state) {
            return function (flowId) {
                return state.flows[flowId];
            };
        },
        loading: (state) => state.loading,
        flowListLoaded: (state) => state.flowListLoaded,
        completedFlowListLoaded: (state) => state.completedFlowListLoaded,
        currentFlow: (state) => state.flows[state.currentFlowId],
        currentDraftFlow: (state) => state.currentDraftFlow,
        draftFlowList: (state) => Object.values(state.draftFlows),
        flowList: (state) => Object.values(state.flows),
        repeatingFlowList: (state) => Object.values(state.repeatingFlows),
        checkList: (state) => Object.values(state.checks),
        allFlowsCount: (state) =>
            state.flowStatusCounts.NOT_STARTED +
            state.flowStatusCounts.IN_PROGRESS +
            state.flowStatusCounts.UNDER_REVIEW +
            state.flowStatusCounts.COMPLETED,
        myFlowsCount: (state, getters) =>
            getters.flowList.filter((f) => f.userId === window.Xavier.userId || f.secondUserId === window.Xavier.userId)
                .length,
        myReviewsCount: (state, getters) =>
            getters.flowList.filter((f) => f.reviewerId === window.Xavier.userId).length,
        filteredFlowList: (state, getters) => (viewFilter, searchFilter) => {
            let filtered;

            if (viewFilter === 'myFlows') {
                filtered = getters.flowList.filter(
                    (f) => f.userId === window.Xavier.userId || f.secondUserId === window.Xavier.userId
                );
            } else {
                filtered = getters.flowList;
            }

            return searchFilter
                ? filtered.filter((f) => f.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1)
                : filtered;
        },
        flowStatusCountTotals: (state) => state.flowStatusCounts,
    },
    actions: {
        async loadFlowList({ commit }) {
            commit(types.LOADING_STARTED);

            const { currentTeam } = window.Xavier;
            const { data } = await FlowService.getAllFlows(currentTeam.rbExternalId, {
                statuses: ['In Progress', 'Under Review', 'Not Started'],
            });

            commit(types.FLOW_LIST_LOADED, data);
        },
        async loadCompletedFlowList({ commit }) {
            commit(types.LOADING_STARTED);

            const { currentTeam } = window.Xavier;
            const { data } = await FlowService.getAllFlows(currentTeam.rbExternalId, {
                statuses: ['Complete'],
            });

            commit(types.COMPLETED_FLOW_LIST_LOADED, data);
        },
        async loadFlowStatusCounts({ commit }, clientId) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const data = await FlowService.getFlowStatuses(practiceCrn, clientId);

            commit(types.FLOW_STATUS_COUNTS_LOADED, data);

            return data;
        },
        async loadChecks({ commit }) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data } = await FlowService.loadChecks(practiceCrn);

            commit(types.FLOW_CHECKS_LOADED, data);
        },
        async loadFlow({ commit }, slug) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const data = await FlowService.loadFlow(practiceCrn, slug);

            commit(types.FLOW_LOADED, data.data);

            return data;
        },
        async loadCurrentDraftFlow({ commit }, slug) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.loadDraftFlow(practiceCrn, slug);

            commit(types.LOAD_CURRENT_DRAFT_FLOW, data);

            return data;
        },
        async loadFlowChecks({ commit }, id) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data } = await FlowService.loadChecksForFlow(practiceCrn, id);

            return data;
        },
        async loadCheckStats({ commit, getters }, insight) {
            try {
                const flow = getters.currentFlow;

                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                const { data } = await axios.get(
                    `/api/teams/${practiceCrn}/client/${flow.clientId}/insight/${insight}/stats/${flow.id}`
                );

                return data;
            } catch (ex) {
                console.error(ex);
                this.$toaster.error('Failed to load check stats. Please try refreshing the page.');
            }
        },
        async loadCheckResults({ commit, getters }, insight) {
            try {
                const flow = getters.currentFlow;

                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                const { data } = await axios.get(
                    `/api/teams/${practiceCrn}/client/${flow.clientId}/insight/${insight}/flow/${flow.id}`
                );

                return data;
            } catch (ex) {
                console.error(ex);
            }
        },
        async createDuplicate({ dispatch, commit, state }, id) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data } = await FlowService.duplicateFlow(practiceCrn, id);

            return data;
        },
        async loadDraftFlows({ commit }) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.loadDraftFlows(practiceCrn);

            commit(types.DRAFT_FLOW_LIST_LOADED, data);
        },
        async loadRepeatingFlows({ commit }) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data } = await FlowService.loadRepeatingDraftFlows(practiceCrn);

            commit(types.REPEATING_FLOW_LIST_LOADED, data);
        },
        async createDraftFlow({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data } = await FlowService.createDraftFlow(practiceCrn, details);

            return data;
        },
        async updateDraftFlow({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.updateDraftFlow(practiceCrn, details.slug, details.stage, details);

            return data;
        },
        async deleteDraftFlow({ commit, dispatch }, id) {
            commit(types.REMOVE_FLOW, id);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.deleteDraftFlow(practiceCrn, id);

            await dispatch('loadDraftFlows');
            await dispatch('loadRepeatingFlows');
        },
        async createFlow({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.createFlow(practiceCrn, details);

            return data;
        },
        async updateFlow({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const data = await FlowService.updateFlow(practiceCrn, details.slug, details);

            dispatch('loadFlowList');

            return data;
        },
        async selectFlow({ dispatch, commit, state }, slug) {
            if (slug !== state.currentFlowSlug) {
                commit(types.CLEAR_FLOW);
            }

            const { data } = await dispatch('loadFlow', slug);

            commit(types.FLOW_SELECTED, data);
        },
        async clearDraftFlow({ dispatch, commit, state }) {
            commit(types.CLEAR_DRAFT_FLOW);
        },
        async startFlow({ dispatch, commit, state }, id) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.startFlow(practiceCrn, id);
        },
        async completeFlow({ dispatch, commit, state }, id) {
            // await axios.get(`/api/flow/${id}/complete`);
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.completeFlow(practiceCrn, id);

            await dispatch('loadFlowList');
        },
        async completeFlowReview({ dispatch, commit, state }, id) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.completeFlowReview(practiceCrn, id);

            await dispatch('loadFlowList');
        },
        async cancelFlowReview({ dispatch, commit, state }, data) {
            // await axios.post(`/api/flow/${data.id}/cancel-review`, data);
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.cancelFlowReview(practiceCrn, data);

            await dispatch('loadFlowList');
        },
        async deleteFlow({ commit, dispatch }, id) {
            commit(types.REMOVE_FLOW, id);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.deleteFlow(practiceCrn, id);
        },
        async completeCheck({ dispatch, commit, state }, details) {
            // await axios.post(`/api/flow/${details.flowId}/check/${details.checkId}/complete`, details);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.completeCheckForFlow(practiceCrn, details.flowId, details.checkId, details);

            await dispatch('selectFlow', state.currentFlowSlug);
        },
        async incompleteCheck({ dispatch, commit, state }, details) {
            // await axios.get(`/api/flow/${details.flowId}/check/${details.checkId}/incomplete`);
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.incompleteCheckForFlow(practiceCrn, details.flowId, details.checkId);

            await dispatch('selectFlow', state.currentFlowSlug);
        },
        async saveComment({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const data = await FlowService.saveCommentForFlow(practiceCrn, details.id, details.comment);

            details.comments = data;

            commit(types.COMMENTS_UPDATED, details);
        },
        async deleteComment({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const data = await FlowService.deleteCommentForFlow(practiceCrn, details.id, details.comment.id);

            details.comments = data;

            commit(types.COMMENTS_UPDATED, details);
        },
        async uploadAttachment({ dispatch, commit, state }, details) {
            const formData = new FormData();

            formData.append('file', details.file);

            return await axios
                .post(details.url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    details.attachments = response.data;
                    commit(types.FILES_ATTACHED, details);

                    return response;
                })
                .catch((ex) => {
                    // Append the file name to the response so we can display the error message for the file
                    ex.response.fileName = details.file.name;

                    return ex.response;
                });
        },
        async deleteAttachment({ dispatch, commit, state }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            let data = null;

            if (details.file.checkId) {
                data = await FlowService.deleteAttachmentFromFlowCheck(
                    practiceCrn,
                    details.flowSlug,
                    details.file.checkId,
                    details.file.uuid
                );
            } else {
                data = await FlowService.deleteAttachmentFromFlow(practiceCrn, details.flowSlug, details.file.uuid);
            }

            if (data.status === 'success') {
                commit(types.FILES_REMOVED, details);
            }

            return data;
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.SET_CURRENT_DRAFT_FLOW](state, slug) {
            state.currentDraftFlowSlug = slug;
        },
        [types.LOAD_CURRENT_DRAFT_FLOW](state, flow) {
            state.currentDraftFlow = flow;
        },
        [types.FLOW_STATUS_COUNTS_LOADED](state, statusCounts) {
            state.flowStatusCounts = statusCounts;
            state.loading = false;
        },
        [types.DRAFT_FLOW_LIST_LOADED](state, flowList) {
            for (const flow of flowList) {
                Vue.set(state.draftFlows, flow.id, {
                    ...state.draftFlows[flow.id],
                    ...flow,
                });
            }

            for (const id of Object.keys(state.draftFlows)) {
                if (flowList.every((f) => f.id != id)) {
                    Vue.delete(state.draftFlows, id);
                }
            }

            state.loading = false;
            state.draftFlowListLoaded = true;
        },
        [types.FLOW_LIST_LOADED](state, flowList) {
            for (const flow of flowList) {
                Vue.set(state.flows, flow.id, {
                    ...state.flows[flow.id],
                    ...flow,
                });
            }

            for (const id of Object.keys(state.flows)) {
                if (flowList.every((f) => f.id != id)) {
                    Vue.delete(state.flows, id);
                }
            }

            state.loading = false;
            state.flowListLoaded = true;
        },
        [types.REPEATING_FLOW_LIST_LOADED](state, flowList) {
            for (const flow of flowList) {
                Vue.set(state.repeatingFlows, flow.id, {
                    ...state.repeatingFlows[flow.id],
                    ...flow,
                });
            }

            for (const id of Object.keys(state.repeatingFlows)) {
                if (flowList.every((f) => f.id != id)) {
                    Vue.delete(state.repeatingFlows, id);
                }
            }

            state.loading = false;
            state.repeatingFlowListLoaded = true;
        },
        [types.COMPLETED_FLOW_LIST_LOADED](state, flowList) {
            for (const flow of flowList) {
                Vue.set(state.flows, flow.id, {
                    ...state.flows[flow.id],
                    ...flow,
                });
            }

            state.loading = false;
            state.completedFlowListLoaded = true;
        },
        [types.FLOW_CHECKS_LOADED](state, checkList) {
            for (const check of checkList) {
                Vue.set(state.checks, check.id, {
                    ...state.checks[check.id],
                    ...check,
                });
            }

            for (const id of Object.keys(state.checks)) {
                if (checkList.every((c) => c.id != id)) {
                    Vue.delete(state.checks, id);
                }
            }

            state.loading = false;
        },
        [types.FLOW_LOADED](state, flow) {
            Vue.set(state.flows, flow.id, {
                ...state.flows[flow.id],
                ...flow,
            });
        },
        [types.REMOVE_FLOW](state, id) {
            Vue.delete(state.flows, id);
        },
        [types.REMOVE_DRAFT_FLOW](state, id) {
            Vue.delete(state.draftFlows, id);
        },
        [types.FLOW_SELECTED](state, flow) {
            state.currentFlowId = flow.id;
            state.currentFlowSlug = flow.slug;
        },
        [types.CLEAR_FLOW](state) {
            state.currentFlowId = null;
            state.currentFlowSlug = null;
        },
        [types.CLEAR_DRAFT_FLOW](state) {
            state.currentDraftFlowSlug = null;
            state.currentDraftFlow = null;
        },
        [types.COMMENTS_UPDATED](state, details) {
            state.flows[details.id].comments = details.comments;
        },
        [types.FILES_ATTACHED](state, details) {
            Vue.set(state.flows[details.attachments.flowId].attachments, 'data', [
                ...state.flows[details.attachments.flowId].attachments.data,
                details.attachments,
            ]);
        },
        [types.FILES_REMOVED](state, details) {
            Object.keys(state.flows[details.file.flowId].attachments.data).forEach((attachmentKey) => {
                const currentAttachment = state.flows[details.file.flowId].attachments.data[attachmentKey];

                if (currentAttachment?.uuid === details.file.uuid) {
                    return state.flows[details.file.flowId].attachments.data.splice(attachmentKey, 1);
                }
            });
        },
    },
};
