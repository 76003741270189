<template>
    <OptionalWrapper :component="SimplePortal" :condition="detach" :selector="detach ? '#menus-target' : null">
        <Container
            :class="containerClassName"
            initial-pose="enter"
            :pose="visible ? 'enter' : 'exit'"
            :style="contentStyle"
            v-show="visible"
        >
            <div
                v-bind="attrs"
                :class="contentClassName"
                ref="itemsRef"
                tabindex="-1"
                v-on="listeners"
                v-click-outside="onClickOutside"
            >
                <div class="menu-content__prepend" v-if="hasPrependSlot">
                    <slot name="prepend" />
                </div>

                <SimpleBar class="menu__items">
                    <slot />
                </SimpleBar>
            </div>
        </Container>
    </OptionalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import posed from 'vue-pose';
import * as PropTypes from 'vue-types';
import { Portal as SimplePortal } from '@linusborg/vue-simple-portal';
import SimpleBar from 'simplebar-vue';

import ClickOutside from '@/directives/clickOutside';
import OptionalWrapper from '@/frames/OptionalWrapper';
import injectStrict from '@/hooks/injectStrict';
import useHasSlot from '@/hooks/useHasSlot';
import { useUUID } from '@/mixins/uuid';
import { focusElement } from '@/utils/dom';

import useKeydownHandler from '../hooks/useKeydownHandler';
import useMenuItemsStyle from '../hooks/useMenuItemsStyle';
import menuStates from '../states/MenuStates';
import { HorizontalAlign, VerticalAlign, VerticalPosition } from '../states/PositionStates';
import { MenuContextApi } from '../types';

const easeCubicBezier = [0.25, 0.8, 0.5, 1];

const sizeStates = {
    SMALL: 'small',
    MEDIUM: 'medium',
};

export default defineComponent({
    name: 'MenuItems',

    directives: { ClickOutside },

    props: {
        /**
         * Should the `Menu` content be detached. This is useful if a parent
         * element has `overflow: hidden` which would hide all or part of the
         * content. Moves the element into a modal at the root of the app DOM.
         *
         * @param {Boolean} [detach=false]
         */
        detach: PropTypes.bool().def(false),

        /**
         * Configure the dropdown container alignment relative to the activator.
         *
         * @param {Object} [alignment={horizontal,vertical}]
         * @param {String} [alignment.horizontal=left]
         *        Set whether to use the left or right edge of the dropdown for
         *        alignment. This will also configure the side that the
         *        component appears when `inline: true` is set.
         * @param {String} [alignment.vertical=top]
         *        Set whether to use the top or bottom edge of the dropdown for
         *        alignment. Only in use if the `inline: true` is set.
         */
        alignment: PropTypes.shape({
            horizontal: PropTypes.oneOf(Object.values(HorizontalAlign)),
            vertical: PropTypes.oneOf(Object.values(VerticalAlign)),
        }).def({
            horizontal: HorizontalAlign.LEFT,
            vertical: VerticalAlign.TOP,
        }),

        /**
         * Should the component be displayed next to the activator.
         *
         * @param {Boolean} [inline=false]
         */
        inline: PropTypes.bool().def(false),

        /**
         * Set the vertical position of the component. This can be either above
         * or below. Does nothing if `inline: true` is set.
         *
         * @param {String} [verticalPosition=below]
         */
        verticalPosition: PropTypes.oneOf(Object.values(VerticalPosition)).def(VerticalPosition.BELOW),

        /**
         * Set the size of the `Menu` dropdown.
         *
         * @prop {String} [size=small]
         */
        size: PropTypes.oneOf(Object.values(sizeStates)).def(sizeStates.SMALL),
    },

    components: {
        OptionalWrapper,
        SimpleBar,
        Container: posed.div({
            enter: {
                opacity: 1,
                transition: { delay: 10, duration: 200, ease: easeCubicBezier },
            },
            exit: {
                opacity: 0,
                transition: { duration: 150, ease: easeCubicBezier },
            },
        }),
    },

    setup(props) {
        const uuid = useUUID();
        const menuContext = injectStrict<MenuContextApi>('MenuContext');
        const onKeyDown = useKeydownHandler();

        const contentStyle = useMenuItemsStyle({
            alignment: props.alignment,
            detach: props.detach,
            inline: props.inline,
            offset: 4,
            verticalPosition: props.verticalPosition,
        });
        const id = `menu_items_${uuid}`;

        const onClickOutside = (event: MouseEvent) => {
            const activatorContainer = (event.target as HTMLElement)?.closest('.menu__activator');
            const isOpen = menuContext.menuState.value === menuStates.OPEN;
            const isActivator = activatorContainer?.contains(menuContext.activatorRef.value as HTMLElement) ?? false;

            if (!isActivator && isOpen && menuContext.closeOnClick) {
                menuContext.closeMenu();
            }
        };

        const listeners = computed(() => ({ keydown: onKeyDown }));

        const attrs = computed(() => ({
            id,
            'aria-activedescendant':
                menuContext.activeItemIndex.value === -1
                    ? undefined
                    : menuContext.items.value[menuContext.activeItemIndex.value]?.id,
        }));

        watch(menuContext.menuState, (value) => {
            setTimeout(() => {
                value === menuStates.OPEN && focusElement(menuContext.itemsRef.value);
            });
        });

        return {
            attrs,
            onClickOutside,
            containerClassName: {
                'menu-content-container': true,
                'menu-content-container--detached': props.detach,
                'menu-content-container--inline': props.inline,
                [`menu-content-container--align-${props.alignment.horizontal}`]: Boolean(props.alignment.horizontal),
                [`menu-content-container--align-${props.alignment.vertical}`]:
                    props.inline && Boolean(props.alignment.vertical),
                [`menu-content-container--position-${props.verticalPosition}`]:
                    !props.inline && Boolean(props.verticalPosition),
            },
            contentClassName: {
                'menu-content': true,
                [`menu-content--${props.size}`]: Boolean(props.size),
            },
            contentStyle,
            hasPrependSlot: useHasSlot('prepend'),
            itemsRef: menuContext.itemsRef,
            listeners,
            visible: computed(() => menuContext.menuState.value === menuStates.OPEN),
            SimplePortal,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

/**
 * @link https://www.figma.com/file/vTXWsBul1AgAoWAhVRn91a/Onboarding-beyond-submitting?node-id=1725%3A61714&viewport=293%2C48%2C1.08
 *
 * 1. Magic number derived by the content max-width for medium of 312px
 *    (defined in the Figma file), plus the padding of 16px on either side, and
 *    the border width too.
 *
 * 2. This number was lifted straight from the Dext website.
*/

/* Menu Content Container
========================================================================== */
.menu-content-container {
    position: absolute;
    z-index: 10;
}

.menu-content-container--position-above {
    bottom: 100%;
    margin-bottom: 4px;
}

.menu-content-container--position-above.menu-content-container--detached {
    bottom: auto;
    margin-bottom: auto;
}

.menu-content-container--position-below {
    margin-top: 4px;
    top: 100%;
}

.menu-content-container--position-below.menu-content-container--detached {
    top: auto;
}

.menu-content-container--align-left {
    left: 0;

    &.menu-content-container--inline {
        left: 100%;
        margin-left: 4px;
    }
}

.menu-content-container--align-left.menu-content-container--detached {
    left: auto;
}

.menu-content-container--align-right {
    right: 0;

    &.menu-content-container--inline {
        margin-right: 4px;
        right: 100%;
    }
}

.menu-content-container--align-right.menu-content-container--detached {
    right: auto;

    &.menu-content-container--inline {
        margin-right: auto;
    }
}

.menu-content-container--align-bottom {
    bottom: 0;

    &.menu-content-container--detached {
        bottom: auto;
    }
}

/* Menu Content
========================================================================== */
.menu-content {
    @include shadow($size: medium);

    background-color: $color-white;
    border: 1px solid get-color(silver);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    outline: 0;
    overflow: hidden;
}

.menu-content--small {
    width: 280px;
}

.menu-content--medium {
    width: 346px; /* [1] */
}

.menu-content__prepend {
    .menu-content--small & {
        padding-bottom: 8px;
    }
}

/* Menu Items
========================================================================== */
.menu__items {
    flex: 1;
    overflow-y: auto;

    .menu-content--small & {
        max-height: 290px;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .menu-content--medium & {
        max-height: 1072px; /* [2] */
        padding-bottom: 24px;
        padding-top: 24px;
    }
}
</style>
