/**
 * This renderless component creates a declarative handler for Promises, giving
 * a consistent api. This allows the developer to not have to think about
 * managing error, loading and success states.
 */
export default {
    name: 'FramePromise',

    props: {
        promise: {
            default: null,
            required: false,
            type: Promise,
        },
    },

    data() {
        return {
            result: null,
            error: null,
            pending: false,
            resolved: null,
        };
    },

    methods: {
        reset() {
            this.setStatus({ result: null, error: null, pending: false, resolved: null });
        },

        setStatus({ result = this.result, error = null, pending = false, resolved = null }) {
            this.result = result;
            this.error = error;
            this.pending = pending;
            this.resolved = resolved;
        },
    },

    watch: {
        /**
         * Watch for changes to the `promise` prop, and re-run the handler. The
         * bulk of this watcher is setting the status.
         */
        promise: {
            immediate: true,
            async handler() {
                if (!this.promise) return;

                try {
                    this.setStatus({ pending: true });

                    const result = await this.promise;

                    this.setStatus({
                        result,
                        resolved: true,
                    });
                } catch (error) {
                    this.setStatus({
                        error,
                        result: null,
                        pending: false,
                    });
                }
            },
        },
    },

    /**
     * Return a scoped slot with information about the Promise.
     *
     * @returns any
     */
    render() {
        return this.$scopedSlots.default({
            methods: { reset: this.reset },
            result: this.result,
            status: {
                error: this.error,
                pending: this.pending,
                resolved: this.resolved,
            },
        });
    },
};
