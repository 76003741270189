const keycodeMap = {
    ARROW_DOWN: {
        code: 'ArrowDown',
        which: 40,
    },
    ARROW_LEFT: {
        code: 'ArrowLeft',
        which: 37,
    },
    ARROW_RIGHT: {
        code: 'ArrowRight',
        which: 39,
    },
    ARROW_UP: {
        code: 'ArrowUp',
        which: 38,
    },
    END: {
        code: 'End',
        which: 35,
    },
    ENTER: {
        code: 'Enter',
        which: 13,
    },
    ESCAPE: {
        code: 'Escape',
        which: 27,
    },
    HOME: {
        code: 'Home',
        which: 36,
    },
    TAB: {
        code: 'Tab',
        which: 9,
    },
};

export default keycodeMap;
