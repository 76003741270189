const namespace = 'components.sync_button.';

const translations = {
    invalidParams: `${namespace}invalid_params`,
    lastSync: `${namespace}last_sync`,
    recalculate: `${namespace}recalculate`,
    recalculating: `${namespace}recalculating`,
    reconnect: `${namespace}reconnect`,
    syncClient: `${namespace}sync_client`,
    syncCompleted: `${namespace}sync_completed`,
};

export { translations };
