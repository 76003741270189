import FramePromise from './Promise';

/**
 * This renderless component creates a declartive way of accessing the API. This
 * allows the developer to not have to worry about handling the response and
 * remembering the correct syntax for try/catch/finally, and managing error
 * and loading states internally in every component that makes an API call.
 */
export default {
    name: 'FrameApi',

    props: {
        endpoint: {
            required: true,
            type: Function,
        },
        immediate: {
            default: false,
            required: false,
            type: Boolean,
        },
    },

    data() {
        return {
            response: null,
        };
    },

    methods: {
        /**
         * Call the API endpoint and emit any required events for the parent
         * component to listen for.
         *
         * @param  {...any} params Any parameters to call the API endpoint with
         */
        async query(...params) {
            this.response = this.endpoint(...params);

            try {
                await this.response;
                this.$emit('success');
            } catch (error) {
                this.$emit('error', error);
            }
        },
    },

    created() {
        this.immediate && this.query();
    },

    /**
     * "Render" the component. In this instance, this is a renderless component,
     * which means that we don't render any HTML or any other components.
     *
     * Although in this instance we do call the `createElement` method, that is
     * also a renderless component. The entire process here is to return to the
     * child component the returned data, the status of their request and any
     * methods that may be useful.
     *
     * @param {CreateElement} createElement JSX render method, automatically
     *     passed in by Vue.
     * @returns VNode
     */
    render(createElement) {
        return createElement(FramePromise, {
            props: { promise: this.response },
            scopedSlots: {
                default: (props) => {
                    return this.$scopedSlots.default({
                        response: props.result,
                        methods: { query: this.query, reset: props.methods.reset },
                        status: {
                            error: props.status.error,
                            loading: props.status.pending,
                            success: props.status.resolved,
                        },
                    });
                },
            },
        });
    },
};
