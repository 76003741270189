<template>
    <Modal v-model="isVisible" :name="props.name" :persistent="true">
        <template #activator="activatorProps">
            <slot name="activator" v-bind="activatorProps" />
        </template>

        <ModalHeader>
            <slot name="title">{{ translator.t(t.title) }}</slot>
        </ModalHeader>

        <ModalContent>
            <slot />
        </ModalContent>

        <ModalFooter>
            <template #right>
                <slot name="actions">
                    <PButton
                        :disabled="loading"
                        :qa-name="`${props.name} cancel`"
                        variant="transparent"
                        @click="onCancelClick"
                    >
                        {{ cancelButtonText }}
                    </PButton>

                    <PButton
                        :loading="loading"
                        :qa-name="`${props.name} confirm`"
                        :variant="props.danger ? 'dangerous' : 'primary'"
                        @click="onConfirmClick"
                    >
                        {{ confirmButtonText }}
                    </PButton>
                </slot>
            </template>
        </ModalFooter>
    </Modal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

import { useAppContext } from '@/App/composables/useAppContext';
import { PButton } from '@/components/PButton';
import { useVueI18n } from '@/composables/useVueI18n';
import { PrepareSendMessageEvents } from '@/enums/prepare/IframeMessageEvents';

import { translations as t } from './ConfirmationModal.translations';

import { Modal, ModalContent, ModalFooter, ModalHeader } from '../Modal';

const translator = useVueI18n();

const { dispatchIframeEvent } = useAppContext();

type Props = {
    cancelButtonText?: string;
    confirmButtonText?: string;
    danger?: boolean;
    loading?: boolean;
    name: string;
    value?: boolean;
};

type Emits = {
    (event: 'cancel'): void;
    (event: 'confirm'): void;
    (event: 'input', value: boolean): void;
};

const props = withDefaults(defineProps<Props>(), {
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    danger: false,
    loading: false,
    value: false,
});

const emit = defineEmits<Emits>();
const isVisible = ref(props.value);

watch(
    () => props.value,
    (value) => {
        isVisible.value = value;
    }
);

watch(isVisible, (value) => {
    emit('input', value);
});

function onConfirmClick() {
    isVisible.value = false;

    // This fixes the prepare iframe overlay not hiding when the confirmation modal is closed
    dispatchIframeEvent({ action: PrepareSendMessageEvents.HIDE_OVERLAY });

    emit('confirm');
}

function onCancelClick() {
    isVisible.value = false;

    // This fixes the prepare iframe overlay not hiding when the confirmation modal is closed
    dispatchIframeEvent({ action: PrepareSendMessageEvents.HIDE_OVERLAY });

    emit('cancel');
}
</script>

<script lang="ts">
export default { name: 'ConfirmationModal' };
</script>
