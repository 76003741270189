import type { FetchTeamsListResponseSchema } from '@/schemas/Team.schema';

import BaseApiService from './BaseApiService';

class MembershipService extends BaseApiService {
    getMemberships(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.get-memberships', routeParams);
    }
}

export default Object.freeze(new MembershipService());
