export const LOADING_STARTED = 'LOADING_STARTED';
export const FLOW_LIST_LOADED = 'FLOW_LIST_LOADED';
export const COMPLETED_FLOW_LIST_LOADED = 'COMPLETED_FLOW_LIST_LOADED';
export const FLOW_STATUS_COUNTS_LOADED = 'FLOW_STATUS_COUNTS_LOADED';
export const DRAFT_FLOW_LIST_LOADED = 'DRAFT_FLOW_LIST_LOADED';
export const REPEATING_FLOW_LIST_LOADED = 'REPEATING_FLOW_LIST_LOADED';
export const SET_CURRENT_DRAFT_FLOW = 'SET_CURRENT_DRAFT_FLOW';
export const LOAD_CURRENT_DRAFT_FLOW = 'LOAD_CURRENT_DRAFT_FLOW';
export const DRAFT_CHECKS_LOADED = 'DRAFT_CHECKS_LOADED';
export const FLOW_TEMPLATES_LOADED = 'FLOW_TEMPLATES_LOADED';
export const FLOW_CHECKS_LOADED = 'FLOW_CHECKS_LOADED';
export const FLOW_LOADED = 'FLOW_LOADED';
export const FLOW_SELECTED = 'FLOW_SELECTED';
export const COMMENTS_UPDATED = 'COMMENTS_UPDATED';
export const CLEAR_FLOW = 'CLEAR_FLOW';
export const CLEAR_DRAFT_FLOW = 'CLEAR_DRAFT_FLOW';
export const REMOVE_FLOW = 'REMOVE_FLOW';
export const REMOVE_DRAFT_FLOW = 'REMOVE_DRAFT_FLOW';
export const FILES_ATTACHED = 'FILES_ATTACHED';
export const FILES_REMOVED = 'FILES_REMOVED';
