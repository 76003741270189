export const ClientProvider = {
    COMPANIES_HOUSE: 'companies_house',
    DEMO: 'demo',
    HMRC: 'hmrc',
    PLAYGROUND: 'playground',
    QUICKBOOKS: 'quickbooks',
    UNINTEGRATED: 'unintegrated',
    XERO: 'xero',
} as const;

export type ClientProviderKey = keyof typeof ClientProvider;
export type ClientProvider = (typeof ClientProvider)[ClientProviderKey];

// eslint-disable-next-line import/no-default-export
export default ClientProvider;
