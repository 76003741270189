import AnalyticsService from '@/services/AnalyticsService';
import delay from '@/utils/delay';

const analytics = new AnalyticsService();

export default {
    props: {
        delay: {
            default: 100,
            required: false,
            type: Number,
        },
        isError: {
            default: false,
            required: false,
            type: Boolean,
        },
        name: {
            default: null,
            required: false,
            type: String,
        },
    },

    computed: {
        pageName() {
            return this.name ?? this.$route.name + (this.isError ? 'Error' : '');
        },
    },

    async created() {
        Boolean(this.delay) && (await delay(this.delay));
        analytics.trackPageView(this.pageName);
    },

    render() {
        return this.$slots.default;
    },
};
