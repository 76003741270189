import BaseApiService from './BaseApiService';

/**
 * For use in any place that needs to make a request to the Client Bulk Edit
 * API endpoints. All endpoints should be represented here.
 */
class QuickbooksImportService extends BaseApiService {
    /**
     * Get any abandoned imports for the team.
     *
     * @returns Promise
     */
    getAbandonedImports(practiceCrn) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.configure-quickbooks-import.get.current-imports', routeParams);
    }

    /**
     * Get client list for the bulk import.
     *
     * @param {String} practiceCrn
     * @param {String} authEventId Import identifier.
     * @returns Promise
     */
    getStagedClient(practiceCrn, authEventId) {
        return this.get('dext.configure-quickbooks-import.get.clients', { teamCrn: practiceCrn, authEventId });
    }

    /**
     * Cancel the import process.
     *
     * @param {String} practiceCrn
     * @param {String} authEventId Import identifier.
     * @param {Object} params Parameters to pass to the API endpoint.
     * @returns Promise
     */
    postCancelImport(practiceCrn, authEventId, params) {
        const { quickbooksIds, ...rest } = params;

        return this.post(
            'dext.configure-quickbooks-import.post.complete-import',
            { teamCrn: practiceCrn, authEventId },
            {
                ...rest,
                disconnect: quickbooksIds,
                import: [],
            }
        );
    }

    /**
     * Post completed data to queue for import.
     *
     * @param {String} practiceCrn
     * @param {String} authEventId Import identifier.
     * @param {Object} params Parameters to pass to the API endpoint.
     * @param {Array} [params.removedIds=[]] Any `xeroTenantId`s that need to be removed.
     * @returns Promise
     */
    postCompleteImport(practiceCrn, authEventId, params) {
        const { formData, removedIds = [], ...rest } = params;

        const normalisedData = formData.map((item) => {
            return {
                ...item,
                accountManager1: Number(item.accountManager1),
                accountManager2: item.accountManager2 ? Number(item.accountManager2) : null,
            };
        });

        return this.post(
            'dext.configure-quickbooks-import.post.complete-import',
            { teamCrn: practiceCrn, authEventId },
            {
                ...rest,
                disconnect: removedIds,
                import: normalisedData,
            }
        );
    }

    /**
     * URL to ping to keep the import alive. This prevents the import expiring
     * whilst the user is editing the client list.
     *
     * @param {String} practiceCrn
     * @param {String} authEventId Import identifier.
     * @returns Promise
     */
    postKeepAlive(practiceCrn, authEventId) {
        return this.post('dext.configure-quickbooks-import.post.ping', { teamCrn: practiceCrn, authEventId });
    }
}

export default Object.freeze(new QuickbooksImportService());
