<template>
    <div class="card" :class="[{ 'card-hover': expandable }]">
        <div v-if="$slots.title" class="card-body p-3" :class="[{ pointer: expandable }]" @click.stop="toggleOpen()">
            <div class="p-card-title">
                <div class="d-flex">
                    <slot name="title" />
                </div>

                <VIcon
                    v-if="expandable && showChevron"
                    class="p-card-title__chevron"
                    decorative
                    :name="isOpen ? 'chevron-up-thick' : 'chevron-down-thick'"
                    size="2rem"
                    :vertical-align="false"
                />
            </div>
        </div>
        <PoseTransition>
            <Component :is="contentComponent" v-if="(isOpen || !expandable) && hasContent">
                <div class="details">
                    <div :class="{ 'p-3': bodyPadding }">
                        <slot />
                    </div>
                </div>
            </Component>
        </PoseTransition>
    </div>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose';

export default {
    name: 'Card',
    inject: ['analyticsProvider'],
    props: {
        expandable: { type: Boolean, default: false },
        hasContent: { type: Boolean, default: true },
        showChevron: { type: Boolean, default: true },
        bodyPadding: { type: Boolean, default: true },
        name: { type: String, required: false, default: '' },
        open: { type: Boolean, required: false, default: false },
    },
    components: {
        PoseTransition,
    },
    data() {
        return {
            isOpen: this.open,
        };
    },
    computed: {
        contentComponent() {
            const posableDiv = posed.div({
                enter: { height: 'auto', transition: { duration: 200 } },
                exit: { height: 0, transition: { duration: 200 } },
            });

            return this.expandable ? posableDiv : 'div';
        },
    },
    methods: {
        toggleOpen() {
            if (!this.isOpen) {
                this.$emit('expanded');
            }

            this.isOpen = !this.isOpen;
            this.trackClick();
        },
        trackClick() {
            const cardStatus = this.isOpen ? 'Open' : 'Close';

            Boolean(this.name) && this.analyticsProvider.trackElementClick(`${cardStatus} ${this.name}`, 'Card');
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.card {
    background: white;
}

.p-card-title {
    align-items: center;
    display: flex;
    width: 100%;

    > div {
        width: 100%;
    }
}

.p-card-title__chevron {
    margin-left: auto;
}

.details {
    border-top: 1px solid get-color(silver);
}
</style>
