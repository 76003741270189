<template>
    <MenuItem v-bind="{ ...$attrs, ...$props }" class="menu-item--card" ref="item" v-on="$listeners">
        <template #item>
            <div class="menu-item__image" v-if="hasImageSlot">
                <slot name="image" />
            </div>

            <div class="menu-item__label">
                <slot />
            </div>
        </template>
    </MenuItem>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useHasSlot from '@/hooks/useHasSlot';
import MenuItem from './MenuItem.vue';

import { MenuItemProps } from '../types';

export default defineComponent<MenuItemProps>({
    name: 'MenuItemCard',
    components: { MenuItem },
    extends: MenuItem,

    setup() {
        const hasImageSlot = useHasSlot('image');

        return { hasImageSlot };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.menu-item--card {
    border-radius: 4px;
    color: get-color(charcoal, medium);
    flex: 1;
    flex-direction: column;
    font-weight: $font-weight-bold;
    justify-content: center;
    max-width: 50%;
    min-width: 90px;
    padding: 8px;
}

.menu-item--card.menu-item--clickable:not(.menu-item--disabled) {
    color: get-color(charcoal, medium);

    &:hover {
        background-color: get-color(silver, medium);
    }

    &.menu-item--active {
        box-shadow: 0 0 0 0.2rem rgba(get-color(gray, medium), 0.5) inset;
    }
}

.menu-item__image {
    height: 56px;
    width: 56px;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.menu-item__label {
    line-height: 1.2;
    margin-top: 4px;
    text-align: center;
}
</style>
