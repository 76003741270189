<template>
    <div class="product-tour-provider">
        <WelcomeModal v-if="renderWelcomeModal" v-model="welcomeModalVisible" @startTutorial="startFirstTutorial" />
        <LoadingDemoModal v-model="loadingDemoModalVisible" />
        <TourCompletedModal
            :value="productTourStore.tourCompleteModalVisible"
            @addClient="redirectToAddClients"
            @cancel="cancelTourCompletedModal"
        />
        <slot />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Shepherd from 'shepherd.js';
import { ref, provide, nextTick, onMounted, watch, computed } from 'vue';
import { useGetters } from 'vuex-composition-helpers';

import { useAppContext } from '@/App/composables/useAppContext';
import { useRouter } from '@/composables/useRouter';
import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import { ProductTours } from '@/enums/ProductTours';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { WelcomeModal, TourCompletedModal, LoadingDemoModal } from '@/scenes/ProductTour/components';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';
import { useProductTourStore } from '@/store/productTour';

import type { ProductTourStep, ProductTourContextApi, ProductTour } from './types';

import { ProductTourContext } from './useProductTour';

const router = useRouter();

const currentTour = ref<ProductTour | null>();
const productTourStore = useProductTourStore();

// These are the CMS steps, they contain copy, they do not include functional steps.
const tourSteps = ref<ProductTourStep[]>([]);
const { isWelcomeModalViewed, demoClientLoading } = storeToRefs(productTourStore);
const { clientList } = useGetters('legacyClients', ['clientList']);
const { isClientImporting } = useGetters('imports', ['isClientImporting']);

function redirectToAddClients() {
    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

    productTourStore.tourCompleteModalVisible = false;
    if (router?.currentRoute.name !== 'team.home') {
        router?.push({ name: 'dext.platform.client.setup', params: { teamRbExternalId: practiceCrn } });
    }
}

function cancelTourCompletedModal() {
    productTourStore.hideTourCompleteModal();
    if (router?.currentRoute.name !== 'team.home') {
        router?.push({ name: 'team.home' });
    }
}

const loadingDemoModalVisible = computed<boolean>(() => {
    return demoClientLoading.value;
});

const isDemoClientImporting = computed(() => {
    if (!productTourStore.clientId) {
        return false;
    }

    return isClientImporting.value(productTourStore.clientId);
});

watch(isDemoClientImporting, async (newValue, oldValue) => {
    if (!newValue && oldValue) {
        setTimeout(async () => {
            productTourStore.demoClientLoading = false;

            if (productTourStore.currentProductTourKey && productTourStore.pendingProductTour) {
                await productTourStore.resume(productTourStore.currentProductTourKey);
            }
        }, 1000);
    }
});

const { isEmbedded } = useAppContext();

// In some instances we do not want to show the welcome modal
// For example when landing on add a client page which has an onboarding modal of it's own
const excludedFromRouteNames = ['dext.platform.client.setup'];
const visibleInCurrentRoute = router?.currentRoute.name
    ? !excludedFromRouteNames.includes(router?.currentRoute.name)
    : true;

const renderWelcomeModal =
    !isEmbedded.value &&
    useXavierGlobals().subscriptionValid &&
    useIsFeatureEnabled(Feature.PRODUCT_TOUR) &&
    useIsFeatureEnabled(Feature.PLAYGROUND_CLIENTS) &&
    visibleInCurrentRoute;
const welcomeModalVisible = ref<boolean>(false);

onMounted(() => {
    if (renderWelcomeModal) {
        welcomeModalVisible.value = !isWelcomeModalViewed.value;
        if (welcomeModalVisible.value === true) {
            productTourStore.setWelcomeModalViewed();
        }
    }
});

async function startFirstTutorial() {
    await productTourStore.start(ProductTours.MONTHLY_BOOKKEEPING, 0);
    welcomeModalVisible.value = false;
}

async function createTour(): Promise<void> {
    if (currentTour.value) return;

    currentTour.value = new Shepherd.Tour({
        classPrefix: 'dext-tour-',
        confirmCancel: false,
        defaultStepOptions: {
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    nextTick(() => resolve(null));
                });
            },
            cancelIcon: {
                // Created a custom cancel icon to allow triggering the cancel modal
                enabled: false,
            },
            classes: 'dext-shepherd-product-tour',
            scrollTo: true,
        },
        exitOnEsc: false,
        keyboardNavigation: false,
        useModalOverlay: true,
    });
}

function currentStep(): ProductTourStep | null {
    if (!currentTour.value) return null;

    return currentTour.value.getCurrentStep();
}

function currentStepIndex(): number {
    if (!currentTour.value) return 0;

    return currentTour.value.steps.indexOf(currentStep());
}

function addSteps(steps: ProductTourStep[]) {
    if (!currentTour.value) return;

    tourSteps.value = [...steps];

    steps.forEach((step, index) => {
        createExtraElements(step, index);
    });

    return currentTour.value.addSteps(steps);
}

function updateStep(step: ProductTourStep) {
    if (!currentTour.value) return;

    const steps = currentTour.value.steps;

    const foundStep = steps.find((s: ProductTourStep) => s.id === step.id);

    if (!foundStep) return;

    foundStep.updateStepOptions({
        ...step,
    });
}

/**
 * Triggers the cancellation of the current tour.
 */
function cancel() {
    productTourStore.reset();
    if (!currentTour.value) return;

    document.querySelector('.dext-shepherd-product-tour-cancel-icon')?.remove();

    currentTour.value.cancel();

    currentTour.value = null;

    if (router?.currentRoute.name !== 'team.getStarted') {
        router?.push({ name: 'team.getStarted' });
    }
}

/**
 * Triggers the completion of the current tour.
 */
function complete() {
    if (!currentTour.value) return;

    productTourStore.complete();

    const nonPlaygroundClients = clientList.value.filter((client) => client.provider !== ClientProvider.PLAYGROUND);

    if (nonPlaygroundClients.length === 0) {
        productTourStore.showTourCompleteModal();
    } else {
        router?.push({ name: 'team.home' });
    }

    currentTour.value.complete();
    currentTour.value = null;
}

/**
 * Navigates to the next step in the current tour.
 */
function next() {
    if (!currentTour.value) return;

    currentTour.value.next();
    productTourStore.progress(currentStepIndex());
}

/**
 * Navigates to the previous step in the current tour.
 */
function back() {
    if (!currentTour.value) return;

    currentTour.value.back();

    productTourStore.progress(currentStepIndex());
}

/**
 * Shows the current tour.
 */
function show(key?: number | string, forward?: boolean) {
    if (!currentTour.value) return;

    document.querySelector('body')?.classList.add('show-tour');

    return currentTour.value.show(key, forward);
}

/**
 * Hides the current tour.
 */
function hide() {
    if (!currentTour.value) return;

    return currentTour.value.hide();
}

/**
 * Returns the steps of the current tour.
 */
function getSteps() {
    return tourSteps.value;
}

/**
 * Returns boolean value indicating whether the current tour is active.
 */
function isActive() {
    if (!currentTour.value) return;

    return currentTour.value.isActive();
}

/**
 * Handles the creation of extra elements to be inserted to the your UI.
 * These are DOM manipulated as we do not have control over the Shepherd.js UI within Vue.
 * @param step
 * @param index
 */
function createExtraElements(step: ProductTourStep, index: number) {
    // If Shepherd.js is replaced, this can be deleted.
    // Add cancel icon and progress indicator
    step.when = {
        show() {
            const currentStep = Shepherd.activeTour?.getCurrentStep();
            const currentStepElement = currentStep?.getElement();

            if (!currentStepElement) return;

            if (!document.querySelector('.dext-shepherd-product-tour-cancel-icon')) {
                const pageHeader = document.querySelector('.page-header');
                const pageHeaderHeight = pageHeader ? pageHeader.clientHeight : 0;

                const cancelIcon = document.createElement('div');

                cancelIcon.className = 'dext-shepherd-product-tour-cancel-icon';
                cancelIcon.innerHTML = 'Close tutorial';
                cancelIcon.style.top = pageHeaderHeight + 32 + 'px';
                cancelIcon.onclick = () => cancel();

                document.querySelector('body').appendChild(cancelIcon);
            }

            const footer = currentStepElement?.querySelector('.shepherd-footer');
            const progress = document.createElement('span');

            progress.className = 'dext-tour-progress';
            progress.innerText = `${index + 1} of ${Shepherd.activeTour?.steps.length}`;
            // DOM manipulation to insert the cancel icon
            // This is a solution as we do not have control over the Shepherd.js UI within Vue.
            footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
        },
    };
}

watch(
    () => productTourStore.productTourSteps,
    async () => {
        // Initialize shepherd Tour if store has new tour loaded
        if (productTourStore.productTourSteps.length) {
            currentTour.value = null;
            await createTour();
            addSteps(productTourStore.productTourSteps);
        }
    }
);

const api: ProductTourContextApi = {
    back,
    cancel,
    complete,
    createTour,
    currentStep,
    currentTour,
    getSteps,
    hide,
    isActive,
    next,
    show,
    updateStep,
};

provide(ProductTourContext, api);
</script>

<!-- Styles is resources/sass/dext/0-vendor/_shepherd.scss -->
