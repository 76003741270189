import numbro from 'numbro';
import { getBrowserLocale } from './browser';

export function round(value: number, places = 2) {
    if ([null, undefined, Infinity].indexOf(value) !== -1) {
        return '';
    }

    const scale = 10 ** places;

    return Math.round(Number(value) * scale) / scale;
}

export function format(value: number, places = 2, minimumPlaces?: number) {
    if ([null, undefined, Infinity].indexOf(value) !== -1) {
        return '';
    }

    const locale = getBrowserLocale();

    return Number(value).toLocaleString(locale, {
        minimumFractionDigits: minimumPlaces ?? places,
        maximumFractionDigits: places,
    });
}

export function humanize(value: number, digits = 1) {
    return numbro(value).format({
        average: true,
        mantissa: digits,
        trimMantissa: true,
        optionalMantissa: true,
    });
}
