export const reportingModesWithoutManual = [
    { value: 'monthEnd', label: 'Last Complete Month (default)', isDefault: true },
    { value: 'quarterEnd', label: 'Last 3 Complete Months' },
    { value: 'yearEnd', label: 'Last 12 Complete Months' },
    { value: 'vatEnd', label: 'Last Complete salesTax Period' },
    { value: 'lastFinancialYear', label: 'Last Financial Year' },
    { value: 'currentMonth', label: 'Current Month To Date' },
    { value: 'currentVat', label: 'Current salesTax Period To Date' },
    { value: 'currentFinancialYear', label: 'Financial Year To Date' },
];

/**
 * Deepy copy reportingModesWithoutManual to reportingModesWithManual so that we
 * push to it without affecting the original.
 */
let reportingModesWithManual = reportingModesWithoutManual.map((a) => {
    return { ...a };
});

// Add manual as an option
reportingModesWithManual.push({ value: 'manual', label: 'Manual' });

export const reportingModes = reportingModesWithManual;

export function reportingModeOptions(countryCode, localeManager) {
    return reportingModes.map((o) => {
        return { ...o, label: localeManager.localiseText(o.label, countryCode) };
    });
}

export function reportingModeOptionsNoManual(countryCode, localeManager) {
    return reportingModesWithoutManual.map((o) => {
        return { ...o, label: localeManager.localiseText(o.label, countryCode) };
    });
}

export function reportingModeForClient(client, localeManager) {
    const options = reportingModeOptions(client.countryCode, localeManager);

    let mode = options.find((m) => m.value === client.reportingMode);

    return mode ? mode.label : options.find((m) => m.isDefault).label;
}
