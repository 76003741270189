/**
 * This directive will detect whether the click is outside the component / element it as
 * attached to. If it's not, then fire the passed in event method.
 *
 * @link https://stackoverflow.com/a/42389266/1694116
 */
export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', el.clickOutsideEvent);
    },

    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};
