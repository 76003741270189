import { GetterTree } from 'vuex';
import { TotalTransactionsState, WidgetResultsState } from '@/store/modules/insightsDashboard/totalTransactions/types';

import { RootState } from '@/store/types';

export type TotalTransactionsGetters = {
    totalTransactionsWidgetLoading(state: TotalTransactionsState): boolean;
    totalTransactionsWidgetResults(state: TotalTransactionsState): WidgetResultsState;
};

export const getters: GetterTree<TotalTransactionsState, RootState> & TotalTransactionsGetters = {
    totalTransactionsWidgetLoading(state) {
        return state.totalTransactions.widget.isLoading;
    },
    totalTransactionsWidgetResults(state) {
        return state.totalTransactions.widget.results;
    },
    totalTransactionsDetailsLoading(state) {
        return state.totalTransactions.details.isLoading;
    },
    totalTransactionsDetailsResults(state) {
        return state.totalTransactions.details.results;
    },
};
