<template>
    <div class="breadcrumbs-container" data-qa="breadcrumb">
        <BreadcrumbsList :breadcrumbs="breadcrumbs" v-if="breadcrumbs.length > 0" />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import '@total-typescript/ts-reset/filter-boolean';

import { useRoute } from '@/composables/useRoute';

import { BreadcrumbsList } from './BreadcrumbsList';
import type { BreadcrumbConfig } from './Breadcrumbs.types';

const route = useRoute();

const breadcrumbs = computed<BreadcrumbConfig[]>(() => {
    if (!route.value || route.value.meta?.breadcrumbs === false) return [];

    return route.value.matched.reduce((acc, routeRecord) => {
        const routeBreadcrumbs = routeRecord.meta.breadcrumbs;

        if (routeBreadcrumbs === false || !routeBreadcrumbs?.length) return acc;

        const filtered = routeBreadcrumbs.filter(Boolean) as BreadcrumbConfig[];

        return [...acc, ...filtered];
    }, [] as BreadcrumbConfig[]);
});

defineExpose({ breadcrumbs });
</script>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({ name: 'PageBreadcrumbs' });
/* eslint-enable */
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';
</style>
