import type { ClientId } from '@/store/modules/client/types/Client';
import type { Import } from '@/store/modules/imports/types/Import';

import BaseApiService from './BaseApiService';

/**
 * For use in any place that needs to make a request to the Imports API endpoints.
 */
class ImportsService extends BaseApiService {
    /**
     * Get full imports progress for a client.
     *
     * @returns Promise
     * @param practiceCrn
     * @param clientId
     */
    getByClient(practiceCrn: string, clientId: ClientId): Promise<Array<Import>> {
        return this.get<Array<Import>, never>('dext.imports.progress.by-client', {
            clientId: clientId,
            teamCrn: practiceCrn,
        });
    }
}

export default Object.freeze(new ImportsService());
