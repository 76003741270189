<template>
    <DInput :error-messages="errorMessages" :hint="hint" :input-id="id || name" :label="label" :status="inputStatus">
        <template #label>
            <slot name="label" />
        </template>

        <div :class="className" :data-replicated-value="autogrow ? value : null">
            <textarea
                :id="id || name"
                class="d-input"
                :disabled="disabled || loading"
                :name="name"
                type="text"
                :value="value"
                v-bind="$attrs"
                v-on="listeners"
            />
        </div>

        <template #hint>
            <slot name="hint" />
        </template>
    </DInput>
</template>

<script setup lang="ts">
import { computed, useListeners } from 'vue';

import type { InputStatus } from '../DForm.types';

import { useInputStatus } from '../composables/useInputStatus';
import { DInput } from '../DInput';

type Props = {
    autogrow?: boolean;
    autogrowMaxLines?: string | number;
    disabled?: boolean;
    errorMessages?: string | string[];
    hint?: string;
    id?: string;
    label?: string;
    loading?: boolean;
    name: string;
    status?: InputStatus;
    value?: string;
};

const props = defineProps<Props>();
const inputStatus = useInputStatus(props);
const emit = defineEmits<{ (e: 'input', value: string): void }>();
const _listeners = useListeners();

/**
 * This custom listeners definitions is due to a bug with v-model and
 * v-on="$listeners" in Vue 2.5.1+.
 *
 * @link https://github.com/vuejs/vue/issues/7042
 */
const listeners = computed(() => ({
    ..._listeners,
    input(event: InputEvent) {
        emit('input', (event.target as HTMLInputElement).value);
    },
}));

const className = computed(() => {
    return {
        'd-text-area-control': true,
        'd-text-area-autogrow': props.autogrow,
        'd-error': inputStatus.value === 'error',
        'd-loading': props.loading,
        'd-success': inputStatus.value === 'success',
        'd-warning': inputStatus.value === 'warning',
    };
});

const maxLines = computed(() => {
    return props.autogrowMaxLines ? Number(props.autogrowMaxLines) + 1 : undefined;
});
</script>

<script lang="ts">
export default { name: 'DTextAreaField' };
</script>

<style scoped>
/*
 * @link https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */
.d-text-area-control > textarea {
    /* Set the minimum height to four rows */
    min-height: calc((2ex * 5) + (var(--d-spacing-1) * 2));
}

.d-text-area-autogrow {
    display: grid;
    word-break: break-word;
}

.d-text-area-autogrow::after {
    border: 2px solid transparent;
    content: attr(data-replicated-value) ' ';
    line-height: 20px;
    padding: var(--d-spacing-0) var(--d-spacing-1);
    visibility: hidden;
    white-space: pre-wrap;
}

.d-text-area-autogrow > textarea {
    overflow: auto;
    resize: none;
}

.d-text-area-autogrow > textarea,
.d-text-area-autogrow::after {
    grid-area: 1 / 1 / 2 / 2;
    max-height: calc((2ex * v-bind('maxLines')) + (var(--d-spacing-1) * 2));
}
</style>
