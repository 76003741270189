import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';

import * as types from './types';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        tokens: {},
    },

    getters: {
        tokens: (state) => state.tokens,
        isLoading: (state) => state.isLoading,
    },
    actions: {
        fetchTokens({ commit }) {
            commit(types.SET_LOADING, true);

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios.get(`/api/teams/${practiceCrn}/user/api-token`).then((response) => {
                commit(types.SET_TOKENS, response.data.tokens);
                commit(types.SET_LOADING, false);
            });
        },
        deleteToken({ commit, state }, token) {
            commit(types.SET_LOADING, true);

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            return axios
                .delete(`/api/teams/${practiceCrn}/user/api-token/${token.id}`)
                .then((response) => {
                    commit(types.UNSET_TOKEN, token);
                    commit(types.SET_LOADING, false);

                    return Promise.resolve();
                })
                .catch(() => {
                    commit(types.SET_LOADING, false);

                    return Promise.reject();
                });
        },

        updateToken({ commit }, params) {
            commit(types.SET_LOADING, true);

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            return axios
                .put(`/api/teams/${practiceCrn}/user/api-token/${params.token.id}`, params.updateData)
                .then((response) => {
                    commit(types.SET_LOADING, false);
                    commit(types.SET_TOKEN, response.data.token);

                    return Promise.resolve();
                })
                .catch(() => {
                    commit(types.SET_LOADING, false);

                    return Promise.reject();
                });
        },

        createToken({ commit }, createData) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            return axios
                .post(`/api/teams/${practiceCrn}/user/api-token`, createData)
                .then((response) => {
                    commit(types.SET_LOADING, false);
                    commit(types.SET_TOKEN, response.data.token);

                    return Promise.resolve({ tokenString: response.data.tokenString });
                })
                .catch(() => {
                    commit(types.SET_LOADING, false);

                    return Promise.reject();
                });
        },
    },

    mutations: {
        [types.SET_LOADING](state, isLoading) {
            state.isLoading = isLoading;
        },
        [types.SET_TOKENS](state, tokens) {
            for (const token of tokens) {
                Vue.set(state.tokens, token.id, token);
            }
        },
        [types.UNSET_TOKEN](state, token) {
            delete state.tokens[token.id];
        },
        [types.SET_TOKEN](state, token) {
            Vue.set(state.tokens, token.id, token);
        },
    },
};
