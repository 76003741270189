import clients from './clients';
import xeroUsers from './xeroUsers';

export const NAMESPACE = 'clientBulkImport';

export default {
    namespaced: true,

    modules: {
        clients,
        xeroUsers,
    },
};
