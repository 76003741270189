import clients from './clients';

export const NAMESPACE = 'quickbooksImport';

export default {
    namespaced: true,

    modules: {
        clients,
    },
};
