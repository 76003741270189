import { Store, MutationPayload } from 'vuex';

import clientStore, { clientInitialState } from '@/store/modules/client/client.store';
import { Client } from '@/store/modules/client/types/Client';
import { ClientMutationTypes, ClientState } from '@/store/modules/client/types/Store';
import { RootState } from '@/store/types';

export default function clientHandler(store: Store<RootState>, mutation: MutationPayload) {
    mutation.payload.clients.forEach((client: Client) => {
        const moduleName = `client_${client.slug}`;
        const moduleExists = Boolean(store.state.clients[`client_${client.slug}`]);

        if (moduleExists) {
            store.commit(`clients/${moduleName}/${ClientMutationTypes.LOAD_CLIENT_SUCCESS}`, { client });
        } else {
            store.registerModule(['clients', moduleName], {
                ...clientStore,
                state(): ClientState {
                    return {
                        ...clientInitialState,
                        data: { ...client },
                    };
                },
            });
        }

        store.watch(
            (state) => state.clients.allIds,
            (newValue) => {
                const clientExists = newValue.includes(client.slug);

                if (!clientExists) {
                    store.unregisterModule(['clients', moduleName]);
                }
            }
        );
    });
}
