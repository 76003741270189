import { ref, useSlots } from 'vue';

export default function useHasSlot(name: string) {
    const hasSlot = ref(false);
    const slots = useSlots();

    hasSlot.value = Boolean(slots[name]);

    return hasSlot;
}
