<template>
    <VIcon :class="iconClass" :name="iconName" v-bind="iconProps" :size="size" v-on="$listeners" v-if="iconName" />
</template>

<script>
export default {
    name: 'AlertIcon',
    props: {
        size: {
            type: [Number, String],
            default: '25px',
        },
        status: {
            type: String,
            required: true,
            validator(value) {
                const validStatus = ['success', 'warning', 'error'];
                const isValid = validStatus.includes(value);

                if (!isValid) {
                    // eslint-disable-next-line no-console
                    console.error(`[Vue warn]: Invalid prop: invalid status of "${value}" provided for prop "status".`);
                }

                return isValid;
            },
        },
    },
    computed: {
        iconClass() {
            switch (this.status) {
                case 'error':
                    return 'icon-error';
                case 'success':
                    return 'icon-success';
                case 'warning':
                    return 'icon-warning';
                default:
                    return null;
            }
        },
        iconName() {
            switch (this.status) {
                case 'error':
                    return 'error-warning-filled';
                case 'success':
                    return 'success-checklist-filled';
                case 'warning':
                    return 'content-warning-filled';
                default:
                    return null;
            }
        },
        iconProps() {
            // Omit the `status` prop here, as we don't want to pass it
            // down to the child component.
            const { status, ...props } = this.$props;

            return { ...props, ...this.$attrs };
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.icon-error {
    color: get-color(red) !important;
}

.icon-success {
    color: get-color(green) !important;
}

.icon-warning {
    color: get-color(amber) !important;
}
</style>
