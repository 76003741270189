import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';

import * as types from './types';

export default {
    namespaced: true,
    state: {
        billingDetails: undefined,
        clientActivity: [],
        invoices: [],
        subscription: undefined,
        subscriptionTypes: [],
    },
    getters: {
        billingDetails: (state) => state.billingDetails,
        currentSubscription: (state) => state.subscription,
        clientActivity: (state) => state.clientActivity,
        invoices: (state) => state.invoices,
        subscriptionTypes: (state) => state.subscriptionTypes,
        onTrial: (state) =>
            !state.subscription || ['trial_expired', 'trialing'].indexOf(state.subscription.status) >= 0,
        rbInvoicing: (state) => state.subscription && state.subscription.billingType === 'receipt_bank_invoicing',
    },
    actions: {
        async loadBillingDetails({ commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/billing-details`);

            commit(types.BILLING_DETAILS_LOADED, data.data);
        },
        async saveBillingDetails({ dispatch, commit }, details) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.put(`/api/teams/${practiceCrn}/team/billing-details`, details);

            if (!data.error) {
                commit(types.BILLING_DETAILS_LOADED, data.data);
            }

            return data.data ? data.data : data;
        },
        async saveCardDetails({ dispatch, commit }, paymentMethodId) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.put(`/api/teams/${practiceCrn}/team/card-details`, {
                paymentMethodId: paymentMethodId,
            });

            if (!data.error) {
                commit(types.BILLING_DETAILS_LOADED, data.data);
            }

            return data.data ? data.data : data;
        },
        async updateSubscription({ dispatch, commit }, details) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.post(`/api/teams/${practiceCrn}/team/subscription`, details);

            if (!data.error) {
                commit(types.SUBSCRIPTION_LOADED, data);
                dispatch('loadBillingDetails');
                dispatch('loadInvoices');
            }

            return data;
        },
        async applyCoupon({ dispatch, commit }, coupon) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.post(`/api/teams/${practiceCrn}/team/subscription/coupon`, { coupon: coupon });

            if (!data.error) {
                commit(types.SUBSCRIPTION_LOADED, data);
            }

            return data;
        },
        async loadSubscription({ commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/subscription`);

            commit(types.SUBSCRIPTION_LOADED, data);
        },
        async loadSubscriptionTypes({ commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/subscription-types`);

            commit(types.SUBSCRIPTION_TYPES_LOADED, data.data);
        },
        async cancelSubscription({ dispatch, state }) {
            if (!state.subscription) {
                return;
            }

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await axios.delete(`/api/teams/${practiceCrn}/team/subscription`);
            await dispatch('loadSubscription');
        },
        async reactivateSubscription({ dispatch, commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.put(`/api/teams/${practiceCrn}/team/subscription`);

            if (!data.error) {
                commit(types.SUBSCRIPTION_LOADED, data);
                dispatch('loadBillingDetails');
            }

            return data;
        },
        async loadInvoices({ commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/invoices`);

            commit(types.INVOICES_LOADED, data);
        },
        async loadClientActivity({ commit, state }) {
            if (!state.subscription || !state.subscription.currentPeriodStart || !state.subscription.currentPeriodEnd) {
                return;
            }

            const from = state.subscription.currentPeriodStart.substring(0, 10);
            const to = state.subscription.currentPeriodEnd.substring(0, 10);
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/client-activity/${from}/${to}`);

            commit(types.CLIENT_ACTIVITY_LOADED, data);
        },
    },
    mutations: {
        [types.BILLING_DETAILS_LOADED](state, details) {
            Vue.set(state, 'billingDetails', details);
        },
        [types.CLIENT_ACTIVITY_LOADED](state, clients) {
            Vue.set(state, 'clientActivity', clients);
        },
        [types.INVOICES_LOADED](state, invoices) {
            Vue.set(state, 'invoices', invoices);
        },
        [types.SUBSCRIPTION_LOADED](state, subscription) {
            Vue.set(state, 'subscription', subscription);
        },
        [types.SUBSCRIPTION_TYPES_LOADED](state, types) {
            Vue.set(state, 'subscriptionTypes', types);
        },
    },
};
