<template>
    <Component :is="tag" v-bind="attrs" :class="className" v-on="$listeners">
        <VIcon class="d-badge__icon" :name="iconName" size="16px" v-if="iconName" />

        <slot />

        <div class="d-badge__count" v-if="$slots.count">
            <slot name="count" />
        </div>
    </Component>
</template>

<script>
export default {
    name: 'Badge',

    provide() {
        return {
            variant: this.variant,
        };
    },

    props: {
        iconName: {
            default: null,
            required: false,
            type: String,
        },

        name: {
            default: null,
            required: false,
            type: String,
        },

        variant: {
            default: 'neutral',
            required: false,
            type: String,
            validator(value) {
                const validVariants = ['error', 'neutral', 'processing', 'success', 'warning'];
                const isValid = validVariants.includes(value);

                if (!isValid) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `[Vue warn]: Invalid prop: invalid variant of "${value}" provided for prop "variant".`
                    );
                }

                return isValid;
            },
        },
    },

    computed: {
        attrs() {
            if (this.tag === 'VButton') {
                return { name: this.name ? `Badge ${this.name}` : `Badge ${this.variant}` };
            }

            return {};
        },

        className() {
            return {
                'd-badge': true,
                'd-badge--clickable': Boolean(this.$listeners.click),
                'd-badge--with-count': Boolean(this.$slots.count),
                [`d-badge--${this.variant}`]: Boolean(this.variant),
            };
        },

        tag() {
            return this.$listeners.click ? 'VButton' : 'div';
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

$variants: (
    error: (
        background: get-color(red, medium),
        text: get-color(red, dark),
    ),
    neutral: (
        background: get-color(silver, medium),
        text: get-color(gray),
    ),
    processing: (
        background: get-color(blue, medium),
        text: get-color(blue, extra-dark),
    ),
    success: (
        background: get-color(green, medium),
        text: get-color(green, extra-dark),
    ),
    warning: (
        background: get-color(amber, medium),
        text: get-color(amber, extra-dark),
    ),
);

.d-badge {
    align-items: center;
    border: 0;
    border-radius: 4px;
    display: inline-flex;
    font-size: pxtorem(13);
    line-height: 1.23;
    padding: 5px 8px;
}

.d-badge--with-count {
    padding: 4px 8px;
}

.d-badge__icon {
    margin-right: 3px;
}

@each $variant-name, $config in $variants {
    $bg-color: map-get($config, background);

    .d-badge--#{$variant-name} {
        background-color: $bg-color;
        color: map-get($config, text);
    }

    .d-badge--#{$variant-name}.d-badge--clickable:hover {
        background-color: darken($bg-color, 10%);
    }
}
</style>
