import { computed } from 'vue';

import type { InputStatus } from '../DForm.types';

export function useInputStatus(props: { status?: InputStatus; errorMessages?: string | string[] }) {
    const inputStatus = computed(() => {
        if (props.status) return props.status;
        if (props.errorMessages?.length) return 'error';

        return undefined;
    });

    return inputStatus;
}
