import { GetterTree, Module } from 'vuex';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';

import { RootState } from '@/store/types';
import { FeatureGetters, FeatureStore } from './types/FeaturesStore';

export const initialState = (): FeatureStore => {
    return {
        data: window.Xavier.features ?? [],
    };
};

/* Actions
========================================================================== */
const actions = {
    // We probably don't need any, but the option is there
};

/* Mutations
========================================================================== */
const mutations = {
    // We probably don't need any, but the option is there
};

/* Getters
========================================================================== */
const getters: GetterTree<FeatureStore, RootState> & FeatureGetters = {
    /**
     * Detect if a given feature, or features, are enabled for the team. Accepts
     * an array or a string value based on the Feature enum.
     *
     * @param state
     * @returns Function that can be used to search if feature(s) enabled
     */
    isFeatureEnabled(state) {
        return function (feature) {
            if (isString(feature)) {
                return state.data.includes(feature);
            }

            if (isArray(feature)) {
                return feature.every((item) => {
                    return state.data.includes(item);
                });
            }

            return false;
        };
    },
};

const featureStore: Module<FeatureStore, RootState> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state: initialState,
};

export default featureStore;
