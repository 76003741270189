<template>
    <Fragment>
        <VButton
            alt
            block
            class="flow-action"
            color="primary"
            :disabled="currentFlow.reviewerId !== userId"
            name="Complete Single Review"
            @click="$emit('finish')"
        >
            {{ currentFlow.reviewerId === userId ? translator.t(t.completeReview) : translator.t(t.underReview) }}
        </VButton>

        <VButton
            v-if="currentFlow.reviewerId === userId"
            block
            class="flow-action"
            color="secondary"
            name="Single Review Hand Back"
            @click="$emit('hand-back')"
        >
            {{ translator.t(t.handBack) }}
        </VButton>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

import { useVueI18n } from '@/composables/useVueI18n';

import { translations as t } from './SingleReview.translations';

export default defineComponent({
    components: { Fragment },
    emits: ['finish', 'hand-back'],
    name: 'FlowSidebarActionsSingleReview',

    props: {
        currentFlow: PropTypes.object().isRequired,
        userId: PropTypes.number().isRequired,
    },

    setup() {
        const translator = useVueI18n();

        return { t, translator };
    },
});
</script>

<style lang="scss" scoped src="./flow-actions.scss"></style>
