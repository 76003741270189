import { GetterTree } from 'vuex';
import {
    ReconciliationActivityState,
    WidgetResultsState,
} from '@/store/modules/insightsDashboard/reconciliationActivity/types';
import { RootState } from '@/store/types';

export type ReconciliationActivityGetters = {
    reconciliationActivityWidgetLoading(state: ReconciliationActivityState): boolean;
    reconciliationActivityWidgetResults(state: ReconciliationActivityState): WidgetResultsState;
};

export const getters: GetterTree<ReconciliationActivityState, RootState> & ReconciliationActivityGetters = {
    reconciliationActivityWidgetLoading(state) {
        return state.reconciliationActivity.widget.isLoading;
    },
    reconciliationActivityWidgetResults(state) {
        return state.reconciliationActivity.widget.results;
    },
    reconciliationActivityDetailsLoading(state) {
        return state.reconciliationActivity.details.isLoading;
    },
    reconciliationActivityDetailsResults(state) {
        return state.reconciliationActivity.details.results;
    },
};
