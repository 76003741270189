<template>
    <li :class="className">
        <template v-if="data.iframeEvent">
            <DButton
                v-if="!hasChildren"
                class="breadrumbs-link"
                :disabled="isDisabled"
                :prepend-icon="data.iconName ? [data.iconName, 'thick'] : undefined"
                variant="transparent"
                @click="triggerIframeEvent(data.iframeEvent)"
            >
                {{ data.label }}
            </DButton>
        </template>
        <template v-if="!data.iframeEvent">
            <DButton
                v-if="!hasChildren"
                class="breadrumbs-link"
                :disabled="isDisabled"
                :prepend-icon="data.iconName ? [data.iconName, 'thick'] : undefined"
                :to="data.to"
                variant="transparent"
            >
                {{ data.label }}
            </DButton>
        </template>

        <Menu v-if="hasChildren" :disabled="isDisabled" name="Breadcrumbs children">
            <template #activator="{ attrs, on, isVisible }">
                <DButton class="breadrumbs-link" v-bind="attrs" v-on="on">
                    {{ data.label }}

                    <template #icon-right>
                        <DIcon :name="isVisible ? 'arrow-up' : 'arrow-down'" variant="filled" />
                    </template>
                </DButton>
            </template>

            <MenuItems>
                <template v-if="data.iframeEvent">
                    <MenuItem
                        v-if="!isActive"
                        :disabled="isDisabled"
                        name="Breadcrumb"
                        @click="triggerIframeEvent(data.iframeEvent)"
                    >
                        {{ data.label }}
                    </MenuItem>
                </template>
                <template v-if="!data.iframeEvent">
                    <MenuItem
                        v-for="(child, childIndex) in data.children"
                        :key="child.id || childIndex"
                        :disabled="child.disabled"
                        name="Breadcrumb"
                        :to="child.to"
                    >
                        {{ child.label }}
                    </MenuItem>
                </template>
            </MenuItems>
        </Menu>
    </li>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { useClientContext } from '@/App/composables/useClientContext';
import { useIframeEventHandlers } from '@/App/composables/useIframeEventHandlers';
import { DButton, DIcon } from '@/components/DextUi';
import { Menu, MenuItems, MenuItem } from '@/components/Menu';
import { useRoute } from '@/composables/useRoute';
import { useRouter } from '@/composables/useRouter';
import { PrepareSendMessageEvents } from '@/enums/prepare/IframeMessageEvents';
import { ClientNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import useXavierGlobals from '@/hooks/useXavierGlobals';

import type { BreadcrumbListItem } from './BreadcrumbsList.types';
import type { IFrameNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';

type Props = {
    data: BreadcrumbListItem;
    index: number;
};

const props = defineProps<Props>();

const route = useRoute();
const router = useRouter();

const { dispatchIframeEvent } = useIframeEventHandlers();
const { currentClient } = useClientContext('BreadcrumbListItem');

const hasChildren = computed(() => {
    return props.data.children?.length;
});

/**
 * Is the current Breadcrumb Item the current route?
 */
const isActive = computed(() => {
    if (!props.data.to) return false;

    const resolved = router?.resolve(props.data.to);
    const resolvedName = resolved?.route.name;
    const currentRouteName = route.value?.name;

    return resolvedName === currentRouteName;
});

/**
 * The `isDisabled` computed property is used to determine if the item should
 * be disabled. An item has the following conditions for being disabled:
 * 1. If `disabled` is set on the item, always disable;
 * 2. If the item has children, never disable;
 * 3. If the item is the current route, and `isDisabled === false`,
 *    enable the ability to click
 */
const isDisabled = computed(() => {
    const isDisabled = props.data.disabled;

    if (isDisabled === false || hasChildren.value) return false;
    if (isDisabled || isActive.value) return true;

    return false;
});

const className = computed(() => {
    return {
        'breadcrumbs-list-item': true,
        'breadcrumbs-list-item--active': isActive.value,
    };
});

function triggerIframeEvent(route: IFrameNavigationPathNames) {
    const { currentTeam } = useXavierGlobals();

    if (currentClient?.value != null) {
        dispatchIframeEvent({
            action: PrepareSendMessageEvents.NAVIGATE,
            payload: {
                clientCrn: currentClient?.value?.rbExternalId.toString(),
                pathName: route,
                practiceCrn: currentTeam?.rbExternalId.toString(),
            },
        });
    } else {
        dispatchIframeEvent({
            action: PrepareSendMessageEvents.NAVIGATE,
            payload: {
                pathName: route,
                practiceCrn: currentTeam?.rbExternalId.toString(),
            },
        });
    }
}
</script>

<script lang="ts">
export default { name: 'BreadcrumbsListItem' };
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.breadcrumbs-list-item {
    align-items: center;
    display: flex;
}
</style>
