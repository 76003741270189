import { ClientOrganisationType } from '@/enums/ClientOrganisationType';

import type { BasicClient, Client } from '@/store/modules/client/types/Client';
import type { ClientState } from '@/store/modules/client/types/Store';

const currentUserId = window.Xavier.userId;

/**
 * Make sure a client is actually a client. We can have minimal data stored for
 * a client upon registration, so we have to make sure that we can access client
 * data properties.
 *
 * @param Client
 * @link https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
 * @returns Boolean
 */
export default function isClient(client?: Client | BasicClient): client is Client {
    return Boolean(client);
}

/**
 * Determine whether the passed client conforms to the Client interface, or the
 * ClientState interface.
 *
 * @param client
 * @returns Boolean
 */
export function isClientState(client: Client | ClientState): client is ClientState {
    return 'meta' in client;
}

/**
 * Get all clients for the current user.
 *
 * Some clients may not be available to the current user based on the client
 * visibility settings, so we must make sure that clients are only visible to
 * those users that are allowed to see them.
 *
 * @param clientList
 * @returns Client[]
 */
export function getCurrentUserClients(clientList: Client[]): Client[] {
    return clientList.filter((client) => {
        return client.userId === currentUserId || client.secondUserId == currentUserId;
    });
}

/**
 * Get all favourited clients.
 *
 * Find all clients that the user has selected to be a "favourite".
 *
 * @param clientList
 * @returns Client[]
 */
export function getFavouritedClients(clientList: Client[]): Client[] {
    return clientList.filter((client) => {
        return client.hasFlagged;
    });
}

export function isSoleTraderOrPartnership(client: Client): boolean {
    return client.organisationType === 'SOLE_TRADER' || client.organisationType === 'PARTNERSHIP';
}
