import { MutationTree } from 'vuex';
import { TotalTransactionsState, WidgetResultsState } from '@/store/modules/insightsDashboard/totalTransactions/types';

export enum TotalTransactionsMutationsEnum {
    SET_TOTAL_TRANSACTIONS_WIDGET_LOADING = 'SET_TOTAL_TRANSACTIONS_WIDGET_LOADING',
    SET_TOTAL_TRANSACTIONS_WIDGET_RESULTS_WIDGET_RESULTS = 'SET_TOTAL_TRANSACTIONS_WIDGET_RESULTS_WIDGET_RESULTS',
    SET_TOTAL_TRANSACTIONS_DETAILS_LOADING = 'SET_TOTAL_TRANSACTIONS_DETAILS_LOADING',
    SET_TOTAL_TRANSACTIONS_DETAILS_RESULTS = 'SET_TOTAL_TRANSACTIONS_DETAILS_RESULTS',
}

export type TotalTransactionsMutations<State = TotalTransactionsState> = {
    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_LOADING](state: State, isLoading: boolean): void;
    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_RESULTS_WIDGET_RESULTS](
        state: State,
        results: WidgetResultsState
    ): void;

    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_LOADING](state: State, isLoading: boolean): void;

    // todo: describe the type of this
    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_RESULTS](state: State, results: {}[]): void;
};

export const mutations: MutationTree<TotalTransactionsState> & TotalTransactionsMutations = {
    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_LOADING](state, isLoading) {
        state.totalTransactions.widget.isLoading = isLoading;
    },

    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_RESULTS_WIDGET_RESULTS](state, results) {
        state.totalTransactions.widget.results = results;
    },

    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_LOADING](state, isLoading) {
        state.totalTransactions.details.isLoading = isLoading;
    },

    [TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_RESULTS](state, results) {
        state.totalTransactions.details.results = results;
    },
};
