<template>
    <input v-bind="$attrs" class="input--fuse-search" type="text" v-on="listeners" v-model="searchTerm" />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import Fuse from 'fuse.js';
import * as PropTypes from 'vue-types';

export default defineComponent({
    name: 'FuseSearch',

    emits: ['input', 'results'],

    props: {
        /**
         * Options to pass down to the underlying Fuse library.
         *
         * @property options
         * @link https://fusejs.io/api/options.html
         */
        options: PropTypes.object<Fuse.IFuseOptions<unknown>>().def({}),

        /**
         * Define the keys we should search against. Must be at least one item.
         *
         * @property searchKeys
         */
        searchKeys: PropTypes.array<Fuse.FuseOptionKeyObject>().isRequired,

        /**
         * List of items to search against.
         *
         * @property list
         * @link https://fusejs.io/examples.html#search-string-array
         */
        list: PropTypes.array<Record<string, unknown> | string[]>().def([]),

        /**
         * Use for v-model binding.
         *
         * @property value
         */
        value: PropTypes.string(),
    },

    setup(props, context) {
        const fuseInstance = ref<Fuse<unknown>>();
        const results = ref<unknown[]>([]);
        const searchTerm = ref('');

        /**
         * Overwrite the default input event with our own custom one.
         *
         * Using using `v-model` _and_ binding `v-on="$listeners"` would cause
         * a conflict with the `input` event.
         *
         * @returns {Object}
         * @link https://github.com/vuejs/vuejs.org/issues/1272
         * @link https://codesandbox.io/s/o29j95wx9?file=/components/BaseInputText.vue
         */
        const listeners = computed(() => {
            return {
                ...context.listeners,
                input: (event: { target: HTMLInputElement }) => context.emit('input', event.target.value),
            };
        });

        watch(searchTerm, (value) => {
            if (!fuseInstance.value) return;

            const searchResults = fuseInstance.value.search(value).map((result) => result.item);

            results.value = searchResults;
            context.emit('results', searchResults);
        });

        watch(
            () => props.list,
            (value) => {
                if (!fuseInstance.value) return;
                fuseInstance.value.setCollection(value);
            },
            { deep: true }
        );

        watch(
            () => props.value,
            (value) => {
                searchTerm.value = value ?? '';
            }
        );

        return {
            fuseInstance,
            listeners,
            results,
            searchTerm,
        };
    },

    mounted() {
        const options = {
            ...this.options,
            keys: [...(this.options?.keys ?? []), ...this.searchKeys],
        };

        this.fuseInstance = new Fuse<unknown>(this.list, options);
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.input--fuse-search {
    background-color: $color-white !important;
    border: 2px solid get-color(silver) !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    color: get-color(charcoal, lite) !important;
    font-family: $typeface-roboto !important;
    font-size: pxtorem(13) !important;
    font-weight: $font-weight-regular !important;
    height: auto !important;
    line-height: 1.5 !important;
    max-height: 32px !important;
    padding: 5px 8px 4px !important; /* [1] */
    transition: border-color 0.15s ease;

    @include input-placeholder {
        color: get-color(gray, lite) !important;
    }

    &:focus,
    &.focus {
        border-color: get-color(gray, medium) !important;
    }

    &:disabled,
    &.disabled {
        background-color: get-color(silver, medium) !important;
    }

    &.is-invalid {
        border-color: get-color(red, medium) !important;
    }
}
</style>
