const namespace = 'components.date_picker.';

const translations = {
    today: `${namespace}today`,
    this_week: `${namespace}this_week`,
    this_month: `${namespace}this_month`,
    last_month: `${namespace}last_month`,
    last_30_days: `${namespace}last_30_days`,
    last_3_months: `${namespace}last_3_months`,
    last_6_months: `${namespace}last_6_months`,
    this_year: `${namespace}this_year`,
    last_year: `${namespace}last_year`,
};

export { translations };
