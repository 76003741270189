<template>
    <MenuItems v-bind="$attrs">
        <template #prepend>
            <slot name="prepend" />

            <MenuSection horizontal v-if="items.length >= inputVisibilityThreshold">
                <FuseSearch
                    :list="items"
                    :options="options"
                    ref="inputRef"
                    :search-keys="searchKeys"
                    @click.prevent.stop
                    @results="onResults"
                    v-model="searchTerm"
                />
            </MenuSection>
        </template>

        <MenuSection>
            <div class="item-container" :key="item.id" v-for="(item, index) in filteredItems">
                <slot :index="index" :item="item" name="item" />
            </div>
        </MenuSection>
    </MenuItems>
</template>

<script lang="ts">
import { ComponentPublicInstance, computed, defineComponent, nextTick, ref, useSlots, watch } from 'vue';
import PropTypes from 'vue-types';
import omit from 'lodash-es/omit';

import FuseSearch from '@/components/FuseSearch';
import injectStrict from '@/hooks/injectStrict';
import { focusElement } from '@/utils/dom';

import MenuItems from './MenuItems.vue';
import MenuSection from '../MenuSection.vue';
import useHasSlot from '@/hooks/useHasSlot';
import MenuStates from '../states/MenuStates';
import { MenuContextApi } from '../types';

interface Item {
    id: string | number;
    [key: string]: string | number | null | undefined;
}

export default defineComponent({
    name: 'FilterableMenuItems',

    components: { FuseSearch, MenuItems, MenuSection },

    props: {
        /**
         * Hide the input until a certain number of items has been provided to
         * the component. It doesn't make sense to filter on a small number of
         * items, so we hide the input until a threshold is met.
         *
         * @param {Number} [inputVisibilityThreshold=10]
         */
        inputVisibilityThreshold: PropTypes.number.def(10),

        /**
         * Array of items to use in the list. Will be the list used to filter
         * by search user input.
         *
         * @param {Array<{id: Number|String}, [string]: any>} items
         */
        items: PropTypes.arrayOf(
            PropTypes.shape<Item>({
                id: PropTypes.oneOfType([Number, String]).isRequired,
            }).loose
        ).isRequired,

        /**
         * Any options to configure FuseJS.
         *
         * @param {FuseOptions} options
         * @link https://fusejs.io/api/options.html
         */
        options: PropTypes.object,

        /**
         * Array of keys to search data against. These keys should match keys
         * in the `items` prop.
         */
        searchKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    },

    setup(props) {
        const menuContext = injectStrict<MenuContextApi>('MenuContext');
        const inputRef = ref<ComponentPublicInstance<HTMLInputElement>>();
        const results = ref<Item[]>(props.items);
        const searchTerm = ref('');
        const filteredItems = ref(props.items);
        const slots = useSlots();
        const hasPrependSlot = useHasSlot('prepend');

        /**
         * Watch for changes to the menu visibility. If the menu becomes visible
         * then we should focus the input element automatically to allow the
         * user to start searching as quick as possible.
         */
        watch(
            menuContext.menuState,
            (value) => {
                if (value === MenuStates.OPEN && Boolean(inputRef.value)) {
                    setTimeout(() => focusElement(inputRef.value?.$el), 10);
                }
            },
            { immediate: true }
        );

        /**
         * Watch for changes to the `searchTerm`. If we have something to search
         * on, filter the items. If the search term string is empty restore the
         * original list of items.
         */
        watch(searchTerm, async (value) => {
            if (!value.length) {
                filteredItems.value = [];
                await nextTick();
            }

            filteredItems.value = value.length ? results.value : props.items;
        });

        /**
         * Watch for changes to the items prop and reset the filtered items and
         * search term.
         */
        watch(
            () => props.items,
            (value) => {
                filteredItems.value = value;
                searchTerm.value = '';
            }
        );

        return {
            filteredItems: computed(() => filteredItems.value),
            hasPrependSlot,
            inputRef,
            onResults: (searchResults: Item[]) => (results.value = searchResults),
            reset: () => (results.value = props.items),
            results,
            searchTerm,
            slots: omit(slots, ['item']),
        };
    },
});
</script>

<style lang="scss" scoped>
.item-container {
    width: 100%;
}
</style>
