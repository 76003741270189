import axios from 'axios';
import Vue from 'vue';

import * as types from './types';

// this store uses only spark routes
export default {
    namespaced: true,
    state: {
        invitations: [],
        resentInvitations: [],
        errors: [],
    },
    getters: {
        invitations: (state) => state.invitations,
        resentInvitations: (state) => state.resentInvitations,
        errors: (state) => state.errors,
    },
    actions: {
        async getInvitations({ commit }, teamId) {
            const { data } = await axios.get(`/settings/teams/${teamId}/invitations`);

            commit(types.INVITATIONS_LOADED, data);
        },
        async cancelInvitation({ commit }, invitation) {
            await axios.delete(`/settings/invitations/${invitation.team_id}/${invitation.id}`);

            commit(types.INVITATION_DELETED, invitation);
        },
        async resendInvitation({ dispatch, commit }, invitation) {
            commit(types.RESENDING_INVITATION, invitation);

            await axios.delete(`/settings/invitations/${invitation.team_id}/${invitation.id}`);

            await axios.post(`/settings/teams/${invitation.team_id}/invitations`, {
                email: invitation.email,
                role: invitation.role,
            });

            commit(types.INVITATION_RESENT, invitation);

            await dispatch('getInvitations', invitation.team_id);
        },
        async sendInvitation({ dispatch, commit }, invitation) {
            try {
                await axios.post(`/settings/teams/${invitation.teamId}/invitations`, invitation);

                commit(types.INVITATION_SENT, invitation);

                dispatch('getInvitations', invitation.teamId);
            } catch (ex) {
                const error = ex.response.data.errors.email ? ex.response.data.errors.email[0] : null;

                commit(types.LOG_ERRORS, error || ['An error occurred']);

                throw ex;
            }
        },
        async updateErrors({ commit }, error) {
            commit(types.LOG_ERRORS, error);
        },
    },
    mutations: {
        [types.INVITATION_SENT](state, invite) {
            state.invitations.push(invite);
            state.errors = [];
        },
        [types.INVITATIONS_LOADED](state, invites) {
            Vue.set(state, 'invitations', invites);
        },
        [types.RESENDING_INVITATION](state, invite) {
            const inv = state.invitations.find((inv) => inv.id === invite.id);

            Vue.set(inv, 'resending', true);
        },
        [types.INVITATION_RESENT](state, invite) {
            state.resentInvitations.push(invite.email);

            Vue.set(invite, 'resending', false);
        },
        [types.INVITATION_DELETED](state, invite) {
            const invIndex = state.invitations.findIndex((inv) => inv.email === invite.email);

            Vue.delete(state.invitations, invIndex);
        },
        [types.LOG_ERRORS](state, error) {
            state.errors = [];

            if (error) {
                state.errors.push(error);
            }
        },
    },
};
