import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';

const SET_SETTING = 'SET_SETTING';
const SET_FEATURE_VISIBILITY = 'SET_FEATURE_VISIBILITY';

export default {
    namespaced: true,

    state: {
        entries: {},
        featureVisibilities: {},
    },

    getters: {
        get: (state) => (key) => state.entries[key],

        getFeatureVisibility: (state) => (clientId, feature) => {
            if (state.featureVisibilities[clientId] === undefined) {
                return undefined;
            } else if (state.featureVisibilities[clientId][feature] === undefined) {
                return undefined;
            } else {
                return state.featureVisibilities[clientId][feature];
            }
        },
    },

    actions: {
        set({ commit }, params) {
            commit(SET_SETTING, params);
        },

        loadFeatureVisibility({ commit }, { client, thenCallback, catchCallback, finallyCallback }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios
                .get(`/api/teams/${practiceCrn}/client/${client.slug}/feature-visibility`)
                .then((response) => {
                    commit(SET_FEATURE_VISIBILITY, { client, data: response.data.data });

                    thenCallback && thenCallback();
                })
                .catch((e) => catchCallback && catchCallback(e))
                .finally(() => finallyCallback && finallyCallback());
        },

        saveFeatureVisibility(
            { commit },
            { client, feature, visibility, thenCallback, catchCallback, finallyCallback }
        ) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios
                .post(`/api/teams/${practiceCrn}/client/${client.slug}/feature-visibility`, {
                    feature,
                    visibility,
                })
                .then((response) => {
                    commit(SET_FEATURE_VISIBILITY, { client, data: response.data.data });

                    thenCallback && thenCallback();
                })
                .catch((e) => catchCallback && catchCallback(e))
                .finally(() => finallyCallback && finallyCallback());
        },

        grantUserAccessToFeature(
            { commit },
            { client, feature, userId, thenCallback, catchCallback, finallyCallback }
        ) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios
                .post(`/api/teams/${practiceCrn}/client/${client.slug}/feature-visibility/user`, {
                    user_id: userId,
                    feature,
                })
                .then((response) => {
                    commit(SET_FEATURE_VISIBILITY, { client, data: response.data.data });

                    thenCallback && thenCallback();
                })
                .catch((e) => catchCallback && catchCallback(e))
                .finally(() => finallyCallback && finallyCallback());
        },

        revokeUserAccessToFeature(
            { commit },
            { client, feature, userId, thenCallback, catchCallback, finallyCallback }
        ) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            axios
                .delete(`/api/teams/${practiceCrn}/client/${client.slug}/feature-visibility/user/${userId}/${feature}`)
                .then((response) => {
                    commit(SET_FEATURE_VISIBILITY, { client, data: response.data.data });

                    thenCallback && thenCallback();
                })
                .catch((e) => catchCallback && catchCallback(e))
                .finally(() => finallyCallback && finallyCallback());
        },
    },

    mutations: {
        [SET_SETTING](state, { key, value }) {
            Vue.set(state.entries, key, value);
        },

        [SET_FEATURE_VISIBILITY](state, { client, data }) {
            Vue.set(state, 'featureVisibilities', {
                [client.id]: data,
            });
        },
    },
};
