import moment from 'moment';
import isApiError from './isApiError';
import isString from 'lodash-es/isString';

type Error = null | {
    code?: string | number;
    details?: unknown;
    message?: string;
    statusText?: string;
};

export enum StatusStates {
    NEUTRAL,
    IN_PROGRESS,
    IS_FAILURE,
    IS_SUCCESS,
}

export interface Meta {
    status: StatusStates;
    lastSuccessTimestamp: string | null;
    error: Error;
}

export type MetaStore = { meta: Meta };

const initialState: Meta = {
    status: StatusStates.NEUTRAL,
    lastSuccessTimestamp: null,
    error: null,
};

/**
 * Format any errors into a consistent and usable state.
 *
 * @param error
 * @returns Error
 */
function formatError(error: unknown): Error {
    if (isString(error)) {
        return { message: error as string };
    }

    if (isApiError(error)) {
        switch (error.response?.status) {
            case 500:
                return {
                    code: error.response.status,
                    details: error.response?.data,
                    message: 'A server error occurred.',
                    statusText: error.response?.statusText,
                };
        }
    }

    return { message: 'An unknown error occurred.' };
}

/**
 * Clear any existing errors.
 *
 * @param state
 */
function clearError<State extends MetaStore>(state: State) {
    state.meta = {
        ...state.meta,
        error: null,
    };
}

/**
 * Get the initial status state.
 *
 * @returns MetaStore
 */
function getInitialState(): MetaStore {
    return { meta: { ...initialState } };
}

/**
 * Reset the current state back to the initial state.
 *
 * @param state
 */
function reset<State extends MetaStore>(state: State) {
    state.meta = {
        ...initialState,
        lastSuccessTimestamp: state.meta.lastSuccessTimestamp,
    };
}

/**
 * Set a formatted error on the state.
 *
 * @param state
 * @param error
 */
function setError<State extends MetaStore>(state: State, error: string | unknown) {
    state.meta = {
        ...state.meta,
        status: StatusStates.IS_FAILURE,
        lastSuccessTimestamp: state.meta.lastSuccessTimestamp,
        error: formatError(error),
    };
}

/**
 * Set success status.
 *
 * @param state
 * @param value
 */
function setSuccess<State extends MetaStore>(state: State, value = true) {
    state.meta = {
        ...state.meta,
        status: value ? StatusStates.IS_SUCCESS : StatusStates.NEUTRAL,
        lastSuccessTimestamp: moment().toISOString(),
    };
}

/**
 * Set inProgress status to true.
 *
 * @param state
 */
function startProgress<State extends MetaStore>(state: State) {
    reset(state);

    state.meta = {
        ...state.meta,
        status: StatusStates.IN_PROGRESS,
    };
}

/**
 * Set inProgress status to false.
 *
 * @param state
 */
function stopProgress<State extends MetaStore>(state: State) {
    state.meta = {
        ...state.meta,
        status: StatusStates.NEUTRAL,
    };
}

/**
 * Does an error exist in the state.
 *
 * @param state
 * @returns Boolean
 */
function hasError<State extends MetaStore>(state: State) {
    return Boolean(state.meta.error);
}

/**
 * Is the current status inProgress.
 *
 * @param state
 * @returns
 */
function isInProgress<State extends MetaStore>(state: State) {
    return state.meta.status === StatusStates.IN_PROGRESS;
}

function isComplete<State extends MetaStore>(state: State) {
    return state.meta.status === StatusStates.IS_FAILURE || state.meta.status === StatusStates.IS_SUCCESS;
}

export default function createStatus() {
    return {
        clearError,
        getInitialState,
        hasError,
        isComplete,
        isInProgress,
        reset,
        setError,
        setSuccess,
        startProgress,
        stopProgress,
    };
}
