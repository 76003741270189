<template>
    <VButton
        :class="[isDisabled ? 'disabled' : '', 'provider-button']"
        :disabled="isDisabled"
        :name="`connect-to-${providerName}`"
        text
        @click="$emit('click', { provider })"
    >
        <img :alt="`${providerName} Icon`" class="provider-icon" :src="`/img/connect/${logo}`" />
    </VButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        provider: { type: String, required: true },
        providerName: { type: String, required: true },
        logo: { type: String, required: true },
        isDisabled: { type: Boolean, required: false, default: false },
    },
});
</script>

<style lang="scss" scoped>
.provider-button {
    padding-top: 0 !important;
    & + & {
        /*
        * margin-left important added to overwrite
        * button default resources/js/components/Button/Button.vue
        */
        margin-left: 0px !important;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
