import { Fragment } from 'vue-frag';
import PropTypes from 'vue-types';

export default {
    name: 'FrameOptionalWrapper',
    props: {
        condition: PropTypes.bool.def(false).isRequired,
        component: PropTypes.oneOfType([String, Function, Object]).isRequired,
    },
    render(createElement) {
        if (!this.condition) {
            return createElement(Fragment, null, this.$slots.default);
        }

        return createElement(
            this.component,
            {
                ...this.$attrs,
                ...this.$props,
            },
            this.$slots.default
        );
    },
};
