<template>
    <Component
        :is="componentTag"
        v-bind="attrs"
        v-on="disabled ? {} : $listeners"
        v-open-in-new-tab="shouldOpenInNewTab"
    >
        <slot />
    </Component>
</template>

<script lang="ts">
import * as PropTypes from 'vue-types';
import { computed, defineComponent } from 'vue';

import type { Props } from './types';

export default defineComponent<Props>({
    name: 'InteractiveElement',

    props: {
        /**
         * Set the link to disabled. This will re-render the component as a
         * `span` HTMLElement.
         */
        disabled: PropTypes.bool().def(false),

        /**
         * Href to external website page. Required if `$props.to` is not set.
         */
        href: PropTypes.string(),

        /**
         * Should the link open in a new tab.
         */
        newTab: PropTypes.bool().def(false),

        /**
         * Path to internal page. Required if `$props.href` is not set.
         *
         * @param {Object|String} to
         */
        to: PropTypes.oneOfType([Object, String]),

        type: PropTypes.string<'button' | 'reset' | 'submit'>().def('button'),
    },

    setup(props, context) {
        const isAnchorElement = computed(() => Boolean(props.href));
        const isRouterElement = computed(() => Boolean(props.to));
        const isButtonElement = computed(() => !isAnchorElement.value && !isRouterElement.value);
        const isDivElement = computed(() => (isAnchorElement.value || isRouterElement.value) && props.disabled);
        const shouldOpenInNewTab = computed(() => (isAnchorElement.value || isRouterElement.value) && props.newTab);

        const componentTag = computed(() => {
            if (isDivElement.value) return 'div';
            if (isAnchorElement.value) return 'a';
            if (isRouterElement.value) return 'RouterLink';
            if (isButtonElement.value) return 'button';

            return 'div';
        });

        const attrs = computed(() => {
            if (isAnchorElement.value) return { ...context.attrs, href: props.href };
            if (isRouterElement.value) return { ...context.attrs, to: props.to };
            if (isButtonElement.value) return { ...context.attrs, disabled: props.disabled, type: 'button' };

            return context.attrs;
        });

        return {
            attrs,
            componentTag,
            shouldOpenInNewTab,
        };
    },
});
</script>
