import BaseApiService from './BaseApiService';

/**
 * For use in any place that needs to make a request to insights dashboard data
 */
class InsightsDashboardService extends BaseApiService {
    /**
     * Get hidden integrated clients count
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getHiddenIntegratedClientsCount(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.user.get-hidden-integrated-clients-count', routeParams);
    }

    /**
     * Get hidden integrated clients count
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getHiddenClientsCount(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.user.get-hidden-clients-count', routeParams);
    }

    /**
     * Get average score widget data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getAverageHealthScore(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.get-average-health-score', routeParams);
    }

    /**
     * Get average score widget details data
     *
     * @param {String} practiceCrn
     * @param searchParams
     * @returns Promise
     */
    getAverageHealthScoreDetails(practiceCrn: string, searchParams: URLSearchParams) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.get-average-health-score.details', routeParams, searchParams);
    }

    /**
     * Get director loan accounts widget data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getDirectorLoanAccounts(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.directors-loan-accounts-widget', routeParams);
    }

    /**
     * Get director loan accounts widget data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getDirectorLoanAccountsDetails(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.directors-loan-accounts-details', routeParams);
    }

    /**
     * Get flows due data
     *
     * @param {String} practiceCrn
     * @param searchParams
     * @returns Promise
     */
    getFlowsDue(practiceCrn: string, searchParams: URLSearchParams) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.get-flows-due', routeParams, searchParams);
    }

    /**
     * Get lock date activity data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getLockDateActivity(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.lock-date-activity-widget', routeParams);
    }

    /**
     * Get reconciliation activity data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getReconciliationActivity(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.reconciliation-activity-widget', routeParams);
    }

    /**
     * Get reconciliation activity details data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getReconciliationActivityDetails(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.reconciliation-activity.details', routeParams);
    }

    /**
     * Get total transactions data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getTotalTransactions(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.total-transactions-widget', routeParams);
    }

    /**
     * Get total transactions details data
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    getTotalTransactionsDetails(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.insights-dashboard.total-transactions-details', routeParams);
    }
}

export default Object.freeze(new InsightsDashboardService());
