<template>
    <div :class="className">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BadgeCount',

    inject: {
        badgeVariant: {
            default: null,
            from: 'variant',
        },
    },

    props: {
        dark: {
            default: false,
            required: false,
            type: Boolean,
        },

        variant: {
            default: 'neutral',
            required: false,
            type: String,
            validator(value) {
                const validVariants = ['error', 'neutral', 'processing', 'success', 'warning'];
                const isValid = validVariants.includes(value);

                if (!isValid) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `[Vue warn]: Invalid prop: invalid variant of "${value}" provided for prop "variant".`
                    );
                }

                return isValid;
            },
        },
    },

    computed: {
        className() {
            const variant = this.badgeVariant || this.variant;

            return {
                'badge-count': true,
                'badge-count--dark': Boolean(this.badgeVariant),
                [`badge-count--${variant}`]: Boolean(variant),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

$variants: (
    error: (
        regular: get-color(red),
        dark: get-color(red, dark),
    ),
    neutral: (
        regular: get-color(gray, medium),
        dark: get-color(gray, medium),
    ),
    processing: (
        regular: get-color(blue),
        dark: get-color(blue, extra-dark),
    ),
    success: (
        regular: get-color(green),
        dark: get-color(green, extra-dark),
    ),
    warning: (
        regular: get-color(amber),
        dark: get-color(amber, extra-dark),
    ),
);

.badge-count {
    border-radius: 4px;
    color: $color-white;
    line-height: 1.23;
    padding: 1px 5px;

    .d-badge--with-count & {
        margin-left: 4px;
    }
}

@each $variant-name, $config in $variants {
    .badge-count--#{$variant-name} {
        background-color: map-get($config, regular);
    }

    .badge-count--#{$variant-name}.badge-count--dark {
        background-color: map-get($config, dark);
    }
}
</style>
