import { Module } from 'vuex';
import { TotalTransactionsState } from '@/store/modules/insightsDashboard/totalTransactions/types';

import { getters } from '@/store/modules/insightsDashboard/totalTransactions/getters';
import { mutations } from '@/store/modules/insightsDashboard/totalTransactions/mutations';
import { actions } from '@/store/modules/insightsDashboard/totalTransactions/actions';
import { RootState } from '@/store/types';

const state: TotalTransactionsState = {
    totalTransactions: {
        widget: {
            isLoading: false,
            results: {
                bills: {},
                sales: {},
                manual_journals: {},
                credits: {},
                bank_transactions: {},
            },
        },
        details: {
            isLoading: false,
            // todo: describe the type of this
            results: [],
        },
    },
};

const TotalTransactionsModule: Module<TotalTransactionsState, RootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default TotalTransactionsModule;
