export const TeamNavigationPathNames = {
    ACTIVITY_DASHBOARD: 'ACTIVITY_DASHBOARD',
    API_TOKENS: 'API_TOKENS',
    CUSTOM_DASHBOARD_DETAILS: 'CUSTOM_DASHBOARD_DETAILS',
    CUSTOM_DASHBOARDS: 'CUSTOM_DASHBOARDS',
    DEADLINES_DASHBOARD: 'DEADLINES_DASHBOARD',
    EDIT_WORKFLOW: 'EDIT_WORKFLOW',
    HEALTH_SCORE: 'HEALTH_SCORE',
    INSIGHTS_DASHBOARD: 'INSIGHTS_DASHBOARD',
    LOCK_DATES: 'LOCK_DATES',
    MTD_DASHBOARD: 'MTD_DASHBOARD',
    NEW_WORKFLOW: 'NEW_WORKFLOW',
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
    PRACTICE_DASHBOARD: 'PRACTICE_DASHBOARD',
    PRACTICE_INTEGRATIONS: 'PRACTICE_INTEGRATIONS',
    REPEATING_WORKFLOWS: 'REPEATING_WORKFLOWS',
    TAGS: 'TAGS',
    WORKFLOW_CHECKS: 'WORKFLOW_CHECKS',
    WORKFLOW_DASHBOARD: 'WORKFLOW_DASHBOARD',
    WORKFLOW_DRAFTS: 'WORKFLOW_DRAFTS',
    WORKFLOW_TEMPLATES: 'WORKFLOW_TEMPLATES',
    WORKFLOWS: 'WORKFLOWS',
} as const;

export const ClientNavigationPathNames = {
    CLIENT_ABN_VALIDATION: 'CLIENT_ABN_VALIDATION',
    CLIENT_ACTIVITY_STATS: 'CLIENT_ACTIVITY_STATS',
    CLIENT_AGED_BALANCES: 'CLIENT_AGED_BALANCES',
    CLIENT_BANK_RECONCILIATION: 'CLIENT_BANK_RECONCILIATION',
    CLIENT_BENCHMARKING: 'CLIENT_BENCHMARKING',
    CLIENT_CONNECTION_SETTINGS: 'CLIENT_CONNECTION_SETTINGS',
    CLIENT_CONTACT_DETAILS: 'CLIENT_CONTACT_DETAILS',
    CLIENT_CONTROL_ACCOUNTS: 'CLIENT_CONTROL_ACCOUNTS',
    CLIENT_CORPORATION_TAX: 'CLIENT_CORPORATION_TAX',
    CLIENT_COST_ANALYSIS: 'CLIENT_COST_ANALYSIS',
    CLIENT_CUSTOM_REPORT_DETAILS: 'CLIENT_CUSTOM_REPORT_DETAILS',
    CLIENT_CUSTOM_REPORTS: 'CLIENT_CUSTOM_REPORTS',
    CLIENT_DORMANT_ACCOUNTS: 'CLIENT_DORMANT_ACCOUNTS',
    CLIENT_DUPLICATE_CONTACTS: 'CLIENT_DUPLICATE_CONTACTS',
    CLIENT_DUPLICATE_TRANSACTIONS: 'CLIENT_DUPLICATE_TRANSACTIONS',
    CLIENT_ENTERTAINMENT: 'CLIENT_ENTERTAINMENT',
    CLIENT_FIXED_ASSETS: 'CLIENT_FIXED_ASSETS',
    CLIENT_HEALTH_SCORE_DETAILS: 'CLIENT_HEALTH_SCORE_DETAILS',
    CLIENT_HISTORICAL_ACTIVITY: 'CLIENT_HISTORICAL_ACTIVITY',
    CLIENT_LOCK_DATE_HISTORY: 'CLIENT_LOCK_DATE_HISTORY',
    CLIENT_METRICS: 'CLIENT_METRICS',
    CLIENT_MULTI_CODED_CONTACTS: 'CLIENT_MULTI_CODED_CONTACTS',
    CLIENT_OVERVIEW: 'CLIENT_OVERVIEW',
    CLIENT_REPORT_CREATOR: 'CLIENT_REPORT_CREATOR',
    CLIENT_SALES_ANALYSIS: 'CLIENT_SALES_ANALYSIS',
    CLIENT_UNRECONCILED_TRANSACTIONS: 'CLIENT_UNRECONCILED_TRANSACTIONS',
    CLIENT_VAT_RETURN_CHECKS: 'CLIENT_VAT_RETURN_CHECKS',
    CLIENT_WORKFLOW_DETAILS: 'CLIENT_WORKFLOW_DETAILS',
    CLIENT_WORKFLOWS: 'CLIENT_WORKFLOWS',
} as const;

export type TeamNavigationPathNames = (typeof TeamNavigationPathNames)[keyof typeof TeamNavigationPathNames];
export type ClientNavigationPathNames = (typeof ClientNavigationPathNames)[keyof typeof ClientNavigationPathNames];

export type IFrameNavigationPathNames = TeamNavigationPathNames | ClientNavigationPathNames;
