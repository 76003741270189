import axios from 'axios';

import useXavierGlobals from '@/hooks/useXavierGlobals';

const SANDBOX_LOADED = 'SANDBOX_LOADED';
const SANDBOX_CLEARED = 'SANDBOX_CLEARED';
const SANDBOX_ERRORED = 'SANDBOX_ERRORED';
const LOADING_STARTED = 'LOADING_STARTED';

export default {
    namespaced: true,
    state() {
        return {
            data: null,
            loading: false,
            startDate: null,
            endDate: null,
            error: null,
        };
    },
    getters: {
        data: (state) => state.data,
        loading: (state) => state.loading,
        ready: (state) => state.data !== null && state.startDate !== null && state.endDate !== null && !state.loading,
        startDate: (state) => state.startDate,
        endDate: (state) => state.endDate,
    },
    actions: {
        async loadSandbox({ commit }, { clientId, startDate, endDate }) {
            commit(LOADING_STARTED);

            if (!startDate || !endDate) {
                startDate = moment().startOf('year').format('YYYY-MM-DD');
                endDate = moment().endOf('year').format('YYYY-MM-DD');
            }

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const sandboxUrl = `/api/teams/${practiceCrn}/client/${clientId}/sandbox?start=${startDate}&end=${endDate}`;

            try {
                const { data } = await axios.get(sandboxUrl);

                commit(SANDBOX_LOADED, data, startDate, endDate);
            } catch (error) {
                commit(SANDBOX_ERRORED, { error });
            }
        },
        clearSandbox({ commit }) {
            commit(SANDBOX_CLEARED);
        },
    },
    mutations: {
        [LOADING_STARTED](state) {
            state.loading = true;
        },
        [SANDBOX_LOADED](state, data, startDate, endDate) {
            state.data = Object.freeze(data);
            state.loading = false;
            state.startDate = startDate;
            state.endDate = endDate;
        },
        [SANDBOX_CLEARED](state) {
            state.data = null;
        },
        [SANDBOX_ERRORED](state, payload) {
            state.loading = false;
            state.error = payload.error;
        },
    },
};
