<template>
    <div :class="['d-card', { 'd-card-no-padding': noPadding }]" v-bind="$attrs" v-on="$listeners">
        <div v-if="renderHeader" class="d-card-header">
            <div v-if="slots.prepend" class="d-card-header-prepend">
                <slot name="prepend" />
            </div>

            <div class="d-card-header-content">
                <div v-if="slots.title || title" class="d-card-title">
                    <slot name="title">
                        {{ title }}
                    </slot>
                </div>

                <div v-if="slots.subtitle || subtitle" class="d-card-subtitle">
                    <slot name="subtitle">
                        {{ subtitle }}
                    </slot>
                </div>
            </div>

            <div v-if="slots.append" class="d-card-header-append">
                <slot name="append" />
            </div>
        </div>

        <div class="d-card-content">
            <slot />
        </div>

        <div v-if="slots.actions" class="d-card-actions">
            <slot name="actions" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

type Props = {
    title?: string;
    noPadding?: boolean;
    subtitle?: string;
};

const props = defineProps<Props>();

const slots = useSlots();

const renderHeader = computed(() => {
    return props.title || props.subtitle || slots.prepend || slots.title || slots.subtitle || slots.append;
});
</script>

<script lang="ts">
export default { name: 'DCard' };
</script>

<style scoped>
.d-card-title,
.d-card-subtitle {
    word-break: break-word;
}
</style>
