export const LOADING_STARTED = 'LOADING_STARTED';
export const TEAM_LIST_LOADED = 'TEAM_LIST_LOADED';
export const TEAM_LOADED = 'TEAM_LOADED';
export const TEAM_SELECTED = 'TEAM_SELECTED';
export const CHECKLIST_LOADED = 'CHECKLIST_LOADED';
export const CHECKLIST_ITEM_COMPLETED = 'CHECKLIST_ITEM_COMPLETED';
export const NEW_TEAM_DETECTED = 'NEW_TEAM_DETECTED';
export const INVITATIONS_LOADED = 'INVITATIONS_LOADED';
export const ROLES_LOADED = 'ROLES_LOADED';
export const SUBSCRIPTION_LOADED = 'SUBSCRIPTION_LOADED';
export const SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED';
export const INVOICES_LOADED = 'INVOICES_LOADED';
export const CLIENT_ACTIVITY_LOADED = 'CLIENT_ACTIVITY_LOADED';
export const SUBSCRIPTION_TYPES_LOADED = 'SUBSCRIPTION_TYPES_LOADED';
export const MEMBERSHIP_LIST_LOADED = 'MEMBER_LIST_LOADED';
