export const CLEAR_CLIENT = 'CLEAR_CLIENT';
export const CLEAR_REPORTING_DATE = 'CLEAR_REPORTING_DATE';
export const CLIENT_LOADED = 'CLIENT_LOADED';
export const CLIENT_SELECTED = 'CLIENT_SELECTED';
export const CLIENT_LIST_LOADED = 'CLIENT_LIST_LOADED';
export const LOADING_STARTED = 'LOADING_STARTED';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const CLIENT_USER_LIST_LOADED = 'CLIENT_USER_LIST_LOADED';
export const CLIENT_PROVIDER_USER_LIST_LOADED = 'CLIENT_PROVIDER_USER_LIST_LOADED';
export const SET_CLIENT_LINKED = 'SET_CLIENT_LINKED';
export const DUPLICATE_BANK_TRANSACTIONS = 'DUPLICATE_BANK_TRANSACTIONS';
