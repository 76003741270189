import axios from 'axios';
import Vue from 'vue';

import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import MembershipService from '@/services/Api/MembershipService';
import createLocaleManager from '@/utils/locale';

import * as types from './types';

function augmentTeam(team, localeManager) {
    return {
        ...team,
        ...localeManager.countryConfig(team.countryCode),
        localise: function (content) {
            return localeManager.localiseText(content, team.countryCode);
        },
    };
}

const { currentTeam, teamSetup } = window.Xavier;
const localeManager = createLocaleManager(window.Xavier.countries);

const initialState = () => {
    return {
        newTeamId: undefined,
        currentTeam: currentTeam ? augmentTeam(currentTeam, localeManager) : null,
        teams: {},
        setupProgress: teamSetup,
        roles: [],
        memberships: [],
    };
};

export { augmentTeam };

export default {
    namespaced: true,
    state: initialState,
    getters: {
        currentTeam: (state) => state.currentTeam,
        teamList: (state) => Object.values(state.teams),
        membershipList: (state) => state.memberships,
        setupProgress: ({ setupProgress }) => setupProgress,
        setupPercent: ({ setupProgress }) =>
            setupProgress
                ? Math.round(
                      (Object.values(setupProgress).filter((v) => v).length / Object.keys(setupProgress).length) * 100
                  )
                : 0,
        roles: (state) => state.roles,
        wrongTeam: (state) => !!state.newTeamId && state.currentTeam.id !== state.newTeamId,
        hasMultipleTeams: (state) => Object.keys(state.teams).length > 1,
        countryCollectsVat: (state, getters) => {
            if (!getters.currentTeam) {
                return false;
            }

            return (
                [
                    'BE',
                    'BG',
                    'CZ',
                    'DK',
                    'DE',
                    'EE',
                    'IE',
                    'GR',
                    'ES',
                    'FR',
                    'HR',
                    'IT',
                    'CY',
                    'LV',
                    'LT',
                    'LU',
                    'HU',
                    'MT',
                    'NL',
                    'AT',
                    'PL',
                    'PT',
                    'RO',
                    'SI',
                    'SK',
                    'FI',
                    'SE',
                    'GB',
                ].indexOf(getters.currentTeam.countryCode) >= 0
            );
        },
        captureTaxId: (state, getters) => {
            if (!getters.currentTeam) {
                return false;
            }

            return ['AU', 'NZ', 'SG'].indexOf(getters.currentTeam.countryCode) >= 0;
        },
        knownToPP: (state, getters) => {
            if (!getters.currentTeam) {
                return false;
            }

            return getters.currentTeam.knownToPP;
        },
        products: (state, getters) => {
            if (!getters.currentTeam) {
                return [];
            }

            return getters.currentTeam.products ?? [];
        },
        teamPaymentUrl: (state, getters) => {
            if (!getters.currentTeam) {
                return '';
            }

            const countryUrlMap = {
                AU: 'https://dext.com/au/pricing/precision',
                NZ: 'https://dext.com/au/pricing/precision',
                GB: 'https://dext.com/uk/pricing/precision',
                CA: 'https://dext.com/ca/pricing/precision',
            };

            if (!countryUrlMap.hasOwnProperty(getters.currentTeam.countryCode)) {
                return '';
            }

            return countryUrlMap[getters.currentTeam.countryCode];
        },
        isDextAccountSwitcherEnabled: (state, getters) => {
            return getters.teamList.some((team) => team.enabledFeatures.includes(Feature.DEXT_ACCOUNT_SWITCHER));
        },
        byCRN: (state) => (crn) => {
            return Object.values(state.teams).find((team) => team.rbExternalId === crn);
        },
    },
    actions: {
        async loadTeamList({ commit }) {
            commit(types.LOADING_STARTED);

            const { data } = await axios.get('/api/teams');

            commit(types.TEAM_LIST_LOADED, data.data);
        },
        async selectTeam({ dispatch, commit, state }, id) {
            if (id !== state.currentTeam.id) {
                const team = state.teams[state.currentTeam.id];

                window.location = `/team/${team.slug}`;
            }
        },
        async setNewTeam({ commit, state }, id) {
            if (!state.newTeamId || id !== state.newTeamId) {
                commit(types.NEW_TEAM_DETECTED, id);
            }
        },
        async setTagCreation({ commit }, createAnywhere) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const { data } = await axios.post(`/api/teams/${practiceCrn}/team/tag-creation`, {
                createAnywhere: createAnywhere ? createAnywhere : false,
            });

            commit(types.TEAM_LOADED, data.data);
        },
        async updateTeam({ commit }, team) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const { data } = await axios.post(`/api/teams/${practiceCrn}/team`, team);

            commit(types.TEAM_LOADED, data.data);
        },
        async updateTeamLogo({ commit, state }, file) {
            if (file.size / 1024 / 1024 > 2) {
                return {
                    success: false,
                    error: 'Logo file size cannot exceed 2MB',
                };
            }

            const formData = new FormData();

            formData.append('header', file);

            try {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.post(`/teams/${practiceCrn}/settings/header`, formData);

                commit(types.TEAM_LOADED, data.data);

                return { success: true };
            } catch (ex) {
                const errors = ex.response.data.errors.header ? ex.response.data.errors.header : [];

                return {
                    success: false,
                    error: errors.length ? errors[0] : '',
                };
            }
        },
        async removeTeamLogo({ commit, state }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const { data } = await axios.delete(`/teams/${practiceCrn}/settings/header`);

            commit(types.TEAM_LOADED, data.data);
        },
        async loadChecklistProgress({ commit }) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const { data } = await axios.get(`/api/teams/${practiceCrn}/team/checklist`);

            commit(types.CHECKLIST_LOADED, data);
        },
        async completeChecklistItem({ commit }, item) {
            commit(types.CHECKLIST_ITEM_COMPLETED, item);
        },
        async dismissChecklistItem({ commit }, item) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await axios.post(`/api/teams/${practiceCrn}/team/checklist`, { checklistItem: item });

            commit(types.CHECKLIST_ITEM_COMPLETED, item);
        },
        async getRoles({ commit }) {
            // spark route
            const { data } = await axios.get('/settings/teams/roles');

            commit(types.ROLES_LOADED, data);
        },
        async loadMembershipListList({ commit }) {
            try {
                const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

                MembershipService.getMemberships(practiceCrn).then((response) => {
                    commit(types.MEMBERSHIP_LIST_LOADED, response.data);
                });
            } catch (ex) {}
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.TEAM_LIST_LOADED](state, teamList) {
            for (const team of teamList) {
                Vue.set(state.teams, team.id, {
                    ...state.teams[team.id],
                    ...augmentTeam(team, localeManager),
                });
            }

            for (const id of Object.keys(state.teams)) {
                if (teamList.every((t) => t.id != id)) {
                    Vue.delete(state.teams, id);
                }
            }

            state.loading = false;
        },
        [types.TEAM_LOADED](state, team) {
            Vue.set(state.teams, team.id, {
                ...state.teams[team.id],
                ...augmentTeam(team, localeManager),
            });
        },
        [types.CHECKLIST_LOADED](state, progress) {
            Vue.set(state, 'setupProgress', progress);
        },
        [types.CHECKLIST_ITEM_COMPLETED](state, item) {
            state.setupProgress[item] = true;
        },
        [types.NEW_TEAM_DETECTED](state, id) {
            state.newTeamId = id;
        },
        [types.ROLES_LOADED](state, roles) {
            Vue.set(state, 'roles', roles);
        },
        [types.MEMBERSHIP_LIST_LOADED](state, memberships) {
            Vue.set(state, 'memberships', memberships);
        },
    },
};
