import type { Ref } from 'vue';
import { inject } from 'vue';

import type { Client } from '@/store/modules/client/types/Client';
import type ClientProvider from '@/enums/ClientProvider';

export type CreateIntegratedClientRequestPayload = {
    provider: ClientProvider;
    extraParams?: { upgradeClientId?: string; demoClientId?: string };
};

export type CreateIntegratedClientResponseWithRedirect = {
    redirectUrl: string;
    redirectRequired: boolean;
};

export type CreateClientContextApi = {
    setClient: (client: Client | null) => void;
    hideConnectClientModal: () => void;
    showConnectClientModal: () => void;
    createIntegratedClient(
        payload: CreateIntegratedClientRequestPayload
    ): Promise<CreateIntegratedClientResponseWithRedirect>;
    clientLimitModalVisible: Ref<boolean>;
};

export const CreateClientContext = Symbol('CreateClientContext');

/**
 * Give a component access to the CreateClientContext that is provided by the
 * CreateClientProvider component.
 *
 * @param componentName If for some reason there isn't a CreateClientProvider,
 * this will be used in the error message.
 * @returns
 */
export function useCreateClient(componentName: string) {
    const context = inject<CreateClientContextApi>(CreateClientContext);

    if (!context) {
        throw new Error(`<${componentName} /> is missing a parent <CreateClientProvider /> component.`);
    }

    return context;
}
