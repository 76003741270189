import moment from 'moment/min/moment-with-locales';

import { getBrowserLocale } from '@/utils/browser';

export function ago(value, locale = 'en') {
    moment.locale(locale);

    return moment(value).fromNow();
}

export function format(value, format = 'DD/MM/YYYY') {
    return moment.utc(value).local().format(format);
}

export function getFinancialYearForDate(financialYearEndDay, financialYearEndMonth, date) {
    // Month parameter is 1-based, moment is 0-based
    financialYearEndMonth -= 1;

    if (
        date.month() > financialYearEndMonth ||
        (date.month() === financialYearEndMonth && date.date() > financialYearEndDay)
    ) {
        return {
            start: moment({ date: financialYearEndDay, month: financialYearEndMonth, year: date.year() }).add(
                1,
                'days'
            ),
            end: moment({ date: financialYearEndDay, month: financialYearEndMonth, year: date.year() }).add(1, 'years'),
        };
    } else {
        return {
            start: moment({ date: financialYearEndDay, month: financialYearEndMonth, year: date.year() })
                .add(1, 'days')
                .subtract(1, 'years'),
            end: moment({ date: financialYearEndDay, month: financialYearEndMonth, year: date.year() }),
        };
    }
}

/*
    generatePeriods('2018-01', '2018-12') => ['2018-01', '2018-02', ..., '2018-11', '2018-12']
*/
export function* generatePeriods(from, to) {
    const end = moment(to);
    let current = moment(from);

    if (current.isAfter(end)) {
        throw 'From date must be before to date';
    }

    do {
        yield current.format('YYYY-MM');

        current = current.add(1, 'months');
    } while (current.isSameOrBefore(end));
}

/*
    DatePicker component requires MM dd yyyy values for Month, Day, Year
 */
export function localeDateFormat() {
    switch (getBrowserLocale()) {
        case 'en-AU':
        case 'en-GB':
        case 'en-NZ':
            return 'dd/MM/yyyy';
        case 'en-CA':
        case 'en-US':
            return 'MM/dd/yyyy';
        default:
            return 'yyyy-MM-dd';
    }
}

/*
    moment() and date() format requires MM DD YYYY values for Month, Day, Year
 */
export function localeDateFormatUpper() {
    return localeDateFormat().toUpperCase();
}
