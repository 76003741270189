<template>
    <img alt="Accounting System Icon" :class="imageClass" :src="organisationIcon" :style="iconStyle" v-if="useImage" />

    <div :class="iconClass" :style="iconStyle" v-else>
        {{/* dext-backwards-compatibility font-awesome-icons */}}
        <VIcon :color="iconColor" :name="organisationIcon" size="2em" v-if="iconSize === 'large'" />
        <VIcon :color="iconColor" :name="organisationIcon" size="0.5em" v-else-if="iconSize === 'xs'" />
        <VIcon :color="iconColor" :name="organisationIcon" v-else />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

const iconMap = {
    CHARITY: { icon: 'heart-thick', color: '#b5e3d7' },
    NOT_FOR_PROFIT: { icon: 'heart-thick', color: '#b5e3d7' },
    CLUB_OR_SOCIETY: { icon: 'supplier-categories-thick', color: '#46b99c' },
    COMPANY: { icon: 'building-sme-thick', color: '#196acc' },
    PARTNERSHIP: { icon: 'handshake-thick', color: 'red' },
    PERSON: { icon: 'profile-thick', color: '#46b99c' },
    PRACTICE: { icon: 'account-type-thick', color: '#aa9109' },
    SOLE_TRADER: { icon: 'profile-thick', color: '#46b99c' },
    TRUST: { icon: 'money-thick', color: '#b5e3d7' },
    ACCOUNTING_PRACTICE: { icon: 'money-thick', color: '#b5e3d7' },
    QUICKBOOKS: { img: '/img/quickbooks-logo.svg' },
    XERO: { img: '/img/xero-logo.svg' },
    COMPANIES_HOUSE: { img: '/img/companies-house-logo.svg' },
    HMRC: { icon: 'building-sme-thick', color: '#c3cbd5', iconColor: '#000000' },
    UNINTEGRATED: { icon: 'building-sme-thick', color: '#c3cbd5', iconColor: '#000000' },
};

export default defineComponent({
    name: 'ClientIcon',
    props: {
        orgType: { type: String, default: 'COMPANY' },
        iconSize: { type: String, default: 'large' },
    },
    computed: {
        useImage() {
            return iconMap[this.orgType] ? !!iconMap[this.orgType].img : false;
        },
        organisationIcon() {
            if (this.useImage) {
                return iconMap[this.orgType].img;
            }

            return iconMap[this.orgType] ? iconMap[this.orgType].icon : iconMap['COMPANY'].icon;
        },
        iconColor() {
            return iconMap[this.orgType] ? iconMap[this.orgType].iconColor : 'currentColor';
        },
        imageClass() {
            return this.iconSize === 'xs' ? '' : 'mr-2';
        },
        iconClass() {
            if (this.iconSize === 'xs') {
                return 'card-icon client-card-lg';
            }

            if (this.iconSize === 'small') {
                return 'card-icon mr-2 client-card-md';
            }

            return 'card-icon mr-2 client-card-lg';
        },
        iconStyle() {
            const colour = iconMap[this.orgType] ? iconMap[this.orgType].color : iconMap['COMPANY'].color;
            const dimension =
                this.iconSize === 'large'
                    ? this.useImage
                        ? '50px'
                        : '43px'
                    : this.iconSize === 'xs'
                      ? '18px'
                      : '25px';

            return {
                backgroundColor: colour,
                height: dimension,
                width: dimension,
            };
        },
    },
});
</script>
