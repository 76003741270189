<template>
    <div class="menu-title">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'MenuTitle',
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.menu-title {
    color: get-color(gray, medium);
    font-size: pxtorem(12);
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    .menu-section--horizontal & {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .menu-content--medium & {
        margin-top: 0;
    }

    & + * {
        margin-top: 12px;
    }
}
</style>
