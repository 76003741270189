import ClientService from '@/services/Api/ClientService';

import * as types from './types';

export default {
    namespaced: true,

    state() {
        return {
            taxRates: [],
            loading: false,
        };
    },

    getters: {
        loading: (state) => state.loading,
        taxRateList: (state) => state.taxRates,
    },

    actions: {
        async loadTaxCodeList({ commit }, clientSlug) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await ClientService.loadTaxCodes(practiceCrn, clientSlug);

            commit(types.TAX_RATE_LIST_LOADED, data);
        },
    },

    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.TAX_RATE_LIST_LOADED](state, taxRates) {
            state.taxRates = taxRates;

            state.loading = false;
        },
    },
};
