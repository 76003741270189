<template>
    <Fragment>
        <template v-if="limitApproaching">
            <p>You've almost reached the maximum number of clients in your free trial period.</p>
            <p>
                You can add up to {{ clientLimit }} clients during your free trial. If you would like to add more,
                please add your payment details to activate your subscription.
            </p>
        </template>

        <template v-else>
            <p>You've reached the maximum number of clients in your free trial period.</p>
            <p>
                You can only add up to {{ clientLimit }} clients during your free trial. If you would like to add more,
                please add your payment details to activate your subscription.
            </p>
        </template>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

export default defineComponent({
    components: { Fragment },
    props: {
        clientLimit: PropTypes.number().isRequired,
        limitApproaching: PropTypes.bool().isRequired,
    },
});
</script>
