<template>
    <Interactive
        v-bind="attrs"
        ref="menuItemRef"
        :class="className"
        :data-qa="qaName"
        role="menuitem"
        @click.native="onClick"
    >
        <slot name="item">
            <div v-if="hasIconLeft || loading" class="menu-item__icon menu-item__icon--left">
                <slot v-if="!loading" name="icon-left" />

                <VSpinner v-if="loading" basic :centered="false" class="menu-item__spinner" />
            </div>

            <div class="menu-item__label">
                <slot />
            </div>

            <div v-if="hasIconRight" class="menu-item__icon menu-item__icon--right">
                <slot name="icon-right" />
            </div>
        </slot>
    </Interactive>
</template>

<script lang="ts">
import isString from 'lodash-es/isString';
import kebabCase from 'lodash-es/kebabCase';
import omit from 'lodash-es/omit';
import { computed, onMounted, onUnmounted, ref, defineComponent, watch } from 'vue';
import * as PropTypes from 'vue-types';

import Interactive from '@/components/Interactive';
import { useRouter } from '@/composables/useRouter';
import injectStrict from '@/hooks/injectStrict';
import useHasSlot from '@/hooks/useHasSlot';
import { useUUID } from '@/mixins/uuid';
import { scrollIntoView } from '@/utils/dom';

import type { MenuContextApi, MenuItemProps, MenuItemRef } from '../types';
import type { Api as AnalyticsProviderApi } from '@/providers/AnalyticsProvider';

/**
 * User-defined type-guard to check that the parameter is a Vue component
 *
 * @returns Boolean
 */
function isInteractiveComponent(componentOrElement: MenuItemRef): componentOrElement is Vue {
    return Boolean(componentOrElement && '$el' in componentOrElement);
}

export default defineComponent<MenuItemProps>({
    emits: ['click'],

    components: {
        Interactive,
    },

    extends: Interactive,

    props: {
        /**
         * If this MenuItem represents a destructive action.
         */
        danger: PropTypes.bool().def(false),

        /**
         * Is the current `MenuItem` loading? This will set the item to
         * `disabled` as well as add a loading icon. Recommended to use with
         * an `icon-left` slot to prevent content jump.
         */
        loading: PropTypes.bool().def(false),

        /**
         * Assign a name to the `MenuItem` for QA and analytics.
         */
        name: PropTypes.string().isRequired,
    },

    setup(props, context) {
        const uuid = useUUID();
        const router = useRouter();
        const analyticsContext = injectStrict<AnalyticsProviderApi>('analyticsProvider');
        const menuContext = injectStrict<MenuContextApi>('MenuContext');
        const menuItemRef = ref<MenuItemRef>(null);

        const id = `menu_item_${uuid}`;
        const name = `Menu Item ${props.name}`;
        const qaName = `menu-item-${kebabCase(props.name)}`;
        const isLink = computed(() => Boolean(props.to || props.href));
        const hasClick = computed(() => Boolean(context.listeners.click));
        const isDisabled = computed(() => Boolean(props.disabled || props.loading));
        const isInteractive = computed(() => isLink.value || hasClick.value);

        const isActive = computed(() => {
            return menuContext.activeItemIndex.value !== -1
                ? menuContext.items.value[menuContext.activeItemIndex.value]?.id === id
                : false;
        });

        const attrs = computed(() => ({
            ...context.attrs,
            ...omit(props, ['loading', 'name']),
            disabled: isDisabled.value,
            href: props.href ?? null,
            id,
            name,
            to: props.to ?? null,
            type: hasClick.value && !isLink.value ? 'button' : null,
        }));

        const onClick = (event: MouseEvent) => {
            if (isDisabled.value) return;

            context.emit('click', event);

            if (menuContext.closeOnContentClick && !isDisabled.value) menuContext.closeMenu();
            if (!isLink.value) return analyticsContext.trackButtonClick(name);

            if (props.to) {
                const isRoute = props.to && !isString(props.to);
                const url = !isRoute ? props.to : router?.resolve(props.to)?.href;

                analyticsContext.trackLinkClick(name, url ?? '', Boolean(props.href));
            } else {
                /**
                 * TypeScript is moaning because we are expecting a string as the second parameter.
                 * This should never be unknown, as although props.href is optional if we get here we have it, as we
                 * have previously determined we are not dealing with a button and it's not a router-link element.
                 */
                analyticsContext.trackLinkClick(name, props.href ?? 'Unknown', Boolean(props.href));
            }
        };

        onMounted(() => {
            menuContext.registerItem(id, {
                isDisabled: isDisabled.value ?? false,
                isInteractive: isInteractive.value,
            });
        });

        onUnmounted(() => menuContext.unregisterItem(id));

        watch(isActive, (value) => {
            if (!value) return;

            if (isInteractiveComponent(menuItemRef.value)) {
                scrollIntoView(menuItemRef.value.$el as HTMLElement, { block: 'center' });
            }
        });

        return {
            attrs,
            className: computed(() => {
                return {
                    'menu-item': true,
                    'menu-item--active': isActive.value,
                    'menu-item--clickable': isInteractive.value,
                    'menu-item--danger': props.danger,
                    'menu-item--disabled': isDisabled.value,
                    'menu-item--link': isLink.value,
                };
            }),
            hasIconLeft: useHasSlot('icon-left'),
            hasIconRight: useHasSlot('icon-right'),
            isDisabled,
            isInteractive,
            menuItemRef,
            onClick,
            qaName,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.menu-item {
    align-items: center;
    background-color: $color-white;
    border: none;
    display: flex;
    font-family: $typeface-roboto;
    font-size: pxtorem(13);
    font-weight: $font-weight-regular;
    line-height: 1.65;
    padding: 6px 16px 5px;
    text-align: left;
    width: 100%;
}

.menu-item--clickable:not(.menu-item--disabled) {
    color: get-color(charcoal, lite);
    cursor: pointer;
    outline: 0;
    transition: $transition-primary;
    transition-property: background-color, box-shadow;

    &:hover {
        background-color: get-color(orange, lite);
        color: get-color(charcoal, lite);
        text-decoration: none;
    }

    &.menu-item--active {
        box-shadow: 0 0 0 0.2rem rgba(get-color(orange), 0.5) inset;
    }
}

.menu-item--clickable.menu-item--danger {
    color: var(--d-color-red);

    &:hover {
        background-color: var(--d-color-red-lite);
        color: var(--d-color-red);
    }
}

.menu-item--disabled {
    color: get-color(gray, medium);
    cursor: default;
}

.menu-item__label {
    align-items: center;
    display: flex;
    width: 100%;
}

.menu-item__icon {
    align-items: center;
    display: flex;
    font-size: 16px;
    height: 16px;
    margin-right: 10px;
    overflow: hidden;
    width: 16px;

    .dext-icon-container {
        display: block;
    }
}

.menu-item__icon--right {
    margin-left: auto;
}
</style>
