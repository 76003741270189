<template>
    <VCheck
        :additional="stats.alertText"
        :alert-icon="alertLevel"
        :client-id="clientId"
        expandable
        :name="name"
        :title="title"
    >
        <template #description>
            <span class="text-primary">{{ stats.count }}</span> Unreconciled | Balance:
            <span class="text-primary">
                {{ currency ? formatCurrency(balance, currency) : $formatCurrency(balance) }}
            </span>
        </template>

        <template #checkDetail>
            <VGrid
                :columns="statementColumns"
                :data="statements"
                expandable
                :expanded-title="title"
                :grid-style="`width: 100%; height: 370px;`"
                :name="title"
                :options="agedOptions"
            />
        </template>
    </VCheck>
</template>

<script>
import { statementColumns, agedOptions } from '@/utils/grid';
import { formatCurrency } from '@/utils/currency';

export default {
    name: 'Unreconciled',

    inject: ['$formatCurrency'],

    props: {
        clientId: { type: String, required: true },
        statements: { type: Array, required: true },
        stats: { type: Object, required: true },
        title: { type: String, default: 'Reconciliation Review' },
        transactionCountAlert: { type: Boolean, default: false },
        currency: { type: String, default: null },
        currentBalance: { type: Number, default: null },
        name: { type: String, required: false, default: '' },
    },

    data() {
        return {
            agedOptions,
            formatCurrency,
            statementColumns,
        };
    },

    computed: {
        alertLevel() {
            if (!this.stats) {
                return 'success';
            }

            if (this.transactionCountAlert) {
                return this.stats.count > 0 ? 'error' : 'success';
            }

            const status = this.stats.alert;

            switch (status) {
                case 'error':
                case 'warning':
                    return status;
                default:
                    return 'success';
            }
        },

        balance() {
            return this.currentBalance ? this.currentBalance : this.stats.balance;
        },
    },
};
</script>
