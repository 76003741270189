const SET_QBO_CLIENTS_CLICKED = 'SET_QBO_CLIENTS_CLICKED';
const RESET_QBO_CLIENTS_CLICKED = 'RESET_QBO_CLIENTS_CLICKED';

export default {
    namespaced: true,

    state: {
        qboClientsClicked: [],
    },

    getters: {
        qboClientsClicked: (state) => state.qboClientsClicked,
    },

    actions: {
        setQboClientsClicked({ commit }, realmId) {
            commit(SET_QBO_CLIENTS_CLICKED, realmId);
        },
        resetQboClientsClicked({ commit }) {
            commit(RESET_QBO_CLIENTS_CLICKED);
        },
    },

    mutations: {
        [SET_QBO_CLIENTS_CLICKED](state, realmId) {
            state.qboClientsClicked.push(realmId);
        },
        [RESET_QBO_CLIENTS_CLICKED](state) {
            state.qboClientsClicked = [];
        },
    },
};
