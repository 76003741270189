<template>
    <ElDatePicker
        v-bind="$attrs"
        v-model="modelBinding"
        :clearable="clearable"
        :format="localeDateFormat"
        :picker-options="options"
        :placeholder="placeholder"
        size="small"
        type="date"
        unlink-panels
        value-format="yyyy-MM-dd"
    />
</template>

<script>
import DatePicker from 'element-ui/lib/date-picker';

import 'element-ui/lib/theme-chalk/date-picker.css';
import { localeDateFormat } from '@/utils/date';

const defaultShortcuts = {
    Today: () => moment().endOf('day').toDate(),
    'Last Week': () => moment().subtract(1, 'weeks').endOf('week').toDate(),
    'Last Month': () => moment().subtract(1, 'months').endOf('month').toDate(),
    'Last Year': () => moment().subtract(1, 'years').endOf('year').toDate(),
};

export default {
    name: 'DatePickerSingle',
    components: {
        ElDatePicker: DatePicker,
    },
    props: {
        value: { type: String, default: null },
        placeholder: { type: String, default: 'Start date' },
        futureOnly: { type: Boolean, default: false },
        showShortcuts: { type: Boolean, default: false },
        shortcuts: { type: Object, default: () => {} },
        clearable: { type: Boolean, default: false },
    },
    data() {
        return {
            localeDateFormat: localeDateFormat(),
        };
    },
    computed: {
        modelBinding: {
            get() {
                return this.value;
            },
            set(date) {
                this.$emit('input', date);
            },
        },
        shortcutOptions() {
            const shortcuts = { ...defaultShortcuts, ...this.shortcuts };

            return {
                shortcuts: Object.entries(shortcuts).map(([k, v]) => ({
                    text: k,
                    onClick: (picker) => picker.$emit('pick', v()),
                })),
            };
        },
        options() {
            const futureOnly = this.futureOnly
                ? {
                      disabledDate(time) {
                          return moment(time).startOf('day').isBefore(moment().startOf('day'));
                      },
                  }
                : {};

            const shortcuts = this.showShortcuts ? this.shortcutOptions : {};

            return { ...futureOnly, ...shortcuts };
        },
    },
};
</script>

<style>
.el-date-editor--date {
    width: 135px !important;
}
</style>
