import { Module } from 'vuex';
import { ReconciliationActivityState } from '@/store/modules/insightsDashboard/reconciliationActivity/types';

import { getters } from '@/store/modules/insightsDashboard/reconciliationActivity/getters';
import { mutations } from '@/store/modules/insightsDashboard/reconciliationActivity/mutations';
import { actions } from '@/store/modules/insightsDashboard/reconciliationActivity/actions';
import { RootState } from '@/store/types';

const state: ReconciliationActivityState = {
    reconciliationActivity: {
        widget: {
            isLoading: false,
            results: {
                no_reconciled_in_last_7_days: 0,
                no_reconciled_in_60_days: 0,
                no_reconciled_in_last_30_days: 0,
                no_reconciled_in_over_90_days: 0,
            },
        },
        details: {
            isLoading: false,
            results: [],
        },
    },
};

const ReconciliationActivityModuleTs: Module<ReconciliationActivityState, RootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default ReconciliationActivityModuleTs;
