import type {
    InitProductTourRequestSchema,
    InitProductTourResponseSchema,
    ProgressProductTourRequestSchema,
    CompleteProductTourRequestSchema,
    FetchProductToursResponseSchema,
} from '@/schemas/app/ProductTour.schema';

import BaseApiService from './BaseApiService';

class ProductTourService extends BaseApiService {
    async fetchProductTours(practiceCrn: string) {
        return this.get<FetchProductToursResponseSchema>('dext.product-tours', { teamCrn: practiceCrn });
    }
    async initProductTour(practiceCrn: string, data: InitProductTourRequestSchema) {
        return this.post<InitProductTourResponseSchema>('dext.product-tour.init', { teamCrn: practiceCrn }, data);
    }
    async progressProductTour(practiceCrn: string, data: ProgressProductTourRequestSchema) {
        return this.post<ProgressProductTourRequestSchema>(
            'dext.product-tour.progress',
            { teamCrn: practiceCrn },
            data
        );
    }
    async completeProductTour(practiceCrn: string, data: CompleteProductTourRequestSchema) {
        return this.post<CompleteProductTourRequestSchema>(
            'dext.product-tour.complete',
            { teamCrn: practiceCrn },
            data
        );
    }
}

export default Object.freeze(new ProductTourService());
