<template>
    <p v-if="!canCreate && !suggestions.length">
        No further tags are assignable - additional tags can be created from the Team menu.
    </p>
    <span v-else>
        <ElAutocomplete
            v-model="tagInput"
            class="inline-input"
            :fetch-suggestions="querySearch"
            :placeholder="translator.t(t.placeholder)"
            :select-when-unmatched="true"
            @select="tagSelected"
        />
        <button v-if="canCreate" class="btn btn-add-tag" :disabled="tagInput === ''" @click.prevent="newTag">
            <VIcon name="zoom-add-plus-thick" />
        </button>
    </span>
</template>

<script>
import AutoComplete from 'element-ui/lib/autocomplete';

import 'element-ui/lib/theme-chalk/autocomplete.css';
import { useVueI18n } from '@/composables/useVueI18n';

import { translations as t } from './NewTag.translations';

export default {
    name: 'AutoComplete',
    components: {
        ElAutocomplete: AutoComplete,
    },
    props: {
        suggestions: { type: [Array], default: () => [] },
        placeholder: { type: String, default: 'Add tag' },
        canCreate: { type: Boolean, required: true },
    },
    data() {
        return {
            tagInput: '',
        };
    },
    setup() {
        const translator = useVueI18n();

        return { t, translator };
    },
    methods: {
        querySearch(queryString, cb) {
            const suggestions = this.suggestions;
            const filtered = queryString ? suggestions.filter(this.createFilter(queryString)) : suggestions;

            const matches = [];

            filtered.forEach(function (t) {
                matches.push({ value: t.name, id: t.id });
            });

            cb(matches);
        },
        createFilter(queryString) {
            return (tag) => {
                return tag.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        tagSelected(item) {
            if (item.value !== '' && (this.canCreate || item.id)) {
                this.$emit('selected', {
                    name: item.value,
                    id: item.id ? item.id : null,
                });

                this.tagInput = '';
            }
        },
        newTag() {
            if (this.tagInput !== '' && this.canCreate) {
                this.$emit('selected', {
                    name: this.tagInput,
                    id: null,
                });

                this.tagInput = '';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-add-tag {
    background: #31826e;
    border-radius: 4px;
    color: white;
    margin-bottom: 0.25rem;
}
</style>
