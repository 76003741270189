import { defineStore, acceptHMRUpdate } from 'pinia';
import { set } from 'vue';

import { useUUID } from '@/mixins/uuid';

import type { AppRequestConfig, RequestMeta, RequestStoreState } from './RequestsStore.types';

import { RequestStatusStates } from './RequestStatusStates';

const initialRequestMetaState: RequestMeta = {
    error: null,
    lastSuccessTimestamp: null,
    status: RequestStatusStates.NEUTRAL,
};

const useRequestsStore = defineStore('requests.store', {
    state(): RequestStoreState {
        return {
            requests: {},
        };
    },

    getters: {
        /**
         * Get the current error, if any, for a given request ID.
         *
         * @param requestId string
         * @returns null | unknown
         */
        getError(state) {
            return (requestId: string) => {
                return state.requests[requestId]?.error;
            };
        },

        /**
         * Get a request's meta data for a given request ID.
         *
         * @param requestId string
         * @returns null | unknown
         */
        getRequest(state) {
            return (requestId: string) => {
                return state.requests[requestId];
            };
        },

        /**
         * Has a request for given ID ever been successful?
         *
         * @param requestId string
         * @returns boolean
         */
        hasBeenSuccessFul(state) {
            return (requestId: string) => {
                return Boolean(state.requests[requestId]?.lastSuccessTimestamp);
            };
        },

        /**
         * Is the request for given ID a failure?
         *
         * @param requestId string
         * @returns boolean
         */
        isFailure(state) {
            return (requestId: string) => {
                return state.requests[requestId]?.status === RequestStatusStates.IS_FAILURE;
            };
        },

        /**
         * Is the request for given ID in progress?
         *
         * @param requestId string
         * @returns boolean
         */
        isInProgress(state) {
            return (requestId: string) => {
                return state.requests[requestId]?.status === RequestStatusStates.IN_PROGRESS;
            };
        },

        /**
         * Is the request for given ID in progress?
         * The requestId string will also match if the id starts with the given string.
         *
         * @param requestId string
         * @returns boolean
         */
        isInProgressMatch(state) {
            return (requestId: string) => {
                if (!state.requests || state.requests.length === 0) {
                    return false;
                }

                const filteredRequests = Object.keys(state.requests)
                    .filter((key) => {
                        const regex = new RegExp('^' + requestId.replaceAll('.', '\\.') + '\\.[0-9]+');

                        return regex.exec(key);
                    })
                    .reduce((obj, key) => {
                        return {
                            ...obj,
                            [key]: state.requests[key],
                        };
                    }, {});

                return !!Object.values(filteredRequests).find((request) => {
                    return request.status === RequestStatusStates.IN_PROGRESS;
                });
            };
        },

        /**
         * Is the request for given ID a success?
         *
         * @param state
         * @returns boolean
         */
        isSuccess(state) {
            return (requestId: string) => {
                return state.requests[requestId]?.status === RequestStatusStates.IS_SUCCESS;
            };
        },
    },

    actions: {
        /**
         * This method is Private.
         *
         * Create the initial state for a request. Will only create if it
         * doesn't already exist. If you need to replace for any reason,
         * you should call `requestStore.deleteRequest` first with your ID.
         *
         * Any subsequent calls to any of the `requestStore` methods will
         * update the state.
         *
         * @param id
         * @private For internal use only and testing only.
         * @returns void
         */
        __createStateForRequest(id: string) {
            if (this.requests[id]) return;

            set(this.requests, id, initialRequestMetaState);
        },

        /**
         * Delete the state for a request. This shouldn't be necessary.
         *
         * @param id
         * @returns void
         */
        deleteStateForRequest(id: string) {
            if (!this.requests[id]) return;

            const { [id]: _id, ...requests } = this.requests;

            set(this, 'requests', requests);
        },

        /**
         * Initiate your request.
         *
         * @param requestMethod An anonymous function that returns the request
         *     method you want to make.
         * @param config
         * @returns Promise<TResponse>
         */
        async makeRequest<TResponse>(
            requestMethod: () => Promise<TResponse>,
            config?: AppRequestConfig
        ): Promise<TResponse> {
            const requestId = config?.requestId ?? useUUID();

            this.__createStateForRequest(requestId);

            try {
                if (config?.blockMultipleRequests && this.isInProgress(requestId)) {
                    throw new Error(`Request "${requestId}" is already in flight.`);
                }

                this.resetError(requestId);
                this.startProgress(requestId);

                const response = await requestMethod();

                this.setSuccess(requestId);

                return response;
            } catch (error) {
                this.setError(requestId, error);

                throw error;
            }
        },

        /**
         * Reset the error for a given request ID. Any last successful timestamp
         * will be preserved, and the set status will depend on this.
         *
         * @param id
         * @returns void
         */
        resetError(id: string) {
            if (!this.requests[id]) return;

            set(this.requests, id, {
                ...this.requests[id],
                error: null,
                status: this.requests[id].lastSuccessTimestamp
                    ? RequestStatusStates.IS_SUCCESS
                    : RequestStatusStates.NEUTRAL,
            });
        },

        /**
         * Set the error for a given request ID.
         *
         * @param id
         * @param error
         * @returns void
         */
        setError(id: string, error: unknown) {
            if (!this.requests[id]) return;

            this.requests = {
                ...this.requests,
                [id]: {
                    ...this.requests[id],
                    error,
                    status: RequestStatusStates.IS_FAILURE,
                },
            };
        },

        /**
         * Set a request as successful for a given request ID. Any errors
         * will be cleared and a last successful timestamp will be set.
         *
         * @param id
         * @returns void
         */
        setSuccess(id: string) {
            if (!this.requests[id]) return;

            set(this.requests, id, {
                ...this.requests[id],
                error: null,
                lastSuccessTimestamp: new Date().toUTCString(),
                status: RequestStatusStates.IS_SUCCESS,
            });
        },

        /**
         * Start request progress for a given request ID. This will set the
         * status to `RequestStatusStates.IN_PROGRESS`.
         *
         * @param id
         * @returns
         */
        startProgress(id: string) {
            if (!this.requests[id]) return;

            set(this.requests, id, {
                ...this.requests[id],
                status: RequestStatusStates.IN_PROGRESS,
            });
        },

        /**
         * Stop request progress for a given request ID. Any last successful
         * timestamp will be preserved, and the set status will depend on this.
         *
         * @param id
         * @returns
         */
        stopProgress(id: string) {
            if (!this.requests[id]) return;

            set(this.requests, id, {
                ...this.requests[id],
                status: this.requests[id].lastSuccessTimestamp
                    ? RequestStatusStates.IS_SUCCESS
                    : RequestStatusStates.NEUTRAL,
            });
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRequestsStore, import.meta.hot));
}

export { useRequestsStore };
