import capitalize from 'lodash-es/capitalize';
import moment from 'moment';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import PropValidationError from '@/errors/PropValidationError';
import ExportService from '@/services/Api/ExportService';
import { triggerDownload } from '@/utils/browser';

import FrameApi from './Api';

export default {
    props: {
        clientId: {
            default: '',
            required: true,
            type: String,
        },
        exportType: {
            default: '',
            required: true,
            type: String,
            validator(value) {
                const validTypes = ['flow', 'insight', 'overview'];
                const isValid = validTypes.includes(value);

                if (!isValid) {
                    throw new PropValidationError(`invalid type of "${value}" provided for prop "exportType".`, false);
                }

                return isValid;
            },
        },
        fileName: {
            default: '',
            required: false,
            type: String,
        },
        fileType: {
            default: '',
            required: true,
            type: String,
            validator(value) {
                const validTypes = ['pdf', 'excel'];
                const isValid = validTypes.includes(value);

                if (!isValid) {
                    throw new PropValidationError(`invalid type of "${value}" provided for prop "fileType".`, false);
                }

                return isValid;
            },
        },
        flowSlug: {
            default: '',
            required: false,
            type: String,
        },
        insightName: {
            default: '',
            required: false,
            type: String,
        },
        mode: {
            default: 'dashboard',
            required: false,
            type: String,
        },
    },

    computed: {
        /**
         * Get the Export Service method to call based on the provided options.
         * The method name is generated from `props.exportType` and
         * `props.fileType`.
         *
         * We have to return an anonymous function here otherwise Vue will treat
         * the `ExportService.methodName` as reactive and overwrite the internal
         * structure. This means that `this.get` becomes `undefined`, resulting
         * in the API request failing.
         *
         * @returns Function
         */
        endpoint() {
            const exportType = capitalize(this.exportType);
            const fileType = capitalize(this.fileType);
            const methodName = `get${exportType}${fileType}`;

            return (...args) => ExportService[methodName](...args);
        },

        /**
         * Calculate the file extension based on the given `props.fileType`.
         *
         * @returns String?
         */
        extension() {
            if (this.fileType === 'pdf') return 'pdf';
            if (this.fileType === 'excel') return 'xlsx';

            return null;
        },

        /**
         * Generate the array of arguments to pass to the API request. This is
         * based on which export type we are requesting.
         *
         * The "noop" filter at the end will remove any items that don't have
         * a truthy value.
         *
         * @returns Array
         */
        queryArgs() {
            return [
                useXavierGlobals().currentTeam.rbExternalId,
                this.clientId,
                this.mode,
                (this.exportType === 'flow' && this.flowSlug) ?? null,
                (this.exportType === 'insight' && this.insightName) ?? null,
            ].filter((item) => item);
        },
    },

    methods: {
        export(frameApiProps) {
            return () => {
                frameApiProps.methods.reset();

                return frameApiProps.methods.query(...this.queryArgs);
            };
        },

        /**
         * Handle file export download response. If the response is a blob
         * (a file) then trigger the download of the file. Otherwise return
         * the response message, if it exists.
         *
         * There are rare cases when the file generation may take a little
         * while, and the API returns a JSON response instead.
         *
         * @param {Object} data Response data
         * @emits success
         * @returns String?
         */
        async handleResponseData(data, frameApiProps) {
            if (!data) return null;

            const jsonMimeType = 'application/json';
            const dataType = data.type;
            const now = moment().format('YYYY-MM-DD_HH-mm');

            const isBlob = data instanceof Blob && dataType !== jsonMimeType;

            const prefix = this.fileName ? this.fileName + '_' : '';
            const fileName = `${prefix}${now}.${this.extension}`;

            if (isBlob) {
                triggerDownload(fileName, data);
                this.$emit('success');
                frameApiProps.methods.reset();

                return null;
            }

            const jsonResponse = JSON.parse(await data.text());

            this.$emit('success', jsonResponse?.message ?? null);
            frameApiProps.methods.reset();

            return data?.message ?? null;
        },
    },

    /**
     * Validate some of the optional props on create. These optional props rely
     * on other props, and as such can't be calculated at prop validation time.
     *
     * @throws PropValidationError
     */
    created() {
        if (this.exportType === 'flow' && !this.flowSlug) {
            throw new PropValidationError('"flowSlug" is a required prop when "exportType" is "flow"', false);
        }

        if (this.exportType === 'insight' && !this.insightName) {
            throw new PropValidationError('"insightName" is a required prop when "exportType" is "insight"', false);
        }
    },

    render(createElement) {
        return createElement(FrameApi, {
            on: { error: (error) => this.$emit('error', error) },
            props: { endpoint: this.endpoint },
            scopedSlots: {
                default: (props) => {
                    const response = !props.status.error && this.handleResponseData(props.response, props);

                    return this.$scopedSlots.default({
                        methods: { export: this.export(props) },
                        response: response,
                        status: props.status,
                    });
                },
            },
        });
    },
};
