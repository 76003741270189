import injectStrict from '@/hooks/injectStrict';
import keyCodes from '@/utils/keycodeMap';

import { MenuContextApi } from '../types';

export default function useKeydownHandler() {
    const menuContext = injectStrict<MenuContextApi>('MenuContext');

    /**
     * Handle keydown events.
     *
     * @param {KeyboardEvent} event
     */
    return function (event: KeyboardEvent) {
        switch (event.code) {
            case keyCodes.ARROW_DOWN.code:
                event.preventDefault();
                event.stopPropagation();

                return menuContext.focusNext();

            case keyCodes.ARROW_UP.code:
                event.preventDefault();
                event.stopPropagation();

                return menuContext.focusPrevious();

            case keyCodes.END.code:
                event.preventDefault();
                event.stopPropagation();

                return menuContext.focusLast();

            case keyCodes.ENTER.code:
                event.preventDefault();
                event.stopPropagation();

                return (
                    menuContext.activeItemIndex.value > -1 &&
                    document.getElementById(menuContext.items.value[menuContext.activeItemIndex.value].id)?.click()
                );

            case keyCodes.ESCAPE.code:
                event.preventDefault();
                event.stopPropagation();

                return menuContext.closeMenu();

            case keyCodes.HOME.code:
                event.preventDefault();
                event.stopPropagation();

                return menuContext.focusFirst();

            case keyCodes.TAB.code:
                event.preventDefault();
                event.stopPropagation();
                break;
        }
    };
}
