<template>
    <Fragment>
        <slot />

        <ChooseClientProviderModal
            v-model="connectClientModalVisible"
            :client-count="clientCount"
            :client-id="currentClient && currentClient.id"
            :client-limit="clientLimit"
            :client-limit-reached="clientLimitReached"
            :client-name="currentClient && currentClient.name"
            @create-client="onCreateClient"
        />

        <ClientLimitModal
            v-model="clientLimitModalVisible"
            :client-count="clientCount"
            :client-limit="clientLimit"
            :client-provider="currentClient && currentClient.provider"
            :is-dext="currentTeam.isDext"
            :is-manual-billing="isManualBilling"
            :subscription-status="subscriptionStatus"
        />
    </Fragment>
</template>

<script lang="ts">
import axios from 'axios';
import { computed, defineComponent, provide, reactive, toRef, toRefs, watch } from 'vue';
import { Fragment } from 'vue-frag';

import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import ChooseClientProviderModal from '@/layout/PageLayout/ChooseClientProviderModal.vue';
import { ClientLimitModal } from '@/layout/PageLayout/ClientLimitModal';
import useCurrentTeam from '@/store/hooks/useCurrentTeam';
import { isFeatureEnabled } from '@/utils/features';

import type {
    CreateClientContextApi,
    CreateIntegratedClientRequestPayload,
    CreateIntegratedClientResponseWithRedirect,
} from './useCreateClient';
import type { Client } from '@/store/modules/client/types/Client';

import { CreateClientContext } from './useCreateClient';

type State = {
    currentClient: Client | null;
    connectClientModalVisible: boolean;
    clientLimitModalVisible: boolean;
};

async function createIntegratedClient(payload: CreateIntegratedClientRequestPayload) {
    const endpoint = `/teams/${useXavierGlobals().currentTeam.rbExternalId}/client/new`;

    const { data } = await axios.post<CreateIntegratedClientResponseWithRedirect>(endpoint, payload);

    if (data.redirectRequired) {
        window.location.href = data.redirectUrl;
    }

    return data;
}

/* eslint-disable sort-keys */
export default defineComponent({
    components: { ChooseClientProviderModal, ClientLimitModal, Fragment },

    setup() {
        const currentTeam = useCurrentTeam();
        const xavier = useXavierGlobals();

        const state = reactive<State>({
            currentClient: null,
            connectClientModalVisible: false,
            clientLimitModalVisible: false,
        });

        const clientCount = currentTeam.value.clientCount ?? 0;
        const clientLimit = currentTeam.value.clientLimit ?? 0;
        const clientLimitReached = currentTeam.value.clientLimitReached ?? false;
        const subscriptionStatus = currentTeam.value.subscriptionStatus;
        const isManualBilling = Boolean(currentTeam.value.isManualBilling);

        const canAddDemo = currentTeam.value.isTestTeam && !currentTeam.value.isDemo;

        const canAddBillableClient = computed(() => {
            const hasLimit = clientLimit > 0;
            const hasReachedLimit = hasLimit && clientLimitReached;

            return !hasReachedLimit;
        });

        function show() {
            state.connectClientModalVisible = true;
        }

        function hide() {
            state.currentClient = null;
            state.connectClientModalVisible = false;
            state.clientLimitModalVisible = false;
        }

        const createClientApi: CreateClientContextApi = {
            clientLimitModalVisible: toRef(state, 'clientLimitModalVisible'),
            createIntegratedClient,
            showConnectClientModal: show,
            hideConnectClientModal: hide,
            setClient(client: Client | null = null) {
                state.currentClient = client;
            },
        };

        provide(CreateClientContext, createClientApi);

        watch(
            () => state.connectClientModalVisible,
            (value) => {
                if (!value) {
                    state.currentClient = null;
                }
            }
        );

        return {
            canAddBillableClient,
            canAddDemo,
            clientCount,
            clientLimit,
            clientLimitReached,
            createIntegratedClient,
            currentTeam,
            isManualBilling,
            subscriptionStatus,
            xavier,
            ...toRefs(state),
        };
    },

    methods: {
        async createUnintegratedCient() {
            this.connectClientModalVisible = false;
            await this.$router.push({ name: 'team.unintegrated-import' }).catch(() => null);
        },

        async onCreateClient(payload: { provider: ClientProvider; demoClientId?: string }) {
            const isUnintegratedClient =
                isFeatureEnabled(Feature.UNINTEGRATED) && payload.provider === ClientProvider.UNINTEGRATED;

            if (isUnintegratedClient) {
                return await this.createUnintegratedCient();
            }

            const canCreateBillableClient = this.xavier.subscriptionValid && !this.clientLimitReached;
            const extraParams: { upgradeClientId?: string; demoClientId?: string } = {};

            if (!canCreateBillableClient) return;

            if (this.currentClient && payload.provider !== ClientProvider.DEMO) {
                extraParams.upgradeClientId = this.currentClient.id;
            }

            if (payload.provider === ClientProvider.DEMO) {
                extraParams.demoClientId = payload.demoClientId;
            }

            this.createIntegratedClient({ provider: payload.provider, extraParams });
        },
    },
});
</script>
