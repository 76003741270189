import omit from 'lodash-es/omit';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientBulkImportService from '@/services/Api/ClientBulkImportService';

import * as types from './types';

/* Bulk Import Xero Users Default State
========================================================================== */
const state = () => {
    return {
        errorsByTenantId: {},
        loadingByTenantId: {},
        userListByTenantId: {},
    };
};

/* Bulk Import Xero Users Getters
 *
 * Since we are keying every piece of data against an ID, having a getter
 * return a method to access the data `byId` would result in any data not being
 * cached. It is also an "anti-pattern".
 *
 * Although something like `getVal: state => param => state[param]` would work,
 * as said above Vue can't cache this, nor can it be made reliably reactive.
========================================================================== */
const getters = {};

/* Bulk Import Xero Users Actions
========================================================================== */
const actions = {
    async loadUserList({ commit, state }, payload) {
        const { authEventId, xeroTenantId } = payload;

        if (!state.loadingByTenantId[xeroTenantId]) {
            commit(types.LOAD_XERO_USERS_START, { xeroTenantId });

            try {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const response = await ClientBulkImportService.getXeroUsersForImportClient(
                    practiceCrn,
                    authEventId,
                    xeroTenantId
                );

                commit(types.LOAD_XERO_USERS_SUCCESS, { xeroTenantId, userList: response.data });
            } catch (error) {
                commit(types.LOAD_XERO_USERS_FAILURE, { error, xeroTenantId });
            }
        }
    },
};

/* Bulk Import Xero Users Mutations
========================================================================== */
const mutations = {
    [types.LOAD_XERO_USERS_START](state, payload) {
        state.loadingByTenantId = {
            ...state.loadingByTenantId,
            [payload.xeroTenantId]: true,
        };
    },

    [types.LOAD_XERO_USERS_FAILURE](state, payload) {
        const { error, xeroTenantId } = payload;

        state.loadingByTenantId = {
            ...state.loadingByTenantId,
            [xeroTenantId]: false,
        };

        state.errorsByTenantId = {
            ...state.errorsByTenantId,
            [xeroTenantId]: error,
        };
    },

    [types.LOAD_XERO_USERS_SUCCESS](state, payload) {
        const { userList, xeroTenantId } = payload;

        state.loadingByTenantId = {
            ...state.loadingByTenantId,
            [xeroTenantId]: false,
        };

        state.userListByTenantId = {
            ...state.userListByTenantId,
            [xeroTenantId]: userList,
        };
    },

    [types.RESET_XERO_USERS_ERROR](state, payload) {
        state.errorsByTenantId = omit(state.errorsByTenantId, [payload.client.xeroTenantId]);
    },
};

/* Exports
========================================================================== */
export const NAMESPACE = 'clientBulkImport/xeroUsers';

export const actionTypes = {
    LOAD_USER_LIST: `${NAMESPACE}/loadUserList`,
};

export const mutationTypes = {
    [types.RESET_XERO_USERS_ERROR]: `${NAMESPACE}/${types.RESET_XERO_USERS_ERROR}`,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
