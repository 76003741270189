export type RequestStatusStateKeys = keyof typeof RequestStatusStates;
export type RequestStateValues = (typeof RequestStatusStates)[RequestStatusStateKeys];

export const RequestStatusStates = {
    CANCELLED: 'CANCELLED',
    IN_PROGRESS: 'IN_PROGRESS',
    IS_FAILURE: 'IS_FAILURE',
    IS_SUCCESS: 'IS_SUCCESS',
    NEUTRAL: 'NEUTRAL',
} as const;
