import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientService from '@/services/Api/ClientService';
import FlowService from '@/services/Api/FlowService';

import * as types from './types';

export default {
    namespaced: true,
    state() {
        return {
            records: {},
            scheme: {},
            loading: false,
        };
    },
    getters: {
        loading: (state) => state.loading,
        mtdRecords: (state) => Object.values(state.records),
        vatScheme: (state) => state.scheme,
        filteredMtdRecords: (state, getters) => (viewFilter, searchFilter) => {
            let filtered;

            switch (viewFilter) {
                case 'myClients':
                    filtered = getters.mtdRecords.filter(
                        (c) => c.userId === window.Xavier.userId || c.secondUserId === window.Xavier.userId
                    );
                    break;
                case 'favourites':
                    filtered = getters.mtdRecords.filter((c) => c.hasFlagged);
                    break;
                default:
                    filtered = getters.mtdRecords;
            }

            return searchFilter
                ? filtered.filter((c) => c.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1)
                : filtered;
        },
    },
    actions: {
        async loadMtdRecords({ commit }) {
            commit(types.LOADING_STARTED);

            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const { data } = await axios.get(`/api/teams/${practiceCrn}/mtd`);

            commit(types.VAT_RECORDS_LOADED, data);
        },
        async loadVatScheme({ commit }, clientId) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const data = await ClientService.loadVatScheme(practiceCrn, clientId);

            commit(types.VAT_SCHEME_LOADED, data);
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.VAT_RECORDS_LOADED](state, records) {
            state.records = records;

            state.loading = false;
        },
        [types.VAT_SCHEME_LOADED](state, scheme) {
            state.scheme = scheme;

            state.loading = false;
        },
    },
};
