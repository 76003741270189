<template>
    <Modal v-model="isVisible" name="Choose Client Provider" :persistent="!isClosable">
        <ModalHeader>{{ modalTitle }}</ModalHeader>
        <ModalContent>
            <div class="text-center">
                <div v-if="Boolean(clientCount) && clientLimit > 0" class="client-limit-container mb-3">
                    You have used <span class="client-limit">{{ clientCount }} / {{ clientLimit }}</span> available
                    clients
                </div>

                <div v-if="isClientUpgrade" class="mb-3 text-muted text-left text-center">
                    This will change this client from being free of charge to counting towards the client limit of your
                    plan.
                </div>

                <div class="mb-3">
                    <ClientProviderButton
                        data-qa="button-connect-xero"
                        :is-disabled="hasReachedLimit"
                        logo="xero-logo.svg"
                        :provider="ClientProvider.XERO"
                        provider-name="Xero"
                        @click="$emit('create-client', $event)"
                    />
                </div>

                <div class="mb-3">
                    <ClientProviderButton
                        v-if="quickbooksEnabled"
                        data-qa="button-connect-quickbooks"
                        :is-disabled="hasReachedLimit"
                        logo="quickbooks-logo.svg"
                        :provider="ClientProvider.QUICKBOOKS"
                        provider-name="QuickBooks"
                        @click="$emit('create-client', $event)"
                    />
                </div>
                <div v-if="!subscriptionValid" class="content-description text-center row justify-content-center">
                    Your subscription is currently inactive.
                </div>
                <div v-if="isLimitApproaching || hasReachedLimit || !subscriptionValid" class="mb-3">
                    <OnTrialLimitApproachingOrReached
                        v-if="isOnTrial"
                        :client-limit="clientLimit"
                        :limit-approaching="isLimitApproaching"
                    />
                    <LimitApproachingOrReached
                        v-else
                        :client-limit="clientLimit"
                        :is-dext="userIsDext"
                        :is-manual-billing="isManualBilling"
                        :limit-approaching="isLimitApproaching"
                    />

                    <VButton
                        v-if="userIsDext"
                        class="ml-1 central-billing-link"
                        name="Client Limit Modal Redirect To Central Subscriptions"
                        text
                        @click="onCentralBillingClick"
                    >
                        Update your subscription
                    </VButton>

                    <VButton
                        v-else-if="isManualBilling"
                        class="ml-1 central-billing-link"
                        name="Client Limit Modal Contact us"
                        text
                        @click="onContactUsClick"
                    >
                        Contact us
                    </VButton>

                    <VButton
                        v-else
                        class="ml-1 central-billing-link"
                        :name="`Client Limit Modal ${isOnTrial ? 'Add Payment Details' : 'Upgrade Subscription'}`"
                        text
                        @click="onCentralBillingClick"
                    >
                        {{ `${isOnTrial ? 'Add payment details' : 'Add more clients'}` }}
                    </VButton>
                </div>

                <template v-if="canAddDemo && !isClientUpgrade">
                    <div v-for="client in demoClients" :key="client.id" class="row justify-content-center mt-2">
                        <button
                            class="provider-button p-2 pointable border border-secondary rounded-lg"
                            data-qa="button-connect-demo"
                            @click="createDemoClient(client.id)"
                        >
                            <div class="provider-text">Add Demo Client</div>
                            <div class="provider-text--small">{{ client.name }}</div>
                        </button>
                    </div>
                </template>
            </div>

            <UnintegratedClients
                v-if="unintegratedClientsEnabled && !isClientUpgrade"
                @create-client="$emit('create-client', { provider: ClientProvider.UNINTEGRATED })"
            />
            <div class="text-center">
                <VButton
                    v-if="playgroundClientsEnabled"
                    class="provider-button playground-button mb-3"
                    data-qa="button-playground-client"
                    :name="`add-playground-client`"
                    secondary
                    @click="handleCreatePlaygroundClient"
                >
                    Demo Client
                </VButton>
            </div>
        </ModalContent>

        <ModalFooter>
            <template #right>
                <VButton v-if="isClosable" name="Add new client modal cancel" @click="isVisible = false">
                    Cancel
                </VButton>
                <div v-else />
            </template>
        </ModalFooter>
    </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import * as PropTypes from 'vue-types';

import { Modal, ModalContent, ModalFooter, ModalHeader } from '@/components/Modal';
import useCentralBilling from '@/composables/useCentralBilling';
import { useContactSupport } from '@/composables/useContactSupport';
import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import SubscriptionStatus from '@/enums/SubscriptionStatus';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientProviderButton from '@/layout/PageLayout/ClientProviderButton.vue';
import LimitApproachingOrReached from '@/layout/PageLayout/LimitApproachingOrReached.vue';
import OnTrialLimitApproachingOrReached from '@/layout/PageLayout/OnTrialLimitApproachingOrReached.vue';
import UnintegratedClients from '@/layout/PageLayout/UnintegratedClients.vue';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

export default defineComponent({
    components: {
        LimitApproachingOrReached,
        OnTrialLimitApproachingOrReached,
        ClientProviderButton,
        Modal,
        ModalContent,
        ModalFooter,
        ModalHeader,
        UnintegratedClients,
    },

    props: {
        clientCount: PropTypes.number().def(0),
        clientId: PropTypes.string().def(undefined),
        clientLimit: PropTypes.number().def(0),
        clientLimitReached: PropTypes.bool().def(false),
        clientName: PropTypes.string().def(''),
        visible: PropTypes.bool().def(false),
        isClosable: PropTypes.bool().def(true),
    },

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    methods: {
        handleCreatePlaygroundClient() {
            this.$emit('create-client', {
                provider: ClientProvider.PLAYGROUND,
            });
        },
        async createDemoClient(clientId: string) {
            this.$emit('create-client', {
                provider: ClientProvider.DEMO,
                demoClientId: clientId,
            });
        },
    },

    setup(props, context) {
        const isVisible = ref(props.visible);
        const { userIsDext, currentTeam, subscriptionStatus, subscriptionValid, isQuickbooksEnabled } =
            useXavierGlobals();
        const quickbooksEnabled = isQuickbooksEnabled();
        const isManualBilling = Boolean(currentTeam.isManualBilling);
        const { showChat } = useContactSupport();
        const { getCentralBillingUrl } = useCentralBilling();

        const isClientUpgrade = computed(() => {
            return Boolean(props.clientId);
        });

        const modalTitle = computed(() => {
            return isClientUpgrade.value ? 'Connect accounting software' : 'Add client';
        });

        const hasReachedLimit = computed(() => {
            if (!subscriptionValid) {
                return true;
            }

            return props.clientLimit > 0 && props.clientLimitReached;
        });

        const canAddDemo = computed(() => {
            return currentTeam.isTestTeam && !currentTeam.isDemo;
        });

        const isOnTrial = computed(() => {
            return subscriptionStatus === SubscriptionStatus.TRIAL;
        });

        const isLimitApproaching = computed(() => {
            if (props.clientLimit === props.clientCount) {
                return false;
            }

            return props.clientCount < props.clientLimit && props.clientCount >= props.clientLimit - 3;
        });

        watch(
            () => props.visible,
            (value) => {
                isVisible.value = value;
            }
        );

        watch(isVisible, (value) => {
            context.emit('visibility-change', value);
        });

        const onCentralBillingClick = () => {
            window.location.href = getCentralBillingUrl();
        };

        const onContactUsClick = () => {
            isVisible.value = false;
            showChat();
        };

        return {
            hasReachedLimit,
            canAddDemo,
            ClientProvider,
            demoClients: currentTeam.demoClients,
            isClientUpgrade,
            isVisible,
            modalTitle,
            unintegratedClientsEnabled: useIsFeatureEnabled(Feature.UNINTEGRATED),
            playgroundClientsEnabled: useIsFeatureEnabled(Feature.PLAYGROUND_CLIENTS),
            onCentralBillingClick,
            userIsDext,
            isOnTrial,
            isLimitApproaching,
            isManualBilling,
            onContactUsClick,
            subscriptionValid,
            getCentralBillingUrl,
            quickbooksEnabled,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.client-limit-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    text-align: center;
    width: 100%;
}

.client-limit {
    background-color: get-color(silver, medium);
    border-radius: 4px;
    font-size: pxtorem(15);
    margin: 0 4px;
    padding: 2px 4px 1px;
}

.provider-button {
    text-align: center;
    width: 274px;
}

.playground-button {
    height: 48px;
    line-height: pxtoem(20);
}

.demo-button {
    height: 60px;
    width: 274px;
}

.provider-text {
    display: inline-block;
    font-size: 1.4em;
}

.content-description {
    color: #647890;
    display: flex;
    font-size: pxtoem(14);
    font-weight: $font-weight-bold;
    letter-spacing: 0.02em;
    line-height: 120%;
    width: 100%;
}

.central-billing-link {
    color: #196acc;
    cursor: pointer;
}
</style>
