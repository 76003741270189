<template>
    <div :class="className" data-qa="health-score">
        <DIcon class="health-score__icon" :name="iconName" :size="size" :title="iconTitle" />
        <div class="health-score__copy"><span class="sr-only">Your health score is </span>{{ `${score}%` }}</div>
    </div>
</template>

<script>
import { InterimIcon as DIcon } from '@/components/Icon';

const scoreMap = {
    error: 35,
    warning: 70,
    success: 100,
};

export default {
    name: 'HealthScore',
    components: {
        DIcon,
    },
    props: {
        score: {
            default: 0,
            required: false,
            type: Number,
        },

        size: {
            default: '25px',
            required: false,
            type: [Number, String],
        },
    },
    computed: {
        className() {
            return {
                'health-score': true,
                'health-score--error': this.isError,
                'health-score--warning': this.isWarning,
                'health-score--success': this.isSuccess,
            };
        },

        iconName() {
            if (this.isError) return 'love-heart-filled';
            if (this.isWarning || this.isSuccess) return 'love-heart-thick';

            return null;
        },

        iconTitle() {
            if (this.isError) return 'Red heart icon representing poor health score.';
            if (this.isWarning) return 'Amber heart icon representing ok health score.';
            if (this.isSuccess) return 'Green heart icon representing high health score.';

            return null;
        },

        isError() {
            return this.score <= scoreMap.error;
        },
        isWarning() {
            return this.score > scoreMap.error && this.score <= scoreMap.warning;
        },
        isSuccess() {
            return this.score > scoreMap.warning && this.score <= scoreMap.success;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.health-score {
    align-items: center;
    display: flex;
}

.health-score__icon {
    margin-right: 5px;

    .health-score--error & {
        color: get-color(red);
    }

    .health-score--warning & {
        color: get-color(amber);
    }

    .health-score--success & {
        color: get-color(green);
    }
}
</style>
