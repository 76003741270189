import InsightsDashboardService from '@/services/Api/InsightsDashboardService';

import type { ActionTree } from 'vuex';
import type { AugmentedActionContext, RootState } from '@/store/types';
import type { LockDateActivityGetters } from '@/store/modules/insightsDashboard/lockDateActivity/getters';
import type { LockDateActivityMutations } from '@/store/modules/insightsDashboard/lockDateActivity/mutations';
import { LockDateActivityMutationsEnum } from '@/store/modules/insightsDashboard/lockDateActivity/mutations';
import type {
    LockDateActivityState,
    WidgetResultsState,
} from '@/store/modules/insightsDashboard/lockDateActivity/types';

type ActionContext = AugmentedActionContext<LockDateActivityState, LockDateActivityGetters, LockDateActivityMutations>;

export enum LockDateActivityActionsEnum {
    FETCH_LOCK_DATE_ACTIVITY_WIDGET_DATA = 'fetchLockDateActivityWidgetData',
}

export interface LockDateActivityActions {
    [LockDateActivityActionsEnum.FETCH_LOCK_DATE_ACTIVITY_WIDGET_DATA](context: ActionContext): void;
}

export const actions: ActionTree<LockDateActivityState, RootState> & LockDateActivityActions = {
    async [LockDateActivityActionsEnum.FETCH_LOCK_DATE_ACTIVITY_WIDGET_DATA]({ commit }) {
        commit(LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING, true);

        const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

        await InsightsDashboardService.getLockDateActivity(practiceCrn).then((response) => {
            const { results }: { results: WidgetResultsState } = response;

            commit(LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_RESULTS, results);
            commit(LockDateActivityMutationsEnum.SET_LOCK_DATE_ACTIVITY_WIDGET_LOADING, false);
        });
    },
};
