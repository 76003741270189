import InsightsDashboardService from '@/services/Api/InsightsDashboardService';
import { ReconciliationActivityMutationsEnum } from '@/store/modules/insightsDashboard/reconciliationActivity/mutations';

import type { ActionTree } from 'vuex';
import type {
    ReconciliationActivityState,
    WidgetResultsState,
} from '@/store/modules/insightsDashboard/reconciliationActivity/types';
import type { ReconciliationActivityMutations } from '@/store/modules/insightsDashboard/reconciliationActivity/mutations';
import type { AugmentedActionContext, RootState } from '@/store/types';
import type { ReconciliationActivityGetters } from '@/store/modules/insightsDashboard/reconciliationActivity/getters';
import type { Client } from '@/store/modules/client/types/Client';

type ActionContext = AugmentedActionContext<
    ReconciliationActivityState,
    ReconciliationActivityGetters,
    ReconciliationActivityMutations
>;

export enum ReconciliationActivityActionsEnum {
    FETCH_RECONCILIATION_ACTIVITY_WIDGET_DATA = 'fetchReconciliationActivityWidgetData',
    FETCH_RECONCILIATION_ACTIVITY_DETAILS_DATA = 'fetchReconciliationActivityDetails',
}

export interface ReconciliationActivityActions {
    [ReconciliationActivityActionsEnum.FETCH_RECONCILIATION_ACTIVITY_WIDGET_DATA](context: ActionContext): void;
    [ReconciliationActivityActionsEnum.FETCH_RECONCILIATION_ACTIVITY_DETAILS_DATA](context: ActionContext): void;
}

export const actions: ActionTree<ReconciliationActivityState, RootState> & ReconciliationActivityActions = {
    async [ReconciliationActivityActionsEnum.FETCH_RECONCILIATION_ACTIVITY_WIDGET_DATA]({ commit }) {
        commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING, true);

        const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

        await InsightsDashboardService.getReconciliationActivity(practiceCrn).then((response) => {
            const { results }: { results: WidgetResultsState } = response;

            commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_RESULTS, results);
            commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_WIDGET_LOADING, false);
        });
    },

    async [ReconciliationActivityActionsEnum.FETCH_RECONCILIATION_ACTIVITY_DETAILS_DATA]({ commit, rootGetters }) {
        commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING, true);

        const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

        await InsightsDashboardService.getReconciliationActivityDetails(practiceCrn).then((response) => {
            const clientList = rootGetters['legacyClients/clientList'];

            const { results }: {
                results: {
                    client_id: string;
                    last_reconciled_date: string;
                    bank_account: string;
                }[];
            } = response;

            const mapped = results.map((record) => {
                return {
                    client_name: clientList.find((client: Client) => client.id === record.client_id)?.name,
                    bank_account: record.bank_account,
                    last_reconciled_date: record.last_reconciled_date,
                };
            });

            commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_LOADING, false);
            commit(ReconciliationActivityMutationsEnum.SET_RECONCILIATION_ACTIVITY_DETAILS_RESULTS, mapped);
        });
    },
};
