<template>
    <Modal v-model="isVisible" name="Client Limit">
        <OnTrialContent v-if="isOnTrial" :client-limit="clientLimit" :limit-approaching="limitApproaching" />

        <UnintegratedClientContent v-else-if="clientProvider" />

        <DefaultContent
            v-else
            :client-limit="clientLimit"
            :is-dext="isDext"
            :is-manual-billing="isManualBilling"
            :limit-approaching="limitApproaching"
        />

        <ModalFooter>
            <template #right>
                <VButton name="Client Limit Modal Cancel" @click="isVisible = false">Cancel</VButton>

                <VButton
                    v-if="isDext"
                    color="primary"
                    name="Client Limit Modal Redirect To Central Subscriptions"
                    @click="onCentralBillingClick"
                >
                    Add more clients
                </VButton>

                <VButton
                    v-else-if="isManualBilling"
                    color="primary"
                    name="Client Limit Modal Contact us"
                    @click="onContactUsClick"
                >
                    Contact us
                </VButton>

                <VButton
                    v-else
                    color="primary"
                    link
                    :name="`Client Limit Modal ${isOnTrial ? 'Add Payment Details' : 'Upgrade Subscription'}`"
                    :to="{ name: 'team.subscription' }"
                    @click.native="onBillingClick"
                >
                    {{ `${isOnTrial ? 'Add payment details' : 'Add more clients'}` }}
                </VButton>
            </template>
        </ModalFooter>
    </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import * as PropTypes from 'vue-types';

import { Modal, ModalFooter } from '@/components/Modal';
import useCentralBilling from '@/composables/useCentralBilling';
import { useContactSupport } from '@/composables/useContactSupport';
import SubscriptionStatus from '@/enums/SubscriptionStatus';

import DefaultContent from './DefaultContent.vue';
import OnTrialContent from './OnTrialContent.vue';
import UnintegratedClientContent from './UnintegratedClientContent.vue';

export default defineComponent({
    props: {
        clientCount: PropTypes.number().isRequired,
        clientLimit: PropTypes.number().isRequired,
        clientProvider: PropTypes.string(),
        isManualBilling: PropTypes.bool().isRequired,
        isDext: PropTypes.bool().isRequired,
        subscriptionStatus: PropTypes.string<SubscriptionStatus>().isRequired,
        visible: PropTypes.bool().def(false),
    },

    components: { Modal, ModalFooter, DefaultContent, OnTrialContent, UnintegratedClientContent },

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    setup(props, context) {
        const { showChat } = useContactSupport();
        const { getCentralBillingUrl } = useCentralBilling();
        const isVisible = ref(props.visible);

        const isOnTrial = computed(() => {
            return props.subscriptionStatus === SubscriptionStatus.TRIAL;
        });

        const limitApproaching = computed(() => {
            return props.clientCount < props.clientLimit && props.clientCount >= props.clientLimit - 3;
        });

        watch(
            () => props.visible,
            (value) => {
                isVisible.value = value;
            }
        );

        watch(isVisible, (value) => {
            context.emit('visibility-change', value);
        });

        const onCentralBillingClick = () => {
            window.location.href = getCentralBillingUrl();
        };

        return {
            isVisible,
            limitApproaching,
            isOnTrial,
            onCentralBillingClick,
            showChat,
        };
    },

    methods: {
        onBillingClick() {
            this.isVisible = false;
        },

        onContactUsClick() {
            this.isVisible = false;
            this.showChat();
        },
    },
});
</script>
