import axios from 'axios';
import { route } from 'ziggy-js';

import logger from '@/utils/logger';
import { Ziggy } from '@/ziggy';

import type { AxiosResponse } from 'axios';
import type { RouteList } from 'ziggy-js';
import type { ZodSchema } from 'zod';

type Data = null | Record<string, unknown>;

type T = RouteList[];

/**
 * The BaseApiService is used as a basis for all our API services. It handles
 * validating the request and response payloads, as well as formatting the
 * response (removing the need for `data.data` everywhere).
 *
 * This service should not be included in any component or code, other than
 * an API Service class.
 */
class BaseApiService {
    private defaultRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    /**
     * Make a GET request to the API endpoint.
     * Will validate request params if `this.requestSchema !== null`.
     * Will validate response payload if `this.responseSchema !== null`.
     *
     * @param route Laravel route name
     * @param Laravel route params
     * @param Request query parameters
     * @param Axios request config
     */
    async get<TResponse, TRequestParams extends { [key: string]: unknown }>(
        route: keyof RouteList,
        routeParams: Record<string, unknown> | undefined = undefined,
        queryParams?: TRequestParams,
        config = {}
    ): Promise<TResponse> {
        const endpoint = this._getEndpointFromRouteName(route, routeParams ?? {});

        return axios
            .get<TResponse>(endpoint, {
                params: queryParams ?? null,
                ...this.defaultRequestConfig,
                ...config,
            })
            .then(this._formatResponse);
    }

    /**
     * Make a POST request to the API endpoint.
     * Will validate request data if `this.requestSchema !== null`.
     * Will validate response payload if `this.responseSchema !== null`.
     *
     * @param {String} route Laravel route name
     * @param {Object} [routeParams={}] Laravel route params
     * @param {Object|null} [data=null] Any data to send with the request
     * @param {AxiosRequestConfig} [config={}] Axios request config
     * @returns Promise
     */
    post<TResponse, TRequestData extends Data = Data>(
        route: keyof RouteList,
        routeParams: Record<string, unknown> | undefined = undefined,
        data?: TRequestData,
        config = {}
    ): Promise<TResponse> {
        const endpoint = this._getEndpointFromRouteName(route, routeParams);

        return axios
            .post<TResponse>(endpoint, data, { ...this.defaultRequestConfig, ...config })
            .then((response) => this._formatResponse(response));
    }

    /**
     * Make a PUT request to the API endpoint.
     * Will validate request data if `this.requestSchema !== null`.
     * Will validate response payload if `this.responseSchema !== null`.
     *
     * @param {String} route Laravel route name
     * @param {Object} [routeParams={}] Laravel route params
     * @param {Object|null} [data=null] Any data to send with the request
     * @param {AxiosRequestConfig} [config={}] Axios request config
     * @returns Promise
     */
    put(route: keyof RouteList, routeParams = {}, data = null, config = {}) {
        const endpoint = this._getEndpointFromRouteName(route, routeParams);

        return axios
            .put(endpoint, data, { ...this.defaultRequestConfig, ...config })
            .then((response) => this._formatResponse(response));
    }

    /**
     * Make a DELETE request to the API endpoint.
     * Will validate request data if `this.requestSchema !== null`.
     * Will validate response payload if `this.responseSchema !== null`.
     *
     * @param String route Laravel route name
     * @param Object Laravel route params
     * @param data Any data to send with the request
     * @param config Axios request config
     */
    delete<TResponse, TRequestData extends Data>(
        route: keyof RouteList,
        routeParams: Record<string, unknown> | undefined = undefined,
        data?: TRequestData,
        config = {}
    ): Promise<TResponse> {
        const endpoint = this._getEndpointFromRouteName(route, routeParams);

        return axios
            .delete<TResponse>(endpoint, {
                ...this.defaultRequestConfig,
                ...config,
                data,
            })
            .then((response) => this._formatResponse(response));
    }

    /**
     * Format the request response payload.
     *
     * @param {AxiosResponse} response
     * @returns Any
     */
    _formatResponse<Response>(response: AxiosResponse<Response>): Response {
        return response.data;
    }

    _getEndpointFromRouteName(routeName: keyof RouteList, params?: RouteParamsWithQueryOverload) {
        return route(routeName, params ?? {}, false, Ziggy);
    }

    /**
     *
     * @param data Input data to validate against schema
     * @param options
     * @param options.schema The schema to validate the data against
     * @param {boolean=} [options.safeParse=undefined]
     *     Should we use the Zod safeParse method?
     *     If we do, then we won't throw an error, instead we will log it.
     * @returns
     */
    validateResponseData<TResponseData extends object, TSchema extends ZodSchema<TResponseData>>(
        data: TResponseData,
        options: {
            safeParse?: boolean;
            schema: TSchema;
        }
    ) {
        if (!options.safeParse) {
            return options.schema.parse(data);
        }

        const validationResult = options.schema.safeParse(data);

        if (validationResult.error) {
            logger.error(validationResult.error);

            return data;
        }

        return validationResult.data;
    }
}

export default BaseApiService;
