<template>
    <Component
        :is="tag"
        v-bind="attrs"
        :class="className"
        :data-qa="dataQa"
        v-on="!disabled && listeners"
        @[clickListenerName]="onClick"
        v-open-in-new-tab="shouldOpenNewTab"
    >
        <VIcon decorative name="external-link-thick" size="1.2em" v-if="external" />
        <slot />
    </Component>
</template>

<script>
import kebabCase from 'lodash-es/kebabCase';
import omit from 'lodash-es/omit';
import PropTypes from 'vue-types';

export default {
    name: 'DLink',

    inject: ['analyticsProvider'],

    props: {
        /**
         * Set the link to disabled. This will re-render the component as a
         * `span` HTMLElement.
         *
         * @param {Boolean} disabled
         */
        disabled: PropTypes.bool.def(false),

        /**
         * Prepend an "external" icon to the Link body.
         *
         * @param {Boolean} disabled
         */
        external: PropTypes.bool.def(false),

        /**
         * Href to external website page. Required if `$props.to` is not set.
         *
         * @param {String} href
         */
        href: PropTypes.string,

        /**
         * Should the link open in a new tab.
         *
         * @param {Boolean} newTab
         */
        newTab: PropTypes.bool.def(false),

        /**
         * Assign a name to the modal for QA and analytics.
         *
         * @prop {String} name
         */
        name: PropTypes.string.isRequired,

        /**
         * Path to internal page. Required if `$props.href` is not set.
         *
         * @param {Object|String} to
         */
        to: PropTypes.oneOfType([Object, String]),
    },

    computed: {
        className() {
            return {
                link: true,
                'link--disabled': this.disabled,
                'link--external': this.external,
                'link--new-tab': !this.to && this.newTab,
                'link--router': Boolean(this.to),
            };
        },

        attrs() {
            const attrs = { ...this.$attrs, dataQa: this.dataQa };

            if (this.href) {
                attrs.href = this.href;
            }

            if (this.to) {
                attrs.to = this.to;
            }

            return attrs;
        },

        /**
         * Switch between `click.native` and regular `click` listeners depending
         * on whether we are using the `router-link` component.
         *
         * The `click.native` listener is only valid on Vue components and not
         * native DOM elements.
         *
         * @var clickListenerName
         */
        clickListenerName() {
            if (this.tag === 'router-link') return 'click.native';

            return 'click';
        },

        listeners() {
            return omit(this.$listeners, ['click']);
        },

        dataQa() {
            return `button-${kebabCase(this.name)}`;
        },

        shouldOpenNewTab() {
            return this.tag === 'a' && this.newTab;
        },

        /**
         * Get the required HTML tag for the component.
         *
         * @returns {String}
         */
        tag() {
            if (this.disabled) return 'span';
            if (this.to) return 'router-link';

            return 'a';
        },
    },

    methods: {
        onClick(event) {
            if (this.disabled) {
                return;
            }

            const url = this.to ?? this.href;
            const isExternal = this.tag === 'a';

            this.analyticsProvider.trackLinkClick(this.name, url, isExternal);
            this.$emit('click', event);
        },

        validateLocationProp() {
            if (!this.href && !this.to) {
                throw new Error('Invalid prop: One of "href" or "to" props are required.');
            }
        },
    },

    created() {
        this.validateLocationProp();
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.link {
    @include focus-outline($color: get-color(blue));

    color: get-color(blue);
    font-family: $typeface-roboto;
    font-weight: $font-weight-bold;
    text-decoration: none;

    &:hover {
        color: get-color(blue);
        text-decoration: underline;
    }
}

.link--disabled,
.link--disabled:hover {
    color: get-color(gray, medium);
    cursor: not-allowed;
    text-decoration: none;
}
</style>
