import Vue from 'vue';

import { WorkflowStatus } from '@/enums/WorkflowStatus';
import InsightsDashboardService from '@/services/Api/InsightsDashboardService';

import * as types from './types';

export default {
    state: {
        flowsDue: {
            widget: {
                isLoading: true,
                results: {
                    overdue: {
                        [WorkflowStatus.IN_PROGRESS]: 0,
                        [WorkflowStatus.NOT_STARTED]: 0,
                        [WorkflowStatus.UNDER_REVIEW]: 0,
                    },
                    due_within_7_days: {
                        [WorkflowStatus.IN_PROGRESS]: 0,
                        [WorkflowStatus.NOT_STARTED]: 0,
                        [WorkflowStatus.UNDER_REVIEW]: 0,
                    },
                    upcoming: {
                        [WorkflowStatus.IN_PROGRESS]: 0,
                        [WorkflowStatus.NOT_STARTED]: 0,
                        [WorkflowStatus.UNDER_REVIEW]: 0,
                    },
                },
            },
        },
    },

    getters: {
        flowsDueWidgetLoading: (state) => state.flowsDue.widget.isLoading,
        flowsDueWidgetData: (state) => state.flowsDue.widget.results,
    },

    actions: {
        async fetchFlowsDueWidgetData({ commit }, isLoadingDataForEntireTeam) {
            commit(types.SET_FLOWS_DUE_WIDGET_LOADING, true);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const searchParams = new URLSearchParams([['scope', isLoadingDataForEntireTeam ? 'forTeam' : 'forUser']]);

            await InsightsDashboardService.getFlowsDue(practiceCrn, searchParams).then((response) => {
                commit(types.SET_FLOWS_DUE_WIDGET_RESULTS, response.results);
                commit(types.SET_FLOWS_DUE_WIDGET_LOADING, false);
            });
        },
    },

    mutations: {
        [types.SET_FLOWS_DUE_WIDGET_LOADING](state, isLoading) {
            Vue.set(state.flowsDue.widget, 'isLoading', isLoading);
        },
        [types.SET_FLOWS_DUE_WIDGET_RESULTS](state, results) {
            const overdue = {
                ...{
                    [WorkflowStatus.IN_PROGRESS]: 0,
                    [WorkflowStatus.NOT_STARTED]: 0,
                    [WorkflowStatus.UNDER_REVIEW]: 0,
                },
                ...results.overdue,
            };
            const dueWithin7days = {
                ...{
                    [WorkflowStatus.IN_PROGRESS]: 0,
                    [WorkflowStatus.NOT_STARTED]: 0,
                    [WorkflowStatus.UNDER_REVIEW]: 0,
                },
                ...results.due_within_7_days,
            };
            const upcoming = {
                ...{
                    [WorkflowStatus.IN_PROGRESS]: 0,
                    [WorkflowStatus.NOT_STARTED]: 0,
                    [WorkflowStatus.UNDER_REVIEW]: 0,
                },
                ...results.upcoming,
            };

            Vue.set(state.flowsDue.widget.results, 'overdue', overdue);
            Vue.set(state.flowsDue.widget.results, 'due_within_7_days', dueWithin7days);
            Vue.set(state.flowsDue.widget.results, 'upcoming', upcoming);
        },
    },
};
