<template>
    <a
        :class="['sync-button secondary-nav-link', { 'sync-button--alert': syncRequired, disabled: !canSync }]"
        :disabled="!canSync"
        :title="syncText"
        @click="attemptSync"
    >
        <VIcon class="sync-icon" name="sync" :spin="isSyncing" />{{ isSyncing ? syncingText : syncText }}
    </a>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

const { mapActions } = createNamespacedHelpers('legacyClients');
const { mapActions: mapImportsActions } = createNamespacedHelpers('imports');

export default {
    name: 'SyncButton',
    props: {
        clientId: { type: String, required: true },
        flowId: { type: Number, default: null, required: false },
        stage: { type: String, default: 'all', required: false },
        params: { type: Object, default: () => {} },
        mode: {
            type: String,
            default: 'insight',
            validator: (value) => ['insight', 'dashboard', 'vat', 'health', 'flow', 'all', 'reporting'].includes(value),
        },
        paramsValid: { type: Boolean, default: true, required: false },
        syncAllowed: { type: Boolean, default: true, required: false },
        syncText: { type: String, default: 'Recalculate', required: false },
        syncingText: { type: String, default: 'Recalculating...', required: false },
        promptForSync: { type: Boolean, default: false, required: false },
    },
    data() {
        return {
            lastRunParams: _.clone(this.$props.params),
        };
    },
    watch: {
        params(newValue) {
            if (!this.lastRunParams) {
                this.lastRunParams = newValue;
            }

            if (this.paramsHaveChanged) {
                window.Bus.$emit('paramsHaveChanged', { clientId: this.clientId, insight: this.stage });
            }
        },
        isSyncing(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                this.$emit('complete', { mode: this.mode, flowId: this.flowId });
            }
        },
    },
    computed: {
        ...mapGetters({
            clientCanSync: 'legacyClients/clientCanSync',
            isClientImporting: 'imports/isClientImporting',
        }),
        importParams() {
            return {
                id: this.clientId,
                stage: this.stage,
                params: this.params,
                mode: this.mode,
                flowId: this.flowId,
            };
        },
        syncRequired() {
            return this.promptForSync || this.paramsHaveChanged;
        },
        paramsHaveChanged() {
            return this.params && !_.isEqual(this.params, this.lastRunParams);
        },
        isSyncing() {
            return this.isClientImporting(this.clientId, this.mode, this.stage);
        },
        canSync() {
            return this.syncAllowed && this.clientCanSync(this.clientId, this.mode, this.stage);
        },
    },
    methods: {
        ...mapActions(['syncClient']),
        ...mapImportsActions(['CREATE_IMPORT_PLACEHOLDER']),
        attemptSync() {
            if (!this.paramsValid) {
                this.$toaster.warning('Invalid insight parameters - please correct and try again');
            } else if (this.canSync) {
                this.syncClient(this.importParams);
                this.CREATE_IMPORT_PLACEHOLDER({ clientId: this.clientId, mode: this.mode, stage: this.stage });

                this.lastRunParams = this.params;
            }
        },
    },
};
</script>

<style lang="scss">
@import 'style/dext/includes';

.btn.secondary-nav-link.btn-outline-primary.sync-button,
.btn.secondary-nav-link.btn-primary.sync-button {
    svg {
        display: inline-block;
        margin-right: 8px;
    }
}

.sync-button {
    display: inline-block;

    &.disabled {
        cursor: default !important;
        opacity: 0.6;
    }
}

.sync-button--alert {
    color: get-color(amber) !important;

    &:hover .svg-inline--fa {
        color: get-color(amber) !important;
    }
}

.sync-icon {
    margin-right: 5px;
}
</style>
