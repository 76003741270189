import moment from 'moment';
import Vue, { nextTick } from 'vue';

import {
    bankReconciliationCheckRef,
    completeWorkflowCheckRef,
    completeWorkflowRef,
    duplicatedTransactionsCheckComponentRef,
    duplicatedTransactionsCheckRef,
    firstDuplicatedTransactionRef,
    focusSidebarGroupRef,
    markCheckStatusRef,
    monthlyBookkeepingWorkflowRef,
    syncButtonRef,
    workflowListSidebarItemRef,
} from '@/composables/tours/useProductTourTemplateRefs';
import { ProductTours } from '@/enums/ProductTours';
import store from '@/store';
import { useProductTourStore } from '@/store/productTour';
import { useTeamsStore } from '@/store/teams';

import type { ProductTourValue } from '@/enums/ProductTours';
import type { ProductTourStepSchema } from '@/schemas/app/ProductTour.schema';
import type { ProductTour, ProductTourStep } from '@/store/productTour/types';

/* eslint-disable sort-keys */
const getMonthlyBookkeepingWorkflowLocation = function (clientSlug: string) {
    const teamStore = useTeamsStore();

    return async function () {
        const flow = store.getters['flows/flowList'].find((item) => {
            return item.name === 'Monthly Bookkeeping' && item.clientSlug === clientSlug;
        });

        const { rbExternalId } = teamStore.currentTeam;

        return {
            name: 'client.flow.details',
            params: {
                clientSlug: clientSlug,
                flowSlug: flow.slug,
                teamRbExternalId: rbExternalId.toString(),
            },
        };
    };
};

const updateMonthlyBookkeepingWorkflowLastImportToHideForceRecalculatedModal = async () => {
    await nextTick();

    const currentFlowId = store.state.flows.currentFlowId;

    if (!currentFlowId) {
        // current flow is not loaded yet
        return false;
    }

    // The force recalculate modal compares last import and flow completion dates.
    // Due to race conditions the flow gets calculated just before the import is completed.
    store.state.flows.flows[currentFlowId].lastCalculated = moment().add(1, 'days');

    return true;
};

const defaultNextButton = {
    text: 'Next',
    action: () => {
        const productTourStore = useProductTourStore();

        productTourStore.next();
    },
    classes: 'dext-tour-next',
};

const productTourStepsExtraData = (clientSlug: string) => [
    {
        key: ProductTours.MONTHLY_BOOKKEEPING,
        initialize: async () => {
            await store.dispatch('flows/loadFlowList');
            // We do not want to show the workflows help modal
            Vue.prototype.$settings.set('help.flow-mode', true);
        },
        steps: [
            {
                id: '1',
                location: async function () {
                    return {
                        name: 'team.home',
                        params: {},
                    };
                },
                initialize: async function () {
                    // wait for 3 ticks because focusSidebarGroupRef waits for two ticks to set visibility to false on route change
                    await nextTick();
                    await nextTick();
                    await nextTick();
                    focusSidebarGroupRef.value?.open();

                    return true;
                },
                attachTo: {
                    element: workflowListSidebarItemRef.value?.$el,
                    on: 'right',
                },
                buttons: [defaultNextButton],
                resumable: true,
            },
            {
                id: '2',
                location: function () {
                    return { name: 'team.focus' };
                },
                initialize: async () => true,
                attachTo: {
                    element: () => monthlyBookkeepingWorkflowRef.value?.$el,
                    on: 'bottom',
                },
                buttons: [defaultNextButton],
                resumable: true,
            },
            {
                id: '3',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: updateMonthlyBookkeepingWorkflowLastImportToHideForceRecalculatedModal,
                attachTo: {
                    element: () => duplicatedTransactionsCheckRef.value,
                    on: 'bottom',
                },
                buttons: [defaultNextButton],
                resumable: true,
                scrollTo: false,
            },
            {
                id: '4',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: async function () {
                    if (!duplicatedTransactionsCheckComponentRef.value?.expanded) {
                        duplicatedTransactionsCheckComponentRef.value?.expandToggle();
                    }

                    return true;
                },
                attachTo: {
                    element: () =>
                        firstDuplicatedTransactionRef.value && firstDuplicatedTransactionRef.value[0]
                            ? firstDuplicatedTransactionRef.value[0].$el
                            : null,
                    on: 'top',
                },
                buttons: [defaultNextButton],
                resumable: false,
                scrollTo: false,
            },
            {
                id: '5',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: async () => true,
                attachTo: {
                    element: () => syncButtonRef.value?.$el,
                    on: 'top',
                },
                buttons: [defaultNextButton],
                resumable: true,
            },
            {
                id: '6',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: async () => true,
                attachTo: {
                    element: () => markCheckStatusRef.value,
                    on: 'left',
                },
                scrollTo: false,
                buttons: [defaultNextButton],
                resumable: true,
            },
            {
                id: '7',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: async function () {
                    completeWorkflowCheckRef.value?.$el.click();

                    return true;
                },
                attachTo: {
                    element: () => bankReconciliationCheckRef.value,
                    on: 'top',
                },
                buttons: [defaultNextButton],
                resumable: true,
            },
            {
                id: '8',
                location: getMonthlyBookkeepingWorkflowLocation(clientSlug),
                initialize: async function () {
                    completeWorkflowCheckRef.value?.$el.click();

                    return true;
                },
                attachTo: {
                    element: () => completeWorkflowRef.value?.$el,
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Finish',
                        action: () => {
                            const productTourStore = useProductTourStore();

                            productTourStore.complete();
                        },
                        classes: 'dext-tour-next',
                    },
                ],
                resumable: false,
            },
        ],
    },
];

export async function expandProductTourStepsWithMetadata(
    productTourKey: ProductTourValue,
    productTourSteps: Array<ProductTourStepSchema>,
    clientSlug: string
): Promise<ProductTour> {
    const productTourExtraData = productTourStepsExtraData(clientSlug).find((item) => item.key === productTourKey);

    const productTourData = {
        key: productTourKey,
        initialize: async () => {},
        steps: productTourSteps as Array<ProductTourStep>,
    };

    if (productTourExtraData) {
        productTourData.initialize = productTourExtraData.initialize;
        for (let i = 0; i < productTourData.steps.length; i++) {
            const productTourStepsExtraData = productTourExtraData.steps.find(
                (item) => item.id === productTourSteps[i].id
            );

            if (productTourStepsExtraData) {
                productTourData.steps[i] = { ...productTourSteps[i], ...productTourStepsExtraData };
            }
        }
    }

    return productTourData;
}
