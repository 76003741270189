import checkMessages from '@shared/translations/messages/en-GB/checks.yml';
import commonMessages from '@shared/translations/messages/en-GB/common.yml';
import componentMessages from '@shared/translations/messages/en-GB/components.yml';
import dashboardsMessages from '@shared/translations/messages/en-GB/dashboards.yml';
import errorMessages from '@shared/translations/messages/en-GB/errors.yml';
import helpMessages from '@shared/translations/messages/en-GB/help.yml';
import insightsMessages from '@shared/translations/messages/en-GB/insights.yml';
import linkMessages from '@shared/translations/messages/en-GB/links.yml';
import taxMessages from '@shared/translations/messages/en-GB/tax.yml';
import validationMessages from '@shared/translations/messages/en-GB/validations.yml';
import workflowsMessages from '@shared/translations/messages/en-GB/workflows.yml';
import Element from 'element-ui';
import elementUiMessagesEn from 'element-ui/lib/locale/lang/en';
import elementUiMessagesFr from 'element-ui/lib/locale/lang/fr';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

const messages = {
    ...workflowsMessages,
    ...checkMessages,
    ...dashboardsMessages,
    ...insightsMessages,
    ...componentMessages,
    ...elementUiMessagesEn,
    ...helpMessages,
    ...linkMessages,
    ...taxMessages,
    ...validationMessages,
    ...commonMessages,
    ...errorMessages,
};

let i18n: VueI18n;

const defaultLocale = 'en-GB';
const loadedLanguages = ['en-GB'];

/**
 * This is the i18n instance that will be used throughout the application.
 *
 * @link https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
 * @link https://kazupon.github.io/vue-i18n/guide/formatting.html
 */
function setup() {
    i18n = new VueI18n({
        fallbackLocale: defaultLocale,
        locale: defaultLocale,
        messages: { 'en-GB': messages },
    });
}

function setI18nLanguage(lang: string) {
    if (i18n.locale !== lang) {
        i18n.locale = lang;
        document.querySelector('html')?.setAttribute('lang', lang);
    }

    return lang;
}

const translationsInstance = {
    loadedLanguages: loadedLanguages,
    setLocale: setI18nLanguage,
    setup,
    get vueI18n() {
        return i18n;
    },
};

function loadLanguageAsync(lang: string) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(`@/translations/messages/${lang}.json`).then((messages) => {
        let loadedLangMessages = { ...messages };

        if (lang === 'fr') {
            loadedLangMessages = { ...loadedLangMessages, ...elementUiMessagesFr };
        }

        i18n.setLocaleMessage(lang, loadedLangMessages);
        loadedLanguages.push(lang);

        // Localise element-ui
        Vue.use(Element, {
            i18n: (key: string, value: VueI18n.Values | undefined) => i18n.t(key, value),
        });

        return setI18nLanguage(lang);
    });
}

export { defaultLocale, translationsInstance as i18n, loadLanguageAsync };
