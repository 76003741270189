import InsightsDashboardService from '@/services/Api/InsightsDashboardService';

import type { ActionTree } from 'vuex';
import type {
    TotalTransactionsState,
    WidgetResultsState,
} from '@/store/modules/insightsDashboard/totalTransactions/types';
import type { TotalTransactionsMutations } from '@/store/modules/insightsDashboard/totalTransactions/mutations';
import { TotalTransactionsMutationsEnum } from '@/store/modules/insightsDashboard/totalTransactions/mutations';
import type { TotalTransactionsGetters } from '@/store/modules/insightsDashboard/totalTransactions/getters';
import type { AugmentedActionContext, RootState } from '@/store/types';

type ActionContext = AugmentedActionContext<
    TotalTransactionsState,
    TotalTransactionsGetters,
    TotalTransactionsMutations
>;

export enum TotalTransactionsActionsEnum {
    FETCH_TOTAL_TRANSACTIONS_WIDGET_DATA = 'fetchTotalTransactionsWidgetData',
    FETCH_TOTAL_TRANSACTIONS_DETAILS_DATA = 'fetchTotalTransactionsDetails',
}

export interface TotalTransactionsActions {
    [TotalTransactionsActionsEnum.FETCH_TOTAL_TRANSACTIONS_WIDGET_DATA](context: ActionContext): void;
    [TotalTransactionsActionsEnum.FETCH_TOTAL_TRANSACTIONS_DETAILS_DATA](context: ActionContext): void;
}

export const actions: ActionTree<TotalTransactionsState, RootState> & TotalTransactionsActions = {
    async [TotalTransactionsActionsEnum.FETCH_TOTAL_TRANSACTIONS_WIDGET_DATA]({ commit }) {
        commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_LOADING, true);

        const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

        await InsightsDashboardService.getTotalTransactions(practiceCrn).then((response) => {
            const { results }: { results: WidgetResultsState } = response;

            commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_RESULTS_WIDGET_RESULTS, results);
            commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_WIDGET_LOADING, false);
        });
    },
    async [TotalTransactionsActionsEnum.FETCH_TOTAL_TRANSACTIONS_DETAILS_DATA]({ commit }) {
        commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_LOADING, true);

        const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

        await InsightsDashboardService.getTotalTransactionsDetails(practiceCrn).then((response) => {
            // const clientList = rootGetters['legacyClients/clientList'];

            const { results }: {
                // todo: results should describe the type of data returned from the API
                results: {}[];
            } = response;

            const mapped = results.map((record) => {
                return {
                    // todo: describe the type of this
                    ...record,
                };
            });

            commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_LOADING, false);
            commit(TotalTransactionsMutationsEnum.SET_TOTAL_TRANSACTIONS_DETAILS_RESULTS, mapped);
        });
    },
};
