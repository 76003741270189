/* Client Types
========================================================================== */
export const LOAD_CLIENTS_START = 'LOAD_CLIENTS_START';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const RESET_CLIENT_ERROR = 'RESET_CLIENT_ERROR';
export const RESET_CLIENT_STATE = 'RESET_CLIENT_STATE';
export const RESTORE_CLIENT = 'RESTORE_CLIENT';
export const SET_AUTH_EVENT_ID = 'SET_AUTH_EVENT_ID';

/* Xero User Types
========================================================================== */
export const LOAD_XERO_USERS_START = 'LOAD_XERO_USERS_START';
export const LOAD_XERO_USERS_SUCCESS = 'LOAD_XERO_USERS_SUCCESS';
export const LOAD_XERO_USERS_FAILURE = 'LOAD_XERO_USERS_FAILURE';
export const RESET_XERO_USERS_ERROR = 'RESET_XERO_USERS_ERROR';
export const RESET_XERO_USERS_STATE = 'RESET_XERO_USERS_STATE';
