export function isSmallScreen() {
    const SMALL_THRESHOLD = 800;

    return window.innerWidth < SMALL_THRESHOLD;
}

// Taken from https://github.com/mrded/is-url-external/blob/master/index.js
export function isUrlExternal(url: string) {
    const host = window.location.hostname;

    const linkHost = (function (url) {
        if (/^https?:\/\//.test(url)) {
            // Absolute URL.
            // The easy way to parse an URL, is to create <a> element.
            // @see: https://gist.github.com/jlong/2428561
            const parser = document.createElement('a');

            parser.href = url;

            return parser.hostname;
        } else {
            // Relative URL.
            return window.location.hostname;
        }
    })(url);

    return host !== linkHost;
}

/**
 * @deprecated Use getBrowserLocales instead
 */
export function getBrowserLocale() {
    return getBrowserLocales()[0];
}

/**
 * Get a list of the support locales / languages from the browser configuration.
 * Some browsers incorrectly report the locale when using the `Intl` API, so we
 * ue the navigator.languages instead.
 *
 * @param options
 * @link https://phrase.com/blog/posts/detecting-a-users-locale/
 */
export function getBrowserLocales(options = {}) {
    const opt = { languageCodeOnly: false, ...options };
    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

    if (!browserLocales?.length) {
        return ['en-GB'];
    }

    return browserLocales.map((locale) => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
    });
}

export function triggerDownload(filename: string, data: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;

    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
}
