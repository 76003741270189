import isEqual from 'lodash-es/isEqual';
import { defineStore } from 'pinia';

import ClientProvider from '@/enums/ClientProvider';
import { RequestIds } from '@/enums/RequestIds';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { expandProductTourStepsWithMetadata } from '@/providers/ProductTour/expandProductTourStepsWithMetadata';
import ProductTourService from '@/services/Api/ProductTourService';
import store from '@/store';
import { useRequestsStore } from '@/store/requests/requests.store';
import logger from '@/utils/logger';

import type { ProductTourState } from './types';
import type { ProductTourValue } from '@/enums/ProductTours';
import type { ProductTourSchema } from '@/schemas/app/ProductTour.schema';

const useProductTourStore = defineStore('productTour.store', {
    state(): ProductTourState {
        return {
            clientId: null,
            clientSlug: null,
            currentProductTour: null,
            currentProductTourKey: null,
            currentProductTourStep: null,
            currentProductTourStepIndex: 0,
            demoClientLoading: false,
            pendingProductTour: false,
            pendingProductTourStepIndex: null,
            productTourSteps: [],
            productTours: [],
            stepInitialized: false,
            tourCompleteModalVisible: false,
            welcomeModalViewed: false,
        };
    },

    persist: {
        paths: ['welcomeModalViewed'],
    },

    getters: {
        currentProductTourData: (state: ProductTourState) => {
            return (key: string) => {
                const foundProductTour = state.productTours.find(
                    (tour: ProductTourSchema) => tour.product_tour_key === key
                );

                if (!foundProductTour) {
                    return {
                        completed: false,
                        product_tour_step: 0,
                        product_tour_step_count: 0,
                    };
                }

                foundProductTour.completed = !!foundProductTour?.completed_at;

                return foundProductTour;
            };
        },
        isWelcomeModalViewed: (state: ProductTourState) => {
            return state.welcomeModalViewed;
        },
    },

    actions: {
        async fetch() {
            const requestStore = useRequestsStore();
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const response = await requestStore.makeRequest(
                () => {
                    return ProductTourService.fetchProductTours(practiceCrn);
                },
                {
                    requestId: RequestIds.FETCH_PRODUCT_TOURS,
                }
            );

            if (!response) return;
            this.productTours = response.data;
        },
        reset() {
            this.currentProductTourStepIndex = 0;
            this.currentProductTourStep = null;
            this.currentProductTourKey = null;
            this.productTourSteps = [];
        },
        async start(productTourKey: ProductTourValue | null, productTourStepIndex: number) {
            this.pendingProductTourStepIndex = productTourStepIndex;
            this.currentProductTourStep = null;
            this.stepInitialized = false;
            if (productTourKey === null) {
                return;
            }

            // Set current product tour data
            if (this.currentProductTourKey !== productTourKey) {
                await this.init(productTourKey);
            }

            if (this.demoClientLoading) {
                return;
            }

            this.pendingProductTour = false;
            // Set current product tour step
            await this.progress(productTourStepIndex);
            // Ensure that the environment is correctly setup for current tour
            await this.currentProductTour?.initialize();

            const currentProductTourStep = this.currentProductTour?.steps[productTourStepIndex];

            if (!currentProductTourStep) {
                return;
            }

            // Ensure we are on correct url
            const location = await currentProductTourStep.location();

            if (
                this.$router?.currentRoute.name !== location.name ||
                !isEqual(this.$router?.currentRoute.params, location.params)
            ) {
                await this.$router?.push(location);
            }

            // The useProductTourTemplateRefsComposable will initialize and show steps
            this.currentProductTourStep = currentProductTourStep;

            await this.initializeCurrentStep();
        },

        next() {
            // check if next product tour step index is valid
            this.start(this.currentProductTourKey, this.currentProductTourStepIndex + 1);
        },

        async initializeCurrentStep() {
            if (this.currentProductTourStep === null) {
                return;
            }

            // Ensure to load any necessary data and prepare the state or make any necessary modifications
            if (!this.stepInitialized) {
                this.stepInitialized = await this.currentProductTourStep.initialize();
            }
        },
        async resume(productTourKey: ProductTourValue, targetProductTourStepIndex: number | null = null) {
            // Set current product tour data
            if (this.currentProductTourKey !== productTourKey) {
                this.pendingProductTourStepIndex = targetProductTourStepIndex;
                await this.init(productTourKey);
            }

            if (this.demoClientLoading) {
                return;
            }

            let productTourStepIndex = this.pendingProductTourStepIndex ?? 0;

            if (targetProductTourStepIndex !== null) {
                productTourStepIndex = targetProductTourStepIndex;
            }

            let currentProductTourStep = null;

            // Let's check if we can find a step that is resumable, if not then go with the previous one until we reach the first one
            do {
                currentProductTourStep = this.currentProductTour?.steps[productTourStepIndex];
            } while (!currentProductTourStep?.resumable && productTourStepIndex-- > 0);

            // This probably means that the tour config is incorrect
            if (!currentProductTourStep) {
                return;
            }

            this.start(productTourKey, productTourStepIndex);
        },

        async init(productTourKey: ProductTourValue) {
            this.currentProductTourKey = productTourKey;
            this.pendingProductTour = true;
            this.$patch({ demoClientLoading: true });

            const data = {
                product_tour_key: this.currentProductTourKey,
            };

            const requestStore = useRequestsStore();
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const response = await requestStore.makeRequest(
                () => {
                    return ProductTourService.initProductTour(practiceCrn, data);
                },
                {
                    requestId: RequestIds.INIT_PRODUCT_TOUR,
                }
            );

            if (!response) return;

            const { client, clientId, product_tour_steps } = response;

            this.clientSlug = client;
            this.clientId = clientId;
            this.productTourSteps = product_tour_steps;
            this.currentProductTour = await expandProductTourStepsWithMetadata(
                productTourKey,
                product_tour_steps,
                client
            );
        },
        async progress(productTourStep: number) {
            if (!this.currentProductTourKey) {
                logger.error('Product tour key is not set');

                return;
            }

            this.currentProductTourStepIndex = productTourStep;

            const data = {
                product_tour_key: this.currentProductTourKey,
                product_tour_step: this.currentProductTourStepIndex,
            };

            const requestStore = useRequestsStore();
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await requestStore.makeRequest(() => ProductTourService.progressProductTour(practiceCrn, data), {
                requestId: RequestIds.PROGRESS_PRODUCT_TOUR,
            });
        },

        async complete() {
            // Guard against calling complete on a product tour that hasn't been started
            if (!this.currentProductTourKey) {
                logger.error('Product tour has not been instantiated yet.');

                return;
            }

            document.querySelector('.dext-shepherd-product-tour-cancel-icon')?.remove();

            const data = {
                product_tour_key: this.currentProductTourKey,
            };

            const requestStore = useRequestsStore();
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await requestStore.makeRequest(() => ProductTourService.completeProductTour(practiceCrn, data), {
                requestId: RequestIds.COMPLETE_PRODUCT_TOUR,
            });
            this.reset();

            const clientList = store.getters['legacyClients/clientList'];
            const nonPlaygroundClients = clientList.filter((client) => client.provider !== ClientProvider.PLAYGROUND);

            if (nonPlaygroundClients.length === 0) {
                this.tourCompleteModalVisible = true;
            } else {
                this.$router?.push({ name: 'team.home' });
            }
        },
        showTourCompleteModal() {
            this.tourCompleteModalVisible = true;
        },

        hideTourCompleteModal() {
            this.tourCompleteModalVisible = false;
        },
        setWelcomeModalViewed() {
            this.welcomeModalViewed = true;
        },
    },
});

export { useProductTourStore };
