import type { MetaStore } from '@/store/utils/createMeta';
import type { Import, ImportMode } from '@/store/modules/imports/types/Import';
import type { AugmentedActionContext } from '@/store/types';
import type { ClientId } from '@/store/modules/client/types/Client';

export interface ImportStageUpdatedData {
    clientId: ClientId;
    importId: number;
    stage: string;
    recordCount: number;
}

export interface ImportsStageCompletedData {
    clientId: ClientId;
    importId: number;
    stage: string;
}

export enum ImportsActionTypes {
    LOAD_CLIENT_IMPORTS = 'LOAD_CLIENT_IMPORTS',
    UPDATE_IMPORT_STAGE = 'UPDATE_IMPORT_STAGE',
    UPSERT_IMPORT = 'UPSERT_IMPORT',
    COMPLETE_IMPORT_STAGE = 'COMPLETE_IMPORT_STAGE',
    CREATE_IMPORT_PLACEHOLDER = 'CREATE_IMPORT_PLACEHOLDER',
}

export type ImportsActionContext = AugmentedActionContext<ImportsState, ImportsGetters, ImportsMutations>;

type ActionResponse = Promise<void> | void;

export interface ImportsActions {
    [ImportsActionTypes.LOAD_CLIENT_IMPORTS]({ commit }: ImportsActionContext, clientId: string): ActionResponse;
    [ImportsActionTypes.UPDATE_IMPORT_STAGE](
        { state, commit }: ImportsActionContext,
        data: ImportStageUpdatedData
    ): ActionResponse;
    [ImportsActionTypes.COMPLETE_IMPORT_STAGE](
        { state, commit }: ImportsActionContext,
        data: ImportsStageCompletedData
    ): ActionResponse;
    [ImportsActionTypes.UPSERT_IMPORT](context: ImportsActionContext, importData: Import): ActionResponse;
}

export enum ImportsMutationTypes {
    UPSERT_IMPORT = 'UPSERT_IMPORT',
    UPDATE_IMPORT_STAGE_RECORDS = 'UPDATE_IMPORT_STAGE_RECORDS',
    COMPLETE_IMPORT_STAGE = 'COMPLETE_IMPORT_STAGE',
}

export enum ParentStages {
    all = 'all',
    health_check = 'health_check',
    vat_return = 'vat_return',
    business_performance = 'business_performance',
    control_accounts = 'control_accounts',
    abn_validation = 'abn_validation',
}

export type ImportsMutations = {
    [ImportsMutationTypes.UPDATE_IMPORT_STAGE_RECORDS](state: ImportsState, data: ImportStageUpdatedData): void;
    [ImportsMutationTypes.COMPLETE_IMPORT_STAGE](state: ImportsState, data: ImportsStageCompletedData): void;
    [ImportsMutationTypes.UPSERT_IMPORT](state: ImportsState, importData: Import): void;
};

export type ImportsGetters = {
    inProgressImports(state: ImportsState): Array<Import>;
    isClientImporting(
        state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, mode?: string | ImportMode, stage?: string) => boolean;
    getImportsByClientId(state: ImportsState, getters: ImportsGetters): (clientId: string) => Array<Import>;
    findImportByClientIdAndFlowId(
        state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, flowId: number) => Import | undefined;
    findImportByClientIdAndMode(
        state: ImportsState,
        getters: ImportsGetters
    ): (clientId: string, mode: string | ImportMode) => Import | undefined;
    getImportProgressByClientIdAndModeInPercentage(
        state: ImportsState,
        getters: ImportsGetters,
        mode?: string | ImportMode
    ): (clientId: string, mode?: string | ImportMode) => number;
};

export interface ImportsState extends MetaStore {
    imports: Array<Import>;
}
