<template>
    <Fragment>
        <template v-if="limitApproaching">
            <p>You've almost reached the maximum number of clients in your subscription allowance.</p>
            <p>You can add up to {{ clientLimit }} clients with your current subscription.</p>
            <p v-if="isManualBilling">
                If you would like to add more clients, please contact us to upgrade your subscription.
            </p>
            <p v-else>If you would like to add more clients, please upgrade your subscription.</p>
        </template>

        <template v-else>
            <p v-if="isDext">Upgrade your client bundle to add more clients to your account.</p>
            <template v-else>
                <p>You've reached the maximum number of clients in your subscription allowance.</p>
                <p>You can only add up to {{ clientLimit }} clients with your current subscription.</p>
                <p v-if="isManualBilling">
                    If you would like to add more clients, please contact us to upgrade your subscription.
                </p>
            </template>
        </template>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

import { ModalContent, ModalHeader } from '@/components/Modal';

export default defineComponent({
    components: { Fragment, ModalContent, ModalHeader },
    props: {
        clientLimit: PropTypes.number().isRequired,
        isDext: PropTypes.bool().isRequired,
        isManualBilling: PropTypes.bool().isRequired,
        limitApproaching: PropTypes.bool().isRequired,
    },
});
</script>

<style scoped></style>
