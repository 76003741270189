import axios from 'axios';
import Vue from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';

import * as types from './types';

export default {
    namespaced: true,
    state: {
        customReports: [],
    },
    actions: {
        async loadReports({ commit }, practiceLevel) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.get(
                `/api/teams/${practiceCrn}/custom-reports/${practiceLevel ? 'team' : 'client'}`
            );

            commit(types.REPORTS_LOADED, data.data);
        },
        async saveReport({ dispatch }, details) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            const { data } = await axios.post(`/api/teams/${practiceCrn}/custom-reports`, details);

            dispatch('loadReports', details.practiceLevel);

            return data.id;
        },
        async updateReport({ commit }, details) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await axios.post(`/api/teams/${practiceCrn}/custom-reports/${details.id}`, details);

            commit(types.REPORT_CHANGED, details);
        },
        async flagReport({ commit }, details) {
            try {
                commit(types.REPORT_CHANGED, details);

                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                await axios.post(`/api/teams/${practiceCrn}/custom-reports/${details.id}/flag`);
            } catch (ex) {
                commit(types.REPORT_CHANGED, { id: details.id, hasFlagged: !details.hasFlagged });
                throw ex;
            }
        },
        async deleteReport({ commit }, id) {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

            await axios.delete(`/api/teams/${practiceCrn}/custom-reports/${id}`);

            commit(types.REMOVE_REPORT, id);
        },
    },
    mutations: {
        [types.REPORTS_LOADED](state, reports) {
            state.customReports = reports;
        },
        [types.REMOVE_REPORT](state, id) {
            const reportIndex = state.customReports.findIndex((r) => r.id === id);

            state.customReports.slice(reportIndex, 1);
        },
        [types.REPORT_CHANGED](state, details) {
            const reportIndex = state.customReports.findIndex((r) => r.id === details.id);

            Vue.set(state.customReports, reportIndex, {
                ...state.customReports[reportIndex],
                ...details,
            });
        },
    },
};
