<template>
    <Component :is="component" ref="contentContainerRef" :class="containerClassName" data-qa="content-container">
        <UnificationBanner />
        <div :class="innerClassName">
            <PageBreadcrumbs />

            <slot />
        </div>
    </Component>
</template>

<script setup lang="ts">
import SimpleBar from 'simplebar-vue';
import { computed, provide, ref } from 'vue';

import { Feature } from '@/enums/Feature';
import UnificationBanner from '@/layout/PageLayout/UnificationBanner.vue';
import store from '@/store';
import { isFeatureEnabled } from '@/utils/features';

import type { ContentContainerApi } from './LayoutContentTypes';

import { ContentContainerContext } from './hooks/useContentContainerContext';

import { PageBreadcrumbs } from '../PageLayout/Breadcrumbs';

type Props = {
    noScroll?: boolean;
    size?: 'small' | 'medium' | 'large' | 'full';
};

const props = withDefaults(defineProps<Props>(), {
    noScroll: false,
    size: 'full',
});

const contentContainerRef = ref<HTMLElement | null>(null);

const hasUnlinkedBanner = () => {
    const currentClient = store.getters['legacyClients/currentClient'];

    if (!currentClient) {
        return false;
    }

    const isClientPath = /^\/client\//.test(window.location.pathname);

    return isClientPath && !currentClient.linked && isFeatureEnabled(Feature.UNLINKED_CLIENT_WARNING);
};

const api: ContentContainerApi = {
    contentContainerRef,
    noScroll: computed(() => props.noScroll),
    size: computed(() => props.size),
} as const;

const containerClassName = computed(() => {
    return {
        'content-container': true,
        'content-container--no-scroll': props.noScroll,
        'content-container--with-banner': hasUnlinkedBanner(),
    };
});

const innerClassName = computed(() => {
    return {
        'content-container__inner': true,
        [`content-container__inner--${props.size}`]: Boolean(props.size),
    };
});

const component = computed(() => (props.noScroll ? 'div' : SimpleBar));

provide(ContentContainerContext, api);
</script>

<script lang="ts">
export default { name: 'ContentContainer' };
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.content-container {
    background-color: var(--d-color-silver-lite);
    height: 100%;
    overflow: hidden auto;
    width: 100%;
}

.content-container--with-banner {
    padding: 16px 16px 64px;
}

.content-container__inner {
    height: 100%;
    padding: var(--d-spacing-3);

    .content-container--no-scroll & {
        display: flex;
        flex-direction: column;
    }
}

@each $name, $value in $breakpoints {
    .content-container__inner--#{$name} {
        max-width: $value;
    }
}

.content-container__inner--full {
    max-width: 100%;
}
</style>
