import { watchEffect, ref, nextTick } from 'vue';

import { useProductTour } from '@/providers/ProductTour';
import { pinia } from '@/store/pinia';
import { useProductTourStore } from '@/store/productTour';

export const focusSidebarGroupRef = ref(null);
export const workflowListSidebarItemRef = ref(null);
export const monthlyBookkeepingWorkflowRef = ref(null);
export const duplicatedTransactionsCheckRef = ref(null);
export const duplicatedTransactionsCheckComponentRef = ref(null);
export const firstDuplicatedTransactionRef = ref(null);
export const syncButtonRef = ref(null);
export const markCheckStatusRef = ref(null);
export const completeWorkflowCheckRef = ref(null);
export const bankReconciliationCheckRef = ref(null);
export const completeWorkflowRef = ref(null);

export const useProductTourTemplateRefs = () => {
    const { hide, show } = useProductTour('MonthlyBookkeepingProductTour');

    watchEffect(
        async () => {
            const productTourStore = useProductTourStore(pinia);

            // Hide any tutorial modals to avoid flickering if target element doesn't exist
            hide();
            if (!productTourStore.stepInitialized) {
                await productTourStore.initializeCurrentStep();
            }

            const currentStep = productTourStore.currentProductTourStep ?? null;

            const isTargetElementPresentThroughCallable =
                currentStep && typeof currentStep.attachTo.element === 'function' && currentStep.attachTo.element();

            const isTargetElementPresentThroughStatic =
                currentStep && typeof currentStep.attachTo.element !== 'function' && currentStep.attachTo.element;

            if (isTargetElementPresentThroughCallable || isTargetElementPresentThroughStatic) {
                nextTick(() => {
                    show(productTourStore.currentProductTourStepIndex, true);
                });
            }
        },
        { flush: 'post' }
    );

    return {
        focusSidebarGroupRef,
        workflowListSidebarItemRef,
        monthlyBookkeepingWorkflowRef,
        duplicatedTransactionsCheckRef,
        firstDuplicatedTransactionRef,
        syncButtonRef,
        markCheckStatusRef,
        bankReconciliationCheckRef,
        completeWorkflowRef,
        duplicatedTransactionsCheckComponentRef,
        completeWorkflowCheckRef,
    };
};
