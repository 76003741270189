<template>
    <FileExportFrame v-bind="$props" v-slot="{ methods, status }" v-on="$listeners">
        <MenuItem v-bind="$attrs" :loading="status.loading" :name="`Export to ${fileType}`" @click="methods.export">
            <template #icon-left>
                <VIcon :name="iconLeftName" v-if="iconLeftName" />
            </template>

            <slot />
        </MenuItem>
    </FileExportFrame>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import FileExportFrame from '@/frames/FileExportFrame';
import MenuItem from './MenuItem.vue';

export default defineComponent({
    name: 'FileExportMenuItem',

    components: {
        FileExportFrame,
        MenuItem,
    },

    props: { ...FileExportFrame.props },

    setup(props) {
        /**
         * Generate the prepend icon name.
         *
         * @returns String
         */
        const iconLeftName = computed(() => {
            switch (props.fileType) {
                case 'pdf':
                    return 'file-pdf-thick';
                case 'excel':
                    return 'file-excel-thick';
                default:
                    return '';
            }
        });

        return { iconLeftName };
    },
});
</script>

<style lang="scss" scoped></style>
