import { MutationPayload, Store } from 'vuex';

type ModuleConfig<State> = {
    mutationType: string;
    handler: (store: Store<State>, mutation: MutationPayload) => void;
};

/**
 *
 * @param modules Modules to register
 * @returns Function VueX plugin register function
 */
export default function registerDynamicModules<State>(modules: ModuleConfig<State>[]) {
    return function plugin(store: Store<State>) {
        store.subscribe((mutation) => {
            modules.map((config) => {
                if (mutation.type === config.mutationType) {
                    config.handler(store, mutation);
                }
            });
        });
    };
}
