<template>
    <Fragment>
        <ModalHeader>
            <template v-if="limitApproaching">Client Limit Approaching</template>
            <template v-else>Client Limit Reached</template>
        </ModalHeader>

        <ModalContent>
            <LimitApproachingOrReached
                :client-limit="clientLimit"
                :is-dext="isDext"
                :is-manual-billing="isManualBilling"
                :limit-approaching="limitApproaching"
            />
        </ModalContent>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

import { ModalContent, ModalHeader } from '@/components/Modal';
import LimitApproachingOrReached from '@/layout/PageLayout/LimitApproachingOrReached.vue';

export default defineComponent({
    components: { LimitApproachingOrReached, Fragment, ModalContent, ModalHeader },
    props: {
        clientLimit: PropTypes.number().isRequired,
        isDext: PropTypes.bool().isRequired,
        isManualBilling: PropTypes.bool().isRequired,
        limitApproaching: PropTypes.bool().isRequired,
    },
});
</script>

<style scoped></style>
