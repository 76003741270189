import { AxiosError } from 'axios';

/**
 * Detect if the given error comes from the API or is internal JS.
 *
 * @param error
 * @returns Boolean
 * @link https://stackoverflow.com/a/66519708/1694116
 */
export default function isApiError(error: any): error is AxiosError {
    return error.isAxiosError;
}
