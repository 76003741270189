import type { ClientId } from '@/store/modules/client/types/Client';

export interface ImportStage {
    code: string;
    name: string;
    finished: boolean;
    finishedAt: string | null;
    successful: boolean;
    type: string;
    recordCount: number;
}

export enum ImportMode {
    all = 'all',
    flow = 'flow',
}

export type Import = {
    clientId: ClientId;
    completedAt: string | null;
    flowId: number | null;
    id: number;
    mode: (ImportMode | string) & {};
    progress: ImportStage[];
    stage: string;
};

export type ImportStageCompleteEventData = {
    clientId: ClientId;
    stage: string;
    importId: number;
};

export type ImportCompleteEventData = Import & {
    startedAt: string;
};

export interface ImportPlaceholderData {
    clientId: ClientId;
    stage: string;
    mode: (ImportMode | string) & {};
}
