const namespace = 'workflows.hand_back.';

const translations = {
    close: `${namespace}close`,
    handBack: `${namespace}hand_back`,
    leaveMessage: `${namespace}leave_message`,
    messageHint: `${namespace}message_hint`,
    messageLabel: `${namespace}message_label`,
    title: `${namespace}title`,
};

export { translations };
