const RequestIds = {
    /* Client
    ========================================================================= */
    DELETE_CLIENT: 'client.delete',
    LOAD_CLIENT_STATS: 'client.stats.load',
    LOAD_CLIENT_TRANSACTIONS: 'client.transactions.fetch',

    /* Client
    ========================================================================= */
    UPDATE_CLIENT_UNRECONCILED_TRANSACTIONS_SETTINGS: 'client.unreconciled-transactions.settings.update',

    /* Custom Health Score
    ========================================================================= */
    LOAD_CUSTOM_HEALTH_SCORE_CONFIG: 'customHealthScore.config.custom.load',
    LOAD_DEFAULT_HEALTH_SCORE_CONFIG: 'customHealthScore.config.default.load',
    LOAD_RECOMMENDED_HEALTH_SCORE_CONFIG: 'customHealthScore.config.recommended.load',
    STORE_HEALTH_SCORE_CONFIG: 'customHealthScore.config.store',

    /* Client Health Score
    ========================================================================= */
    GET_DEFAULT_HEALTH_SCORE_CONFIG: 'clientHealthScore.getDefaultHealthScoreConfiguration',
    GET_HEALTH_SCORE_STATS: 'clientHealthScore.getHealthScoreStats',

    /* Product Tour
    ========================================================================= */
    COMPLETE_PRODUCT_TOUR: 'productTour.complete',
    FETCH_PRODUCT_TOURS: 'productTours',
    INIT_PRODUCT_TOUR: 'productTour.init',
    PROGRESS_PRODUCT_TOUR: 'productTour.progress',

    /* Teams
    ======================================================================== */
    CURRENT_TEAM_LOAD: 'teams.current.load',
    TEAMS_LOAD: 'teams.load',

    /* Notification settings
    ======================================================================== */
    FETCH_NOTIFICATION_SETTINGS: 'team.notificationSettings.load',
    UPDATE_NOTIFICATION_SETTINGS: 'team.notificationSettings.update',

    /* Worfklows
    ======================================================================== */
    COMPLETE_WORKFLOW: 'workflows.complete',
    DELETE_WORKFLOW: 'workflows.delete',
    DUPLICATE_WORKFLOW: 'workflows.duplicate',
    FETCH_REPEATING_WORKFLOWS_LIST: 'workflows.fetchRepeatingList',
    FETCH_WORKFLOW_CHECKS: 'workflows.fetchChecks',
    FETCH_WORKFLOWS_LIST: 'workflows.fetchList',
    START_WORKFLOW: 'workflows.start',
    UPDATE_WORKFLOW: 'workflows.update',

    /* Single Workflow
    ======================================================================== */
    FETCH_WORKFLOW: 'workflow.fetch',

    /* Draft Workflows
    ======================================================================== */
    DELETE_DRAFT_WORKFLOW: 'draftWorkflows.delete',
    FETCH_DRAFT_WORKFLOWS_LIST: 'draftWorkflows.fetchList',

    /* Workflow Templates
    ======================================================================== */
    CREATE_WORKFLOW_TEMPLATE: 'workflowTemplates.create',
    DELETE_WORKFLOW_TEMPLATE: 'workflowTemplates.delete',
    FETCH_WORKFLOW_TEMPLATES_LIST: 'workflowTemplates.fetchList',
    UPDATE_WORKFLOW_TEMPLATE: 'workflowTemplates.update',

    /* Workflow Checks
    ======================================================================== */
    FETCH_WORKFLOW_CHECKS_LIST: 'workflowChecks.fetchList',

    /* Workflow Custom Checks
    ======================================================================== */
    CREATE_WORKFLOW_CUSTOM_CHECK: 'workflowCustomChecks.create',
    DELETE_WORKFLOW_CUSTOM_CHECK: 'workflowCustomChecks.delete',
    FETCH_WORKFLOW_CUSTOM_CHECKS_LIST: 'workflowCustomChecks.fetchList',
    UPDATE_WORKFLOW_CUSTOM_CHECK: 'workflowCustomChecks.update',

    /* Dashboards
    ======================================================================== */
    FETCH_ACTIVITY_STATS: 'practiceActivityStats.fetch',
    FETCH_DEADLINES: 'deadlinesDashboard.fetch',
    FETCH_MTD: 'mtdDashboard.fetch',
    FETCH_PRACTICE: 'practiceDashboard.fetch',

    /* Reports
    ======================================================================== */
    FETCH_CUSTOM_REPORTS: 'customReports.fetch',
    GET_CUSTOM_REPORT: 'customReports.get',

    /* Insights
    ======================================================================== */
    FETCH_DUPLICATE_CONTACTS: 'duplicateContacts.fetch',
    FETCH_DUPLICATE_CONTACTS_PARAMS: 'duplicateContacts.params.fetch',
    FETCH_INSIGHT_BANK_RECONCILIATION: 'insights.bankReconciliation.fetch',

    /* Insights
    ======================================================================== */
    FETCH_INSIGHT_AGED_BALANCES: 'insights.agedBalances.fetch',
    FETCH_INSIGHT_BUSINESS_PERFORMANCE: 'insights.businessPerformance.fetch',
    FETCH_INSIGHT_COST_BY_ACCOUNT_CODE: 'insights.costByAccountCode.fetch',
    FETCH_INSIGHT_DRAFT_INVOICES: 'insights.draftInvoices.fetch',
    FETCH_INSIGHT_GO_PROPOSAL: 'insights.goProposal.fetch',
    FETCH_INSIGHT_HISTORICAL_CHANGES: 'insights.historicalChanges.fetch',
    FETCH_INSIGHT_PERFORMANCE_BENCHMARKING: 'insights.performanceBenchmarking.fetch',
    FETCH_INSIGHT_REGULAR_SUPPLIERS: 'insights.regularSuppliers.fetch',
    FETCH_INSIGHT_SALES_ANALYSIS: 'insights.salesAnalysis.fetch',
    FETCH_INSIGHT_UNRECONCILED_TRANSACTIONS: 'insights.unreconciledTransactions.fetch',

    /* Cleanup
    ======================================================================== */
    FETCH_STATEMENTS: 'cleanup.statements.fetch',

    /* Insights Parameters
    ======================================================================== */
    FETCH_INSIGHT_COST_BY_ACCOUNT_CODE_PARAMS: 'insights.costByAccountCode.params.fetch',
    FETCH_INSIGHT_REGULAR_SUPPLIERS_PARAMS: 'insights.regularSuppliers.params.fetch',
} as const;

export type RequestId = keyof typeof RequestIds;
export type RequestIdValue = (typeof RequestIds)[RequestId];
export { RequestIds };
