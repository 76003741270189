<template>
    <Modal v-model="isVisible" name="Demo client loading" :persistent="true" size="medium">
        <ModalContent>
            <div class="d-p-2 d-mt-1">
                <img alt="Demo client loading" class="demo-client-loading" src="/img/demo-client-loading.svg" />
            </div>

            <DSpinner :centered="false" />

            <div class="text-center">
                <DHeading as="h2" class="d-mb-1">Loading demo client for your tutorial</DHeading>

                <p>This will take a few moments</p>
            </div>
        </ModalContent>
    </Modal>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { DHeading } from '@/components/DextUi';
import { Modal, ModalContent } from '@/components/Modal';
import { DSpinner } from '@/components/Spinner';

const emit = defineEmits(['input']);

type Props = {
    value: boolean;
};

const props = defineProps<Props>();

const isVisible = computed({
    get() {
        return props.value;
    },
    set(newValue: boolean) {
        emit('input', newValue);
    },
});
</script>

<style scoped>
.demo-client-loading {
    width: 100%;
}
</style>
