import Vue from 'vue';

import FlowService from '@/services/Api/FlowService';

import * as types from './types';

export default {
    namespaced: true,
    state() {
        return {
            flowTemplates: [],
            loading: false,
            flowTemplatesLoaded: false,
        };
    },
    getters: {
        loading: (state) => state.loading,
        templateList: (state) => Object.values(state.flowTemplates),
        flowTemplatesLoaded: (state) => state.flowTemplatesLoaded,
    },
    actions: {
        async loadFlowTemplates({ commit, state }, page = 1) {
            commit(types.LOADING_STARTED);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;
            const { data, pagination } = await FlowService.getFlowTemplates(practiceCrn, page);

            if (pagination.currentPage < pagination.lastPage) {
                setTimeout(() => {
                    this.dispatch('flowTemplates/loadFlowTemplates', pagination.currentPage + 1);
                }, 100);
            }

            const nextState = state.flowTemplates
                .concat(data)
                .filter((item) => item)
                .filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);

            commit(types.FLOW_TEMPLATES_LOADED, nextState);
        },
        async createFlowTemplate({ dispatch }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.createFlowTemplate(practiceCrn, details);

            await dispatch('loadFlowTemplates');

            return data;
        },
        async updateFlowTemplate({ dispatch }, details) {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const { data } = await FlowService.updateFlowTemplate(practiceCrn, details.id, details);

            await dispatch('loadFlowTemplates');

            return data;
        },
        async deleteFlowTemplate({ commit }, id) {
            commit(types.REMOVE_FLOW_TEMPLATE, id);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await FlowService.deleteFlowTemplate(practiceCrn, id);
        },
    },
    mutations: {
        [types.LOADING_STARTED](state) {
            state.loading = true;
        },
        [types.FLOW_TEMPLATES_LOADED](state, templateList) {
            state.flowTemplates = templateList;

            state.flowTemplatesLoaded = true;
            state.loading = false;
        },
        [types.REMOVE_FLOW_TEMPLATE](state, id) {
            const flowTemplateIndex = state.flowTemplates.findIndex((template) => template.id === id);

            Vue.delete(state.flowTemplates, flowTemplateIndex);
        },
    },
};
