import type { FetchAccountResponseSchema } from '@/schemas/app/Account.schema';
import type { HealthScore } from '@/store/clientHealthScore/types';
import type { AccountCode, Client, ClientId } from '@/store/modules/client/types/Client';
import type { ReportingMode } from '@/store/modules/client/types/Stats';

import BaseApiService from './BaseApiService';

/**
 * For use in any place that needs to make a request to the Client Bulk Edit
 * API endpoints. All endpoints should be represented here.
 */
class ClientService extends BaseApiService {
    /**
     * Get full client accounts list.
     *
     * @param practiceCrn
     * @param {String} id Client id
     * @returns Promise
     * @todo Use a slug not an id
     */
    getClientAccounts(practiceCrn: string, id: ClientId) {
        return this.get<FetchAccountResponseSchema, never>('dext.client.accounts', { teamCrn: practiceCrn, id });
    }

    /**
     *
     * @param practiceCrn
     * @param {String} clientId
     * @param params
     * @returns Promise
     */
    getTransactions(practiceCrn: string, clientId: ClientId, params: { [key: string]: string } = {}) {
        return this.get('dext.client.get.transactions', { teamCrn: practiceCrn, clientId }, params);
    }

    /**
     *
     * @param practiceCrn
     * @param {String} clientId
     * @param params
     * @returns Promise
     */
    getComments(practiceCrn: string, clientId: ClientId, params: { [key: string]: string } = {}) {
        return this.get('dext.client.get.comments', { clientById: clientId, teamCrn: practiceCrn }, params);
    }

    /**
     *
     * @param {String} practiceCrn
     * @param {String} clientId
     * @param params
     * @returns Promise
     */
    getClientBenchmarking(
        practiceCrn: string,
        clientId: ClientId,
        metric: string,
        segment: string,
        params: { [key: string]: string } = {}
    ) {
        return this.get(
            'dext.benchmark.get',
            {
                clientId: clientId,
                metric: metric,
                segment: segment,
                teamCrn: practiceCrn,
            },
            params
        );
    }

    /**
     *
     * @param {String} practiceCrn
     * @param {String} clientId
     * @param params
     * @returns Promise
     */
    getTransactionsByAccount(
        practiceCrn: string,
        clientId: ClientId,
        accountId: string,
        params: { [key: string]: string }
    ) {
        return this.get('dext.client.get.transactions.account', { teamCrn: practiceCrn, clientId, accountId }, params);
    }

    /**
     *
     * @param {String} practiceCrn
     * @param {String} clientId
     * @param params
     * @returns Promise
     */
    getTransactionsByCode(practiceCrn: string, clientId: ClientId, code: string, params: { [key: string]: string }) {
        return this.get('dext.client.get.transactions.code', { teamCrn: practiceCrn, clientId, id: code }, params);
    }

    /**
     *
     * @param practiceCrn
     * @param {String} id Client id
     * @returns Promise
     * @todo Use a slug not an id
     */
    getVatExpenseAccounts(practiceCrn: string, id: ClientId) {
        return this.get<AccountCode[], never>('dext.client.vat_expense_account_overrides', {
            teamCrn: practiceCrn,
            clientById: id,
        });
    }

    /**
     * Get all clients.
     *
     * @param practiceCrn string
     * @param {Object} params Request query params
     * @returns Promise
     */
    getAllClients(practiceCrn: string, params: { enterprise?: boolean; reportingMode?: ReportingMode }) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.clients.get.all', routeParams, params);
    }

    /**
     * Get a single client by ID.
     *
     * @param practiceCrn
     * @param id ClientId
     * @param params URL Params
     * @returns Promise
     */
    getClientById(practiceCrn: string, id: ClientId, params = {}) {
        return this.get<{ data: Client }, never>('dext.clients.get.single-by-id', { teamCrn: practiceCrn, id }, params);
    }

    /**
     * Get client stats.
     *
     * @param practiceCrn
     * @param {String} id Client ID to get
     * @param {String} mode Stats mode to get
     * @returns Promise
     */
    getClientStats(practiceCrn: string, id: ClientId, mode: ReportingMode) {
        return this.get<HealthScore, never>('dext.client.get.stats', { teamCrn: practiceCrn, id, mode });
    }

    /**
     * Get client insights stats excluding health score insights.
     *
     * @param practiceCrn
     * @param {String} id Client ID to get
     * @param {String} mode Stats mode to get
     * @returns Promise
     */
    getInsightsStats(practiceCrn: string, id: ClientId, mode: ReportingMode) {
        return this.get<HealthScore>('dext.client.get.insights-stats', { teamCrn: practiceCrn, id, mode });
    }

    /**
     * Post client user. Add a user to a client.
     *
     * @param practiceCrn
     * @param {String} id Client ID to update
     * @param {Object} data Data to update
     * @returns Promise
     */
    postClientUser(practiceCrn: string, id: ClientId, data: { userId: number }) {
        return this.post('dext.client.post.user', { teamCrn: practiceCrn, id }, data);
    }

    /**
     * @param practiceCrn
     * @param id
     */
    postFlagClient(practiceCrn: string, id: ClientId) {
        return this.post('client.flag', { teamCrn: practiceCrn, id });
    }

    /**
     * Delete a client at the given id.
     *
     * @param practiceCrn
     * @param id
     * @returns Promise
     */
    deleteClient(practiceCrn: string, id: ClientId) {
        return this.delete('dext.client.delete', { teamCrn: practiceCrn, id });
    }

    /**
     * Delete client user.
     *
     * @param practiceCrn
     * @param {String} id Client ID to update
     * @param {String} userId User ID to delete
     * @returns Promise
     */
    deleteClientUser(practiceCrn: string, id: ClientId, userId: number) {
        return this.delete('dext.client.delete.user', { teamCrn: practiceCrn, id, userId });
    }

    /**
     * Get a client's healthscore over time data.
     *
     * @param practiceCrn
     * @param {String} id Client ID to get
     * @param {Object} data
     * @param {String} data.start
     * @param {String} data.end
     * @param {String} data.frequency
     * @returns
     */
    getClientHealthScoreOverTime(
        practiceCrn: string,
        id: ClientId,
        data?: { start: string; end: string; frequency: string }
    ) {
        return this.get('dext.client.get.health_score_over_time', { teamCrn: practiceCrn, clientById: id }, data);
    }

    /**
     * Get the client list.
     *
     * @returns Promise
     */
    getClientList(practiceCrn: string | number) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get<{ data: Client[] }, never>('dext.clients.get.list', routeParams);
    }

    /**
     * Post override account codes.
     *
     * @param practiceCrn
     * @param {String} clientById Client ID to update
     * @param {String} type Type of check to update
     * @param {Object} data Data to update
     * @returns Promise
     */
    postAccountCodeOverrides(
        practiceCrn: string,
        clientById: Client['id'],
        type: string,
        data?: { accounts: Array<string> }
    ) {
        return this.post('dext.client.post.override-accounts', { teamCrn: practiceCrn, clientById, type }, data);
    }

    /**
     * Get override account codes.
     *
     * @param practiceCrn
     * @param {String} clientById Client ID to get
     * @param {String} type Type of check to get
     * @returns Promise
     */
    getAccountCodeOverrides(practiceCrn: string, clientById: Client['id'], type: string) {
        return this.get('dext.client.get.override-accounts', { teamCrn: practiceCrn, clientById, type });
    }

    /**
     * Get directors loans results by account group ID.
     *
     * @param practiceCrn
     * @param {String} clientById Client ID to get
     * @param {String} groupId Account group ID to get
     * @param {String} mode Mode to get
     * @param {String} flowId Flow ID to get
     * @returns Promise
     */
    getDirectorsLoansResults(
        practiceCrn: string,
        clientById: Client['id'],
        groupId: string,
        mode: string,
        flowId: string
    ) {
        return this.get('dext.client.get.directors-loans-results', {
            teamCrn: practiceCrn,
            clientById,
            groupId,
            mode,
            flowId,
        });
    }

    /**
     * Updates a flag in clients table whether to include
     * bank transactions in the insight
     *
     * @param practiceCrn
     * @param {String} id
     * @param {Number} value
     * @returns Promise
     */
    postIsIncludingDuplicateBankTransactions(practiceCrn: string, id: Client['id'], value: number) {
        return this.post(
            'dext.client.update-is-including-duplicate-bank-transactions',
            { teamCrn: practiceCrn, id },
            {
                is_including_duplicate_bank_transactions: value,
            }
        );
    }

    /**
     * Disconnects client from provider. For HMRC and Companies house the client
     * is switched to unintegrated
     *
     * @param practiceCrn
     * @param id
     */
    unlink(practiceCrn: string, id: Client['id']) {
        return this.post('dext.api.client.unlink', { teamCrn: practiceCrn, id });
    }

    getXeroClientReconnectUrl(practiceCrn: string, clientSlug: Client['slug']) {
        return this.get<{ data: string }, never>('dext.client.xero.get-reconnect-url', {
            teamCrn: practiceCrn,
            client: clientSlug,
        });
    }

    loadVatScheme(practiceCrn: string, clientId: Client['id']) {
        return this.get('dext.client.vat.getScheme', {
            teamCrn: practiceCrn,
            clientId: clientId,
        });
    }

    updateVatScheme(
        practiceCrn: string,
        clientId: Client['id'],
        data: {
            vatRate: number;
            vatStart: number;
            taxNumber?: string;
            salesTaxBasis?: string;
            salesTaxPeriod?: string;
            clientProvider?: string;
        }
    ) {
        return this.post<{ data: { periodEnd: string; periodStart: string } }>(
            'dext.client.vat.updateSettings',
            {
                teamCrn: practiceCrn,
                clientId: clientId,
            },
            data
        );
    }

    loadTaxCodes(practiceCrn: string, cluentSlug: Client['slug']) {
        return this.get('dext.client.getTaxCodes', {
            teamCrn: practiceCrn,
            client: cluentSlug,
        });
    }
}

export default Object.freeze(new ClientService());
