const WorkflowStatus = {
    COMPLETED: 'Complete',
    DELETED: 'Delete',
    IN_PROGRESS: 'In Progress',
    NOT_STARTED: 'Not Started',
    UNDER_REVIEW: 'Under Review',
} as const;

export { WorkflowStatus };
export type WorkflowStatusKey = keyof typeof WorkflowStatus;
export type WorkflowStatus = (typeof WorkflowStatus)[WorkflowStatusKey];
