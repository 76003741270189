<template>
    <div class="bar-container">
        <div :style="{ width: barWidth }" class="bar" />
    </div>
</template>

<script>
export default {
    props: {
        progress: { type: Number, default: 0 },
    },
    data() {
        return {
            currentProgress: this.$props.progress,
        };
    },
    computed: {
        barWidth() {
            const progress = Math.min(this.currentProgress, 100);

            return `${progress}%`;
        },
    },
    watch: {
        progress(value) {
            this.currentProgress = value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.bar-container {
    height: 5px;
    width: 100%;
}

.bar {
    background-color: get-color(green);
    height: 100%;
    transition: width 200ms;
}
</style>
