import { GetterTree } from 'vuex';
import { LockDateActivityState, WidgetResultsState } from '@/store/modules/insightsDashboard/lockDateActivity/types';
import { RootState } from '@/store/types';

export type LockDateActivityGetters = {
    lockDateActivityWidgetLoading(state: LockDateActivityState): boolean;
    lockDateActivityWidgetResults(state: LockDateActivityState): WidgetResultsState;
};

export const getters: GetterTree<LockDateActivityState, RootState> & LockDateActivityGetters = {
    lockDateActivityWidgetLoading(state) {
        return state.lockDateActivity.widget.isLoading;
    },
    lockDateActivityWidgetResults(state) {
        return state.lockDateActivity.widget.results;
    },
};
