import { inject } from 'vue';

/**
 * Force an error if no injection found.
 *
 * @param key
 * @param fallback
 * @returns Provided data from parent component
 * @throws If no injection found
 */
export default function injectStrict<T>(key: string, fallback?: T): T {
    const resolved = inject(key, fallback);

    if (!resolved) {
        throw new Error(`Could not resolve ${key}`);
    }

    return resolved;
}
