import { useTeamContext } from '@/App/composables/useTeamContext';

function useCentralBilling() {
    const { linkToPrepare } = useTeamContext('useCentralBilling');
    const getCentralBillingUrl = () => linkToPrepare('subscription');

    return { getCentralBillingUrl };
}

export default useCentralBilling;
