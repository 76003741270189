import { defineStore } from 'pinia';

import { RequestIds } from '@/enums/RequestIds';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import TeamService from '@/services/Api/TeamService';

import type { TeamsStoreState } from './TeamsStore.types';
import type { TeamModel } from '@/schemas/Team.schema';

import { useRequestsStore } from '../requests';

export const useTeamsStore = defineStore('teams.store', {
    state(): TeamsStoreState {
        return {
            allIds: [],
            byId: {},
            currentTeamId: -1,
        };
    },

    getters: {
        currentTeam(state) {
            return state.byId[state.currentTeamId];
        },

        allTeams(state) {
            return state.allIds.map((id) => state.byId[id]);
        },
    },

    actions: {
        async fetchTeamsList() {
            const requestStore = useRequestsStore();

            try {
                const result = await requestStore.makeRequest(() => TeamService.getTeamsList(), {
                    blockMultipleRequests: true,
                    requestId: RequestIds.TEAMS_LOAD,
                });

                const allIds = result.data.map((item) => item.id);
                const byId = result.data.reduce(
                    (objectAcc, item) => {
                        return {
                            ...objectAcc,
                            [item.id]: { ...item },
                        };
                    },
                    {} as Record<number, TeamModel>
                );

                this.byId = byId;
                this.allIds = allIds;

                if (this.currentTeamId < 0) {
                    this.currentTeamId = useXavierGlobals().currentTeam.id;
                }
            } catch (error) {
                // Handled by the request store
            }
        },

        async init() {
            await this.fetchTeamsList();
        },
    },
});
