import type { WorkflowStatus } from '@/enums/WorkflowStatus';
import type { DuplicateWorkflowResponseSchema } from '@/schemas/app/Workflows/DraftWorkflow.schema';
import type {
    GetFlowListRequestSchema,
    GetFlowListResponseSchema,
    StartFlowRequestSchema,
    StartFlowResponseSchema,
    UpdateDraftRequestSchema,
    UpdateDraftResponseSchema,
} from '@/schemas/app/Workflows/Workflow.schema';

import BaseApiService from './BaseApiService';

/**
 * @deprecated Use WorkflowService instead
 */
class FlowService extends BaseApiService {
    /**
     * Get all flows.
     *
     * @returns Promise
     */
    getAllFlows(practiceCrn: number, params: { statuses?: WorkflowStatus[] }) {
        return this.get<GetFlowListResponseSchema, GetFlowListRequestSchema>(
            'dext.flows.get.all',
            { teamCrn: practiceCrn },
            params
        );
    }

    /**
     * Update a selected flow.
     *
     * @returns Promise
     * @deprecated Use updateWorkflow instead
     */
    updateFlow(practiceCrn: number, slug: string, data: UpdateDraftRequestSchema) {
        return this.post<UpdateDraftResponseSchema, UpdateDraftRequestSchema>(
            'dext.flow.post.update',
            { teamCrn: practiceCrn, slug },
            data
        );
    }

    /**
     * Get flow statuses
     *
     * @param {String} practiceCrn
     * @param {number|null} clientId
     * @returns Promise
     */
    getFlowStatuses(practiceCrn, clientId) {
        let routeParams = { teamCrn: practiceCrn };

        if (clientId) {
            routeParams = { ...routeParams, ...{ clientId } };
        }

        return this.get('dext.flows.get.statuses', routeParams);
    }

    /**
     * Get flow templates.
     *
     * @param {String} practiceCrn
     * @param {number} page
     * @returns Promise
     */
    getFlowTemplates(practiceCrn, page) {
        return this.get('dext.flow-templates.index', { teamCrn: practiceCrn }, { page });
    }

    /**
     * Delete a selected flow.
     *
     * @param {String} practiceCrn
     * @param {number} flowId
     * @returns Promise
     */
    deleteFlow(practiceCrn: number, flowId: number) {
        return this.delete<never, never>('dext.flow.delete', {
            id: flowId,
            teamCrn: practiceCrn,
        });
    }

    /**
     * Start a selected flow.
     *
     * @returns Promise
     */
    startFlow(practiceCrn: number, flowId: number) {
        return this.get<StartFlowResponseSchema, StartFlowRequestSchema>('dext.flow.start', {
            teamCrn: practiceCrn,
            id: flowId,
        });
    }

    /**
     * Duplicate a selected flow.
     *
     * @returns Promise
     */
    duplicateFlow(practiceCrn: number, flowId: number) {
        return this.post<DuplicateWorkflowResponseSchema, never>('dext.draft-flows.duplicate', {
            flowId,
            teamCrn: practiceCrn,
        });
    }

    /**
     * Create flow.
     *
     * @param {String} practiceCrn
     * @param {Object} data
     * @returns Promise
     */
    createFlow(practiceCrn, data) {
        return this.post('dext.flow.store', { teamCrn: practiceCrn }, data);
    }

    /**
     * Load a selected flow.
     *
     * @param {String} practiceCrn
     * @param {String} slug
     * @returns Promise
     */
    loadFlow(practiceCrn, slug) {
        return this.get('dext.flows.get.by-slug', { teamCrn: practiceCrn, slug });
    }

    /**
     * Load checks.
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    loadChecks(practiceCrn) {
        return this.get('dext.flows.checks.get.all', { teamCrn: practiceCrn });
    }

    /**
     * Load checks.
     *
     * @param {String} practiceCrn
     * @param {Object} data The flow data to be updated
     * @returns Promise
     */
    updateDraftFlowChecks(practiceCrn, data) {
        return this.get('dext.draft-flows.updateChecks', { teamCrn: practiceCrn }, data);
    }

    /**
     * Create flow template.
     *
     * @param {String} practiceCrn
     * @param {Object} data The flow data to be updated
     * @returns Promise
     */
    createFlowTemplate(practiceCrn, data) {
        return this.post('dext.flow-templates.store', { teamCrn: practiceCrn }, data);
    }

    /**
     * Update a selected flow template.
     *
     * @param {String} practiceCrn
     * @param {number} flowTemplateId
     * @param {Object} data The flow data to be updated
     * @returns Promise
     */
    updateFlowTemplate(practiceCrn, flowTemplateId, data) {
        return this.post('dext.flow-templates.update', { teamCrn: practiceCrn, id: flowTemplateId }, data);
    }

    /**
     * Delete a selected flow template.
     *
     * @param {String} practiceCrn
     * @param {number} flowTemplateId
     * @returns Promise
     */
    deleteFlowTemplate(practiceCrn, flowTemplateId) {
        return this.delete('dext.flow-templates.delete', { teamCrn: practiceCrn, id: flowTemplateId });
    }

    /**
     * Load draft flows
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    loadDraftFlows(practiceCrn) {
        return this.get('dext.draft-flows.getAll', { teamCrn: practiceCrn });
    }

    /**
     * Create draft flow.
     *
     * @param {String} practiceCrn
     * @param {Object} data
     * @returns Promise
     */
    createDraftFlow(practiceCrn, data) {
        return this.post('dext.draft-flow.store', { teamCrn: practiceCrn }, data);
    }

    /**
     * Update draft flow.
     *
     * @param {String} practiceCrn
     * @param {String} slug The flow idenitifer
     * @param {String} stage The stage
     * @param {Object} data The flow data to be updated
     * @returns Promise
     */
    updateDraftFlow(practiceCrn, slug, stage, data) {
        return this.post('dext.draft-flows.update', { teamCrn: practiceCrn, slug, stage }, data);
    }

    /**
     * Update draft flow.
     *
     * @param {String} practiceCrn
     * @param {String} slug The flow idenitifer
     * @returns Promise
     */
    loadDraftFlow(practiceCrn, slug) {
        return this.get('dext.draft-flows.getBySlug', { teamCrn: practiceCrn, slug });
    }

    /**
     * Delete deaft flow
     *
     * @param {String} practiceCrn
     * @param {number} draftFlowId
     * @returns Promise
     */
    deleteDraftFlow(practiceCrn, draftFlowId) {
        return this.delete('dext.draft-flows.delete', { teamCrn: practiceCrn, id: draftFlowId });
    }

    /**
     * Load draft repeating flows.
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    loadRepeatingDraftFlows(practiceCrn) {
        return this.get('dext.draft-flows.getAllRepeatingFlows', { teamCrn: practiceCrn });
    }

    /**
     * Load draft repeating flows.
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    loadEnterpriseDraftFlows(practiceCrn) {
        return this.get('dext.draft-flows.getAllForEnterprise', { teamCrn: practiceCrn });
    }

    /**
     * Load checks.
     *
     * @param {String} practiceCrn
     * @returns Promise
     */
    loadCustomChecks(practiceCrn) {
        return this.get('dext.custom-checks.getCustom', { teamCrn: practiceCrn });
    }

    /**
     * Load checks.
     *
     * @param {String} practiceCrn
     * @param {number} checkId
     * @returns Promise
     */
    deleteCustomCheck(practiceCrn, checkId) {
        return this.delete('dext.custom-checks.delete', { teamCrn: practiceCrn, id: checkId });
    }

    /**
     * Update custom check.
     *
     * @param {String} practiceCrn
     * @param {number} checkId
     * @param {Object} data
     * @returns Promise
     */
    updateCustomCheck(practiceCrn, checkId, data) {
        return this.post('dext.custom-checks.update', { teamCrn: practiceCrn, id: checkId }, data);
    }

    /**
     * Load checks.
     *
     * @param {String} practiceCrn
     * @param {Object} data
     * @returns Promise
     */
    createCustomCheck(practiceCrn, data) {
        return this.post('dext.custom-checks.store', { teamCrn: practiceCrn }, data);
    }

    /**
     * Load checks.
     * @param {String} practiceCrn
     * @param {number} flowId
     * @returns Promise
     */
    loadChecksForFlow(practiceCrn, flowId) {
        return this.get('dext.flow.checks.getForFlow', { teamCrn: practiceCrn, id: flowId });
    }

    /**
     * Create comment for flow.
     * @param {String} practiceCrn
     * @param {number} flowId
     * @param {Object} data
     * @returns Promise
     */
    saveCommentForFlow(practiceCrn, flowId, data) {
        return this.post('dext.flow.saveComment', { teamCrn: practiceCrn, id: flowId }, data);
    }

    /**
     * Delete comment for flow.
     * @param {String} practiceCrn
     * @param {number} flowId
     * @param {number} commentId
     * @returns Promise
     */
    deleteCommentForFlow(practiceCrn, flowId, commentId) {
        return this.delete('dext.flow.deleteComment', { teamCrn: practiceCrn, flowId, commentId });
    }

    /**
     * Delete comment for flow.
     * @param {String} practiceCrn
     * @param {String} flowSlug
     * @param {String} fileUuid
     * @returns Promise
     */
    deleteAttachmentFromFlow(practiceCrn, flowSlug, fileUuid) {
        return this.delete('dext.flow-attachment.delete', { teamCrn: practiceCrn, flowSlug, attachmentUuid: fileUuid });
    }

    /**
     * Delete attachment from flow check
     * @param {String} practiceCrn
     * @param {String} flowSlug
     * @param {number} checkId
     * @param {String} fileUuid
     * @returns Promise
     */
    deleteAttachmentFromFlowCheck(practiceCrn, flowSlug, checkId, fileUuid) {
        return this.delete('dext.flow-attachment.checks.delete', {
            teamCrn: practiceCrn,
            flowSlug,
            checkId,
            attachmentUuid: fileUuid,
        });
    }

    /**
     * Complete check for flow.
     *
     * @param {String} practiceCrn
     * @param {number} flowId
     * @param {number} checkId
     * @param {Object} data
     * @returns Promise
     */
    completeCheckForFlow(practiceCrn, flowId, checkId, data) {
        return this.post('dext.flow.check.completeCheck', { teamCrn: practiceCrn, flowId, checkId }, data);
    }

    /**
     * Update a selected flow.
     *
     * @param {String} practiceCrn
     * @param {number} flowId The flow idenitifer
     * @param {number} checkId The flow data to be updated
     * @returns Promise
     */
    incompleteCheckForFlow(practiceCrn, flowId, checkId) {
        return this.get('dext.flow.check.incompleteCheck', { teamCrn: practiceCrn, flowId, checkId });
    }

    /**
     * Complete flow
     *
     * @param {String} practiceCrn
     * @param {number} flowId
     * @returns Promise
     */
    completeFlow(practiceCrn, flowId) {
        return this.get('dext.flow.complete', { teamCrn: practiceCrn, id: flowId });
    }

    /**
     * Complete flow review
     *
     * @param {String} practiceCrn
     * @param {number} flowId
     * @returns Promise
     */
    completeFlowReview(practiceCrn, flowId) {
        return this.get('dext.flow.complete-review', { teamCrn: practiceCrn, id: flowId });
    }

    /**
     * Cancel flow for review
     *
     * @param {String} practiceCrn
     * @param data
     * @returns Promise
     */
    cancelFlowReview(practiceCrn, data) {
        return this.post('dext.flow.cancel-review', { teamCrn: practiceCrn, id: data.id }, data);
    }
}

export default new FlowService();
