<template>
    <div class="client-link">
        <PButton v-if="subscriptionValid && params.data" qa-name="go.to.client" text @click="goToClientOverview">
            {{ params.data.name }}
        </PButton>

        <span v-else class="text-primary">
            {{ params.data ? params.data.name : '' }}
        </span>
    </div>
</template>

<script>
import Vue from 'vue';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { PButton } from '@/components/PButton';
import { ClientNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import useXavierGlobals from '@/hooks/useXavierGlobals';

export default Vue.extend({
    components: { PButton },
    methods: {
        goToClientOverview() {
            const { currentTeam } = useXavierGlobals();

            const clientCrn = this.params.data.rbExternalId || this.params.data.rb_external_id;

            dispatchIframeEvent({
                action: 'NAVIGATE',
                payload: {
                    clientCrn: clientCrn.toString(),
                    pathName: ClientNavigationPathNames.CLIENT_OVERVIEW,
                    practiceCrn: currentTeam.rbExternalId.toString(),
                },
            });

            this.$router.push({ name: 'client.overview', params: { clientSlug: this.params.data.slug } });
        },
    },
    name: 'ClientLinkRenderer',
    props: {
        params: {
            required: true,
            type: Object,
        },
    },
    setup() {
        const { subscriptionValid } = useXavierGlobals();

        return { subscriptionValid };
    },
});
</script>

<style lang="scss" scoped>
.client-link {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}
</style>
