import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import ClientService from '@/services/Api/ClientService';

import { RootState } from '@/store/types';
import createMeta from '@/store/utils/createMeta';
import { Client, ClientId, ClientSlug } from '../client/types/Client';

import { AugmentedActionContext } from '@/store/types';

import { ClientsState } from './types/Store';
import { ClientState } from '../client/types/Store';

export const NAMESPACE = 'clients';

const meta = createMeta();

/* State
========================================================================== */
const initialState = (): ClientsState => {
    return {
        allIds: [],
        ...meta.getInitialState(),
    };
};

/* Actions
========================================================================== */

type ActionContext = AugmentedActionContext<ClientsState, ClientsGetters, InternalMutations>;

export enum ClientsActionTypes {
    /* All Clients
    ========================================================================= */
    LOAD_CLIENT_LIST = 'LOAD_CLIENT_LIST',
}

export interface ClientsActions {
    [ClientsActionTypes.LOAD_CLIENT_LIST](context: ActionContext): Promise<{ message: string } | Client[]>;
}

const actions: ActionTree<ClientsState, RootState> & ClientsActions = {
    /**
     * Load all clients. Adding each individual client to the state is handled
     * in a Vuex plugin which creates dynamic modules.
     *
     * @param context
     */
    async [ClientsActionTypes.LOAD_CLIENT_LIST]({ commit, state }) {
        if (meta.isInProgress(state)) {
            return Promise.reject({ message: 'Already in flight' });
        }

        commit(ClientsMutationTypes.LOAD_CLIENTS_START);

        try {
            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const clients = await ClientService.getClientList(practiceCrn);

            commit(ClientsMutationTypes.LOAD_CLIENTS_SUCCESS, { clients: clients.data });
        } catch (error) {
            commit(ClientsMutationTypes.LOAD_CLIENTS_FAILURE, { error });
        }

        return [];
    },
};

/* Getters
========================================================================== */
/**
 * This is used to pass the getters param to an actual getter, whilst excluding
 * any passed getter values. This prevents recursion, if required.
 */
type ClientsGettersValues<CurrentGetter> = {
    [key in Exclude<keyof ClientsGetters, CurrentGetter>]: ReturnType<ClientsGetters[key]>;
};

export type ClientsGetters = {
    clientList(state: ClientsState): ClientState[];
    clientById(
        state: ClientsState,
        getters: ClientsGettersValues<'clientById'>
    ): (clientId: ClientId) => ClientState | undefined;
    clientBySlug(state: ClientsState): (clientSlug: ClientSlug) => ClientState | undefined;
};

const getters: GetterTree<ClientsState, RootState> & ClientsGetters = {
    /**
     * Loop over all the clients in the allIds array and grab the client sub-
     * module for that client slug.
     *
     * @param state Clients store state
     */
    clientList(state) {
        return state.allIds.map((clientSlug) => {
            return state[`client_${clientSlug}`];
        });
    },

    /**
     * Find a client sub-module by a given client ID.
     *
     * @param _state Unused access to store state
     * @param getters Clients getters
     */
    clientById(_state, getters) {
        const clientList = getters.clientList;

        return function findClient(clientId): ClientState | undefined {
            return clientList.find((client) => client.data?.id === clientId);
        };
    },

    /**
     * Find a client sub-module by a given client slug.
     *
     * @param state Clients store state
     */
    clientBySlug(state) {
        return function (clientSlug): ClientState | undefined {
            return state[`client_${clientSlug}`];
        };
    },
};

/* Mutations
========================================================================== */
export enum ClientsMutationTypes {
    LOAD_CLIENTS_START = 'LOAD_CLIENTS_START',
    LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE',
    LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS',

    CLIENT_DELETED = 'CLIENT_DELETED',
}

type InternalMutations<State = ClientsState> = {
    [ClientsMutationTypes.LOAD_CLIENTS_FAILURE](state: State, payload: { error: unknown }): void;
    [ClientsMutationTypes.LOAD_CLIENTS_START](state: State): void;
    [ClientsMutationTypes.LOAD_CLIENTS_SUCCESS](state: State, payload: { clients: Client[] }): void;
    [ClientsMutationTypes.CLIENT_DELETED](state: State, payload: { clientSlug: ClientSlug }): void;
};

export type ClientsMutations = {
    [Mutation in keyof InternalMutations as `clients/${Mutation}`]: InternalMutations[Mutation];
};

const mutations: MutationTree<ClientsState> & InternalMutations = {
    /**
     * Client list failed to load. Set the meta error and status error.
     *
     * @param state
     * @param payload
     */
    [ClientsMutationTypes.LOAD_CLIENTS_FAILURE](state, { error }) {
        meta.stopProgress(state);
        meta.setError(state, error);
    },

    /**
     * Start client list loading. Set the meta status to in progress.
     *
     * @param state
     */
    [ClientsMutationTypes.LOAD_CLIENTS_START](state) {
        meta.startProgress(state);
    },

    /**
     * Client list loaded successfully. Here we loop over each client to
     * normalise the data set and improve state reliability. We do this to make
     * sure that there are no errant clients in the list that cannot be
     * accessed, since we are keying by the client slug and not an array index.
     *
     * @param state
     * @param payload
     */
    [ClientsMutationTypes.LOAD_CLIENTS_SUCCESS](state, { clients }) {
        const allIds = clients.map((client) => client.slug);

        meta.stopProgress(state);
        meta.setSuccess(state, true);

        Vue.set(state, 'allIds', allIds);
    },

    /**
     * Client has been deleted and should be removed from the `state.allIds`
     * array.
     */
    [ClientsMutationTypes.CLIENT_DELETED](state, { clientSlug }) {
        const allIds = state.allIds.filter((slug) => slug !== clientSlug);

        Vue.set(state, 'allIds', allIds);
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state: initialState,
};
