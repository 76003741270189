import type { FetchTeamsListResponseSchema, FetchTeamMemberListResponseSchema } from '@/schemas/Team.schema';


import BaseApiService from './BaseApiService';

class TeamService extends BaseApiService {
    getTeamMembers(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get<FetchTeamMemberListResponseSchema>('dext.team.get.members', routeParams);
    }

    getTeamDextMembers(practiceCrn: string) {
        const routeParams = { teamCrn: practiceCrn };

        return this.get('dext.team.get.dext-members', routeParams);
    }

    getTeamsList() {
        return this.get<FetchTeamsListResponseSchema>('teams.get.all');
    }
}

export default Object.freeze(new TeamService());
