import * as types from './types';
import Vue from 'vue';
import InsightsDashboardService from '@/services/Api/InsightsDashboardService';

export default {
    state: {
        directorsLoanAccounts: {
            widget: {
                isLoading: false,
                results: {
                    not_overdrawn: 0,
                    overdrawn: 0,
                    not_configured: 0,
                },
            },
            details: {
                isLoading: false,
                results: [],
            },
        },
    },

    getters: {
        directorsLoanAccountsWidgetLoading: (state) => state.directorsLoanAccounts.widget.isLoading,
        directorsLoanAccountsWidgetResults: (state) => state.directorsLoanAccounts.widget.results,
        directorsLoanAccountsDetailsLoading: (state) => state.directorsLoanAccounts.details.isLoading,
        directorsLoanAccountsDetailsResults: (state) => state.directorsLoanAccounts.details.results,
    },

    actions: {
        async fetchDirectorLoanAccountsWidgetData({ commit }) {
            commit(types.SET_DIRECTORS_LOAN_WIDGET_LOADING, true);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await InsightsDashboardService.getDirectorLoanAccounts(practiceCrn).then((response) => {
                commit(types.SET_DIRECTORS_LOAN_WIDGET_RESULTS, response.results);
                commit(types.SET_DIRECTORS_LOAN_WIDGET_LOADING, false);
            });
        },
        async fetchDirectorsLoanAccountsDetails({ commit }) {
            commit(types.SET_DIRECTORS_LOAN_DETAILS_LOADING, true);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            await InsightsDashboardService.getDirectorLoanAccountsDetails(practiceCrn).then((response) => {
                commit(types.SET_DIRECTORS_LOAN_DETAILS_RESULTS, response.results);
                commit(types.SET_DIRECTORS_LOAN_DETAILS_LOADING, false);
            });
        },
    },

    mutations: {
        [types.SET_DIRECTORS_LOAN_WIDGET_LOADING](state, isLoading) {
            Vue.set(state.directorsLoanAccounts.widget, 'isLoading', isLoading);
        },

        [types.SET_DIRECTORS_LOAN_WIDGET_RESULTS](state, results) {
            const newState = {
                ...{
                    not_overdrawn: 0,
                    overdrawn: 0,
                    not_configured: 0,
                },
                ...results,
            };

            Vue.set(state.directorsLoanAccounts.widget, 'results', newState);
        },

        [types.SET_DIRECTORS_LOAN_DETAILS_LOADING](state, isLoading) {
            Vue.set(state.directorsLoanAccounts.details, 'isLoading', isLoading);
        },

        [types.SET_DIRECTORS_LOAN_DETAILS_RESULTS](state, results) {
            Vue.set(state.directorsLoanAccounts.details, 'results', results);
        },
    },
};
