import Vue from 'vue';

import InsightsDashboardService from '@/services/Api/InsightsDashboardService';

import * as types from './types';

export default {
    state: {
        averageHealthScore: {
            widget: {
                isLoading: false,
                results: {
                    health_score: 0,
                    health_score_fluctuation: 0,
                },
            },
            details: {
                isLoading: false,
                hiddenClients: 0,
                results: {
                    pagination: {
                        currentPage: null,
                        from: null,
                        to: null,
                        lastPage: null,
                        perPage: null,
                        total: null,
                    },
                    data: {},
                },
            },
        },
    },

    getters: {
        averageHealthScoreWidgetLoading: (state) => state.averageHealthScore.widget.isLoading,
        averageHealthScoreWidgetData: (state) => state.averageHealthScore.widget,
        averageHealthScoreDetailsLoading: (state) => state.averageHealthScore.details.isLoading,
        averageHealthScoreDetailsData: (state) => state.averageHealthScore.details.results.data,
        averageHealthScoreDetailsPagination: (state) => state.averageHealthScore.details.results.pagination,
    },

    actions: {
        fetchAverageHealthScoreWidgetData({ commit }) {
            commit(types.SET_AVERAGE_HEALTHSCORE_LOADING, true);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            InsightsDashboardService.getAverageHealthScore(practiceCrn).then((response) => {
                commit(types.SET_AVERAGE_HEALTHSCORE_WIDGET_RESULTS, response.results);
                commit(types.SET_AVERAGE_HEALTHSCORE_LOADING, false);
            });
        },

        async fetchAverageHealthScoreDetails({ commit }, { page, clientName, sortColumn, sortDirection }) {
            commit(types.SET_AVERAGE_HEALTHSCORE_DETAILS_LOADING, true);

            const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

            const searchParams = new URLSearchParams({
                page,
                clientName,
                sortColumn,
                sortDirection,
            });

            await InsightsDashboardService.getAverageHealthScoreDetails(practiceCrn, searchParams).then((response) => {
                commit(types.SET_AVERAGE_HEALTHSCORE_DETAILS_RESULTS, response.data);
                commit(types.SET_AVERAGE_HEALTHSCORE_DETAILS_PAGINATION, response.pagination);
                commit(types.SET_AVERAGE_HEALTHSCORE_DETAILS_LOADING, false);
            });
        },

        async setLoading({ commit }, isLoading) {
            commit(types.SET_AVERAGE_HEALTHSCORE_DETAILS_LOADING, isLoading);
        },
    },

    mutations: {
        [types.SET_AVERAGE_HEALTHSCORE_LOADING](state, isLoading) {
            Vue.set(state.averageHealthScore.widget, 'isLoading', isLoading);
        },
        [types.SET_AVERAGE_HEALTHSCORE_WIDGET_RESULTS](state, results) {
            Vue.set(state.averageHealthScore.widget, 'results', results);
        },
        [types.SET_AVERAGE_HEALTHSCORE_DETAILS_LOADING](state, isLoading) {
            Vue.set(state.averageHealthScore.details, 'isLoading', isLoading);
        },
        [types.SET_AVERAGE_HEALTHSCORE_DETAILS_RESULTS](state, results) {
            Vue.set(state.averageHealthScore.details.results, 'data', results);
        },
        [types.SET_AVERAGE_HEALTHSCORE_DETAILS_PAGINATION](state, paginationData) {
            const pagination = {
                ...{
                    currentPage: null,
                    from: null,
                    to: null,
                    lastPage: null,
                    perPage: null,
                    total: null,
                },
                ...paginationData,
            };

            Vue.set(state.averageHealthScore.details.results, 'pagination', pagination);
        },

        [types.SET_AVERAGE_HEALTHSCORE_DETAILS_HIDDEN_CLIENT_COUNT](state, invisibleClientCount) {
            Vue.set(state.averageHealthScore.details, 'hiddenClients', invisibleClientCount);
        },
    },
};
