import BaseApiService from './BaseApiService';

class ExportService extends BaseApiService {
    constructor() {
        super();
        this.requestConfig = { responseType: 'blob' };
    }

    getFlowExcel(practiceCrn, clientId, mode, slug) {
        return this.get('dext.export.flow.excel', { teamCrn: practiceCrn, clientId, slug }, null, this.requestConfig);
    }

    getFlowPdf(practiceCrn, clientId, mode, slug) {
        return this.get('dext.export.flow.pdf', { teamCrn: practiceCrn, clientId, slug }, null, this.requestConfig);
    }

    getInsightExcel(practiceCrn, clientId, mode, insight) {
        return this.get(
            'dext.export.insight.excel',
            { teamCrn: practiceCrn, clientId, insight },
            { mode },
            this.requestConfig
        ).then(this.handleResponse);
    }

    getInsightPdf(practiceCrn, clientId, mode, insight, params = {}) {
        return this.get(
            'dext.export.insight.pdf',
            {
                teamCrn: practiceCrn,
                clientId,
                insight,
            },
            { mode, ...params },
            this.requestConfig
        );
    }

    getOverviewExcel(practiceCrn, clientId, mode) {
        return this.get('dext.export.overview.excel', { teamCrn: practiceCrn, clientId }, { mode }, this.requestConfig);
    }

    getOverviewPdf(practiceCrn, clientId, mode) {
        return this.get('dext.export.overview.pdf', { teamCrn: practiceCrn, clientId }, { mode }, this.requestConfig);
    }
}

export default Object.freeze(new ExportService());
