<template>
    <div ref="contentStickyRef" :class="containerClassName" data-qa="sticky-content" :style="style">
        <div :class="innerClassName">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import isString from 'lodash-es/isString';
import { computed, nextTick, onMounted, ref, defineComponent } from 'vue';
import PropTypes from 'vue-types';

import useContentContainerContext from './hooks/useContentContainerContext';

export default defineComponent({
    name: 'ContentSticky',

    props: {
        offset: PropTypes.oneOfType([Number, String]).def(0),
        zIndex: PropTypes.number.def(3),
    },

    setup(props) {
        const contentStickyRef = ref<HTMLElement | null>(null);
        const isSticky = ref(false);
        const api = useContentContainerContext('ContentSticky');

        const onIntersectObserve = ([entry]: IntersectionObserverEntry[]) => {
            isSticky.value = entry.intersectionRatio < 1;
        };

        const containerClassName = computed(() => {
            return {
                'content-sticky': true,
                'content-sticky--is-sticky': isSticky.value,
            };
        });

        const innerClassName = computed(() => {
            return {
                'content-sticky__inner': true,
                [`content-sticky__inner--${api.size.value}`]: Boolean(api.size.value),
            };
        });

        const style = computed(() => {
            return {
                paddingTop: isString(props.offset) ? props.offset : `${props.offset}px`,
                zIndex: props.zIndex,
            };
        });

        onMounted(async () => {
            await nextTick();

            const observer = new IntersectionObserver(onIntersectObserve, {
                root: api.contentContainerRef.value.$el,
                rootMargin: '20px 16px 0px 16px',
                threshold: [1],
            });

            observer.observe(contentStickyRef.value);
        });

        return {
            containerClassName,
            contentStickyRef,
            innerClassName,
            style,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

/**
* 1. This is a little hack since we are using -1px on the top positioning to get
*    the element in the right place.
* 2. Another little trick to get a full width element whilst still being a child
*    element. We are "breaking out" of the parent Content Container.
*/

.content-sticky {
    background-color: get-color(xavier-custom, silver-lite);
    border-top: 1px solid transparent;
    position: sticky;
    top: -1px;
    transition: box-shadow 0.15s ease;
}

.content-sticky--is-sticky {
    @include shadow($size: medium);
}

@each $name, $value in $breakpoints {
    .content-sticky__inner--#{$name} {
        max-width: $value;
    }
}

.content-sticky__inner--full {
    max-width: 100%;
}
</style>
