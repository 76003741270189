export enum HorizontalAlign {
    LEFT = 'left',
    RIGHT = 'right',
}

export enum VerticalAlign {
    BOTTOM = 'bottom',
    TOP = 'top',
}

export enum VerticalPosition {
    ABOVE = 'above',
    BELOW = 'below',
}
