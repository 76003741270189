import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Vue, { markRaw } from 'vue';

import { useRouter } from '@/composables/useRouter';

import type { Router } from 'vue-router';

declare module 'pinia' {
    export interface PiniaCustomProperties {
        $router: Router;
    }
}

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
    store.$router = markRaw(useRouter() as object);
});

export { pinia };
